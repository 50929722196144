import { useContext } from 'react'

import BasketComponentContext from '@providers/basketComponentProvider'

export default function useBasket() {
  const { addItem, removeItem, clearBasket, basket } = useContext(
    BasketComponentContext
  )

  return { addItem, removeItem, clearBasket, basket }
}

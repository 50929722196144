const GetPost = require('./getPost')
const GetPosts = require('./getPosts')
const GetTopics = require('./getTopics')
const GetMagazineArticle = require('./getMagazineArticle')
const AddLike = require('./likeItem')
const CreateArticle = require('./createArticle')
const CreateAnswer = require('./createAnswer')
const CreateComment = require('./createComment')
const OrderPrintMaterial = require('./orderPrintMaterial')

module.exports = function () {
  const api = {}

  api.getPost = GetPost
  api.getPosts = GetPosts
  api.getTopics = GetTopics
  api.getMagazineArticle = GetMagazineArticle
  api.addLike = AddLike
  api.createArticle = CreateArticle
  api.createAnswer = CreateAnswer
  api.createComment = CreateComment
  api.orderPrintMaterial = OrderPrintMaterial

  return api
}

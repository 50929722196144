import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  burgerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  line: {
    backgroundColor: theme.palette.text.primary,
    height: '3px',
    width: '100%',
    transition: '0.2s',
  },
  first: {
    width: '120%',
    transform: 'translateY(4px) translateX(-2px) rotate(45deg)',
  },
  second: {
    width: '120%',
    transform: 'translateY(-6px) translateX(-2px) rotate(-45deg)',
  },
}))

function Burger({ className, active }) {
  const classes = useStyles()

  return (
    <div className={clsx(className, classes.burgerRoot)} data-testid="burger">
      <div className={clsx(classes.line, { [classes.first]: active })}></div>
      <div className={clsx(classes.line, { [classes.second]: active })}></div>
    </div>
  )
}

Burger.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
}

export default Burger

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  navItemRoot: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    ...theme.typography.toc,
    fontWeight: 500,
  },
  navItemLevel1: {
    fontWeight: 700,
    flex: 1,
  },
  navItemLevel3: {
    fontWeight: 300,
    flex: 1,
  },
  link: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4, 6, 4, 5),
  },
  active: {
    color: theme.palette.red.main,
  },
  back: {
    padding: theme.spacing(4, 6, 4, 5),
    fontWeight: 700,
    backgroundColor: theme.palette.background.grey,
  },
  next: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    fontSize: theme.spacing(3),
    verticalAlign: 'baseline',
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
}))

function DrawerNavItem({
  children,
  className,
  to,
  activeSlug,
  title,
  drawerClass,
  level,
  onClick,
}) {
  const classes = useStyles()
  const intl = useIntl()
  const location = useLocation()

  const [open, setOpen] = useState(false)

  const toggleSubMenu = (open) => (event) => {
    setOpen(open)
  }

  function Wrapper({ children }) {
    if (to) {
      return (
        <Link
          data-testid="link"
          className={classes.link}
          color="inherit"
          to={to}
          onClick={onClick(false)}
          activeClassName={classes.active}
          partiallyActive={to.match(/\//g)?.length > 2}
        >
          {children}
        </Link>
      )
    } else {
      return (
        <div
          data-testid="toggle"
          className={clsx(classes.link, classes.next, {
            [classes.active]:
              activeSlug && location.pathname.startsWith(activeSlug),
          })}
          onClick={toggleSubMenu(true)}
        >
          {children}
        </div>
      )
    }
  }

  return (
    <>
      <ListItem
        data-testid={`item-${level}`}
        className={clsx(
          className,
          classes.navItemRoot,
          classes[`navItemLevel${level + 1}`]
        )}
        button
      >
        <Wrapper hasChildren={children ? true : false}>
          <span>{title}</span>
          {children && <Icon className={classes.icon} name="Next" />}
        </Wrapper>
      </ListItem>
      {children && (
        <SwipeableDrawer
          data-testid="submenu"
          anchor={'right'}
          variant="persistent"
          open={open}
          onClose={toggleSubMenu(false)}
          onOpen={toggleSubMenu(true)}
          PaperProps={{
            classes: {
              root: drawerClass,
            },
          }}
        >
          <div
            className={clsx(classes.navItemRoot, classes.back)}
            onClick={toggleSubMenu(false)}
          >
            <Icon className={classes.icon} name="Prev" />
            <span>{intl.formatMessage({ id: 'navigation.back' })}</span>
          </div>
          {children}
        </SwipeableDrawer>
      )}
    </>
  )
}

DrawerNavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  activeSlug: PropTypes.string,
  linkProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  drawerClass: PropTypes.string,
  level: PropTypes.number.isRequired,
  onClick: PropTypes.func,
}

export default DrawerNavItem

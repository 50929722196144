// const BP = require('./breakpoints')
const FontSize = require('./definitions/fontSize')

module.exports = function (theme) {
  return {
    body1: {
      lineHeight: 1.55,
      [theme.breakpoints.up('lg')]: {
        lineHeight: 1.75,
      },
    },
    body2: {
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['base'],
      lineHeight: 1.625,
    },
    h1: {
      fontSize: FontSize['4xl'],
      lineHeight: 1.2,
      fontWeight: 400,
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['6xl'],
      },
    },
    h2: {
      fontSize: FontSize['3xl'],
      lineHeight: 1.2,
      fontWeight: 400,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['5xl'],
      },
    },
    h3: {
      fontSize: FontSize['xl'],
      lineHeight: 1.3,
      fontWeight: 500,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['3xl'],
      },
    },
    h4: {
      fontSize: FontSize['2lg'],
      lineHeight: 1.2,
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['xl'],
      },
    },
    h5: {
      fontSize: FontSize['lg'],
      lineHeight: 1.2,
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },
    intro: {
      fontSize: FontSize['3lg'],
      lineHeight: 1.3636,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['3xl'],
        lineHeight: 1.286,
      },
    },
    quote: {
      fontSize: FontSize['3lg'],
      lineHeight: 1.273,
      color: theme.palette.red.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: FontSize['2xl'],
        lineHeight: 1.385,
      },
    },
    teaser: {
      fontSize: FontSize['sm'],
      lineHeight: 1.57,
      color: theme.palette.grey.dark,
    },
    info: {
      fontSize: FontSize['xs'],
      lineHeight: 1.23,
      color: theme.palette.text.secondary,
    },
    caption: {
      fontSize: FontSize['sm'],
      lineHeight: 1.14,
      color: theme.palette.text.secondary,
      fontStyle: 'normal',
    },
    type: {
      fontSize: FontSize['sm'],
      lineHeight: 1.3334,
      fontWeight: 700,
      color: theme.palette.red.main,
    },
    link: {
      fontWeight: 700,
      color: theme.palette.red.main,
      '&:focus': {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
      },
      '&:hover': {
        color: theme.palette.red.light,
        textDecoration: 'none',
      },
    },
    navigation: {
      fontSize: FontSize['lg'],
      lineHeight: 1.3334,
    },
    buttonPrimary: {
      fontSize: FontSize['2lg'],
      fontWeight: 500,
    },
    buttonSecondary: {
      fontSize: FontSize['lg'],
    },
    buttonText: {
      fontSize: 'inherit',
    },
    bullet: {
      fontSize: FontSize['2lg'],
      lineHeight: 1.5,
      fontWeight: 500,
      color: theme.palette.default.main,
    },
    footernavigation: {
      fontSize: FontSize['lg'],
      lineHeight: 1.3334,
      [theme.breakpoints.down('md')]: {
        fontSize: FontSize['base'],
      },
    },
    helpertext: {
      fontSize: FontSize['sm'],
      lineHeight: 1.125,
    },
    input: {
      lineHeight: 1.25,
    },
    label: {
      fontSize: FontSize['lg'],
      lineHeight: 1.278,
      fontWeight: 500,
    },
    checkboxOption: {
      fontSize: FontSize['lg'],
    },
    breadcrumb: {
      fontSize: FontSize['base'],
      lineHeight: 1.2,
      fontWeight: 700,
    },
    toc: {
      lineHeight: 1.5,
      fontSize: FontSize['base'],
    },
    topic: {
      fontSize: FontSize['sm'],
      lineHeight: 2,
    },
    hotline: {
      fontSize: FontSize['xl'],
      fontWeight: 500,
      color: theme.palette.red.main,
    },
    glossaryTab: {
      fontSize: FontSize['2lg'],
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    articleMeta: {
      fontSize: FontSize['xs'],
      color: theme.palette.text.secondary,
    },
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'

import FormHelperText from './helperText'
import TextInput from './textInput'

const StyledInputLabel = withStyles((theme) => ({
  root: {
    ...theme.typography.label,
    padding: theme.spacing(0, 5, 3, 5),
    color: 'inherit',
  },
}))(InputLabel)

function TextField(props) {
  const { label, infoText, id, name, type, ...other } = props
  const [field, meta] = useField(props)

  return (
    <>
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <TextInput
        id={id}
        type={type}
        error={meta.touched && !!meta.error}
        {...field}
        {...other}
        onBlur={null}
      />
      {infoText && (
        <FormHelperText spaced type="info">
          {infoText}
        </FormHelperText>
      )}
      {meta.touched && meta.error ? (
        <FormHelperText spaced type="error" id={`error-${id}`}>
          {meta.error}
        </FormHelperText>
      ) : null}
    </>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  infoText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email']),
}

TextField.defaultProps = {
  type: 'text',
}

export default TextField

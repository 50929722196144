import React from 'react'
import getGlossaryLink from './getGlossaryLink'
import formatResult from './formatResult'

const finalCheck = (element, glossaryData, type = 'overlay') => {
  // check each value in element array if its a string or an object
  const filteredElements =
    element.length > 0 && element.filter((value) => value !== undefined)

  const renderWithLineBreaks = (value) => {
    return value.split(/(?:\r\n|\r|\n)/g).map((segment, index) => (
      <React.Fragment>
        {segment}
        {index < value.split(/(?:\r\n|\r|\n)/g).length - 1 && <br />}
      </React.Fragment>
    ))
  }

  const renderWithLineBreaksCheck =
    filteredElements.length > 0 &&
    filteredElements.map((value) => {
      if (typeof value === 'string') {
        return renderWithLineBreaks(value)
      } else {
        return value
      }
    })

  const checkedElements =
    renderWithLineBreaksCheck.length > 0 &&
    renderWithLineBreaksCheck.flatMap((value, i) => {
      if (typeof value !== 'string') {
        // return objects already checked and good to go
        return value
      }
      const words = value.split(/(\s+)/)
      const checkedWords = words.flatMap((word) => {
        if (word.trim() === '') {
          // remove spaces
          return ''
        }

        if (!!glossaryData[word]) {
          return getGlossaryLink(word, i, type, glossaryData)
        } else {
          return word
        }
      })

      return checkedWords
    })
  return formatResult(checkedElements)
}

export default finalCheck

import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Index } from 'elasticlunr'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  search: {
    outline: 0,
    border: 0,
    background: 'none',
    borderRadius: '50%',
    padding: theme.spacing(4),
    '&:focus': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
  },
}))

function Search({ className, searchIndex, onClick }) {
  const intl = useIntl()
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const classes = useStyles()

  const [index, setIndex] = useState(null)

  useEffect(() => {
    if (!index && searchIndex) setIndex(Index.load(searchIndex))
  }, [])

  function showOverlay(show) {
    onClick()
    toggleOverlay(show, 'search', 'fullscreen', {
      trackingID: 'suche',
      className: 'o-overlay__search',
      index: index,
    })
  }

  return (
    <button
      className={clsx(className, classes.search)}
      onClick={() => showOverlay(true)}
      aria-label={intl.formatMessage({ id: 'arialabel.button.search' })}
    >
      <Icon name="Search" />
    </button>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  searchIndex: PropTypes.object,
  onClick: PropTypes.func,
}

export default Search

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  stepperRoot: {},
  progressBar: {
    height: theme.spacing(2),
    marginBottom: theme.spacing(3),
    background: theme.palette.grey.light,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  progress: {
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
    width: '0',
    background: theme.palette.grey.dark,
    lineHeight: 0,
    textAlign: 'right',
    transitionProperty: 'width',
    transitionDuration: theme.transitions.duration.standard,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
  markerAdjust: {
    transform: 'translateX(50%)',
  },
  step: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.helpertext,
    },
    color: theme.palette.text.hint,
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
  stepActive: {
    fontWeight: 700,
  },
}))

function Stepper({ className, steps, activeStep }) {
  const classes = useStyles()
  const stops = steps.length - 1

  return (
    <div className={clsx(className, classes.stepperRoot)}>
      <div className={classes.progressBar}>
        <div
          className={classes.progress}
          style={{ width: `${(100 / stops) * activeStep}%` }}
        >
          <img
            className={clsx({
              [classes.markerAdjust]: activeStep !== 0 && activeStep !== stops,
            })}
            src="/img/illustration/illustration_step.svg"
            alt=""
          />
        </div>
      </div>

      <Grid container spacing={8}>
        {steps.map((label, i) => (
          <Grid
            item
            key={`step${i}`}
            className={clsx(classes.step, {
              [classes.stepActive]: i <= activeStep,
            })}
            xs={12 / steps.length}
          >
            {i + 1}. {label}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

Stepper.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.array,
  activeStep: PropTypes.number,
}

export default Stepper

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getHolidayStatus } from '@api/getHolidayStatus'

const ToggleComponentContext = React.createContext()

export function ToggleComponentProvider({ children }) {
  const [OverlayIsActive, setOverlayIsActive] = useState(false)
  const [customProps, setCustomProps] = useState({})
  const [holidayStatus, setHolidayStatus] = useState(false)

  function toggleOverlay(show, type, dialogType, props) {
    if (
      !holidayStatus &&
      ['createArticle', 'createAnswer', 'createComment'].includes(type)
    ) {
      getHolidayStatus().then((status) => {
        setHolidayStatus(status.enabled)
        if (status.enabled === true) {
          setOverlayIsActive(show)
          setCustomProps({
            type: 'holidayModeDialog',
            dialogType: 'default',
            trackingID: 'holidayModeDialog',
            width: 'sm',
            closeButton: 'invert',
            onAcceptance: () => toggleOverlay(false),
          })
        } else {
          setOverlayIsActive(show)
          setCustomProps({
            type: type,
            dialogType: dialogType,
            ...props,
          })
        }
      })
    } else if (
      holidayStatus &&
      ['createArticle', 'createAnswer', 'createComment'].includes(type)
    ) {
      setOverlayIsActive(show)
      setCustomProps({
        type: 'holidayModeDialog',
        dialogType: 'default',
        trackingID: 'holidayModeDialog',
        width: 'sm',
        onAcceptance: () => toggleOverlay(false),
      })
    } else {
      setOverlayIsActive(show)
      setCustomProps({
        type: type,
        dialogType: dialogType,
        ...props,
      })
    }
  }

  return (
    <ToggleComponentContext.Provider
      value={{ OverlayIsActive, toggleOverlay, customProps }}
    >
      {children}
    </ToggleComponentContext.Provider>
  )
}

ToggleComponentProvider.propTypes = {
  children: PropTypes.any,
}

export default ToggleComponentContext

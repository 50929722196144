import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText as HelperText } from '@material-ui/core'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    color: theme.palette.grey.dark,
    margin: theme.spacing(1, 0),
    ...theme.typography.helpertext,
    '&.error': {
      color: theme.palette.error.main,
    },
    '& a': {
      ...theme.typography.link,
    },
  },
  spaced: {
    margin: theme.spacing(2, 4, 0, 4),
  },
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey.main,
    verticalAlign: '-5px',
  },
}))

function FormHelperText({ children, className, spaced, type, id }) {
  const classes = useStyles()
  return (
    <HelperText
      className={clsx(className, classes.helperTextRoot, {
        error: type === 'error',
        [classes.spaced]: spaced,
      })}
      id={id}
    >
      {type === 'info' && <Icon className={classes.icon} name="Info" />}
      {children}
    </HelperText>
  )
}

FormHelperText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  spaced: PropTypes.bool,
  type: PropTypes.oneOf(['info', 'error']),
}

FormHelperText.defaultProps = {
  type: 'info',
}

export default FormHelperText

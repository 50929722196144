import React, { useContext } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import { List, ListItem, Grid, Hidden } from '@material-ui/core'
import { Link } from 'gatsby'

import clsx from 'clsx'
import SocialIcon from '@objects/socialicon'
import Copy from '@objects/copy'
import Container from '@objects/container'

import SiteMapDE from '@config/sitemap.de'
import SiteMapEN from '@config/sitemap.en'
import BasketBar from '@components/basketBar'

import useBasket from '@hooks/useBasket'
import ToggleComponentContext from '@providers/toggleComponentProvider'

const SiteMaps = {
  de: SiteMapDE,
  en: SiteMapEN,
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 4, 20),
    },
  },
  container: {
    position: 'relative',
  },
  illustration: {
    position: 'absolute',
    top: '25%',
    right: theme.spacing(-12),
    width: '189px',
    [theme.breakpoints.up('md')]: {
      top: 'auto',
      bottom: theme.spacing(-25),
      right: theme.spacing(-8),
    },
    [theme.breakpoints.up('lg')]: {
      bottom: theme.spacing(-33),
      width: '236px',
    },
    [theme.breakpoints.up('xl')]: {
      right: theme.spacing(-45),
    },
    transition: 'all 0.2s',
  },
  copyright: {
    alignSelf: 'flex-end',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(9),
    },
  },
  logoGrid: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      order: -1,
      textAlign: 'left',
    },
  },
  logo: {
    height: '59px',
    width: '150px',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      height: '73px',
      width: '185px',
    },
  },
  iconsGrid: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 'auto'),
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      order: -1,
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  icons: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 'auto', 6),
    },
  },
  iconsTitle: {
    ...theme.typography.breadcrumb,
    color: theme.palette.secondary.main,
  },
  socialIcon: {
    display: 'inline-block',
    background: theme.palette.background.default,
    borderRadius: '50%',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(3),
      },
    },
    '& > svg': {
      fontSize: 35,
    },
  },
  showBasket: {
    marginBottom: '142px',
  },
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
}))

const StyledListItem = withStyles((theme) => ({
  root: {
    ...theme.typography.footernavigation,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:focus': {
      fontWeight: 700,
      textDecoration: 'underline',
    },
    '&:hover': {
      color: theme.palette.red.main,
      textDecoration: 'none',
    },
  },
}))(ListItem)

const StyledList = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
}))(List)

function Footer() {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const { basket } = useBasket()
  const { OverlayIsActive } = useContext(ToggleComponentContext)

  const listItems = SiteMaps[intl.locale].footer

  const socialIcons = [
    {
      name: 'Facebook',
      href: intl.formatMessage({ id: 'socialmedia.facebook.url' }),
      src: '/img/social/facebook.svg',
    },
    {
      name: 'Twitter',
      href: intl.formatMessage({ id: 'socialmedia.twitter.url' }),
      src: '/img/social/twitter.svg',
    },
    {
      name: 'Youtube',
      href: intl.formatMessage({ id: 'socialmedia.youtube.url' }),
      src: '/img/social/youtube.svg',
    },
    {
      name: 'Instagram',
      href: intl.formatMessage({ id: 'socialmedia.instagram.url' }),
      src: '/img/social/youtube.svg',
    },
  ]

  function renderSocialIcons() {
    return socialIcons.map((socialIcon, i) => {
      return (
        <SocialIcon
          key={`${socialIcon.created}${i}`}
          item={socialIcon}
          htmlColor={theme.palette.common.black}
          name={socialIcon.name}
          className={classes.socialIcon}
          invert
        />
      )
    })
  }

  function renderItems(items) {
    return items.map((item, i) => {
      return (
        <li key={`item${i}`}>
          <StyledListItem
            data-track-content
            data-tracking-id={item.messageId}
            disableGutters
            component={Link}
            to={intl.formatMessage({ id: item.slugId })}
          >
            <FormattedMessage id={item.messageId} />
          </StyledListItem>
        </li>
      )
    })
  }

  function renderLists() {
    return listItems.map((list, i) => {
      return (
        <StyledList key={`list${i}`} component={Grid} item xs={12} md={4}>
          <ul className={classes.list}>{renderItems(list.items)}</ul>
        </StyledList>
      )
    })
  }

  return (
    <Container
      className={clsx(classes.root, {
        [classes.showBasket]:
          !!basket.length && !OverlayIsActive && intl.locale === 'de',
      })}
      background={theme.palette.grey.light}
      type="nomargin"
      ariaLabel="arialabel.footer"
    >
      <Grid
        component="footer"
        className={classes.container}
        container
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <img
          className={classes.illustration}
          src="/img/illustration/illustration_grey_light.svg"
          alt=""
        />
        <Grid className={classes.navigation} item xs={10} md={6} container>
          {renderLists()}
        </Grid>
        <Grid className={classes.iconsGrid} item xs={10} md={'auto'}>
          <Hidden mdUp>
            <Copy
              className={classes.iconsTitle}
              html={intl.formatMessage({ id: 'footer.follow' })}
            />
          </Hidden>
          <StyledList className={classes.icons} component={Grid}>
            {renderSocialIcons()}
          </StyledList>
        </Grid>
        <Grid
          item
          xs={6}
          md={12}
          component={Copy}
          className={classes.copyright}
          html={intl.formatMessage({ id: 'footer.copyright' })}
        />
        <Grid className={classes.logoGrid} item xs={6} md={'auto'} lg={3}>
          <img
            className={classes.logo}
            src="/img/logo/5G_logo.svg"
            alt={intl.formatMessage({ id: 'header.logo.alt' })}
          />
        </Grid>
      </Grid>
      {basket.length > 0 && !OverlayIsActive && intl.locale === 'de' && (
        <BasketBar />
      )}
    </Container>
  )
}

export default Footer

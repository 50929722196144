import React from 'react'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '14px',
    },
  },
  text: {
    color: theme.palette.grey.dark,
    fontSize: '14px',
    paddingRight: theme.spacing(2),
  },
}))

function LanguageSelector() {
  const intl = useIntl()
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Link className={classes.text} to={intl.locale === 'en' ? '/' : '/en'}>
        {intl.locale === 'en' ? 'Deutsch' : 'English'}
      </Link>
      <Icon name={'Translation'} />
    </Box>
  )
}

export default LanguageSelector

const { createTheme } = require('@material-ui/core/styles')

const Breakpoints = require('./breakpoints')
const Colors = require('./colors')
const Container = require('./container')
const Shadows = require('./shadows')
const Typography = require('./typography')
const Overrides = require('./overrides')
const Mixins = require('./mixins')

module.exports = function () {
  const theme = createTheme({
    breakpoints: Breakpoints,
    palette: Colors,
    spacing: 4,
    typography: {
      fontFamily: 'BundesSans, Helvetica Arial, sans-serif',
    },
    overrides: Overrides,
    container: Container,
    customShadow: Shadows,
  })
  const typography = Typography(theme)
  const overrides = Overrides(theme)
  const mixins = Mixins(theme)

  theme.typography = { ...theme.typography, ...typography }
  theme.overrides = { ...theme.overrides, ...overrides }
  theme.mixins = { ...theme.mixins, ...mixins }

  theme.themeName = '5G Dialogplattform'

  return theme
}

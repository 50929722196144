const CssBaseline = require('./overrides/baseline')
const Icons = require('./overrides/icons')
const RichtextEditor = require('./overrides/richtexteditor')

module.exports = function (theme) {
  return {
    MuiCssBaseline: CssBaseline(theme),
    MuiSvgIcon: Icons(theme),
    MUIRichTextEditor: RichtextEditor(theme),
    MuiPopover: {
      root: {
        '& .MuiFormLabel-root': {
          color: theme.palette.text.secondary,
        },
        '& .MuiInput-underline::after': {
          borderBottomColor: theme.palette.red.light,
        },
        '& .MuiButton-root': {
          minWidth: theme.spacing(8),
          background: theme.palette.yellow.main,
          borderRadius: '50%',
          padding: theme.spacing(1.5),
          margin: theme.spacing(2, 1, 1, 1),
          '&:hover': {
            background: theme.palette.orange.main,
          },
        },
      },
    },
    MuiToolbar: {
      root: {
        position: 'static',
        width: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    MuiBackdrop: {
      root: {
        background: 'rgba(75,75,75,0.5)',
      },
    },
    MuiDialog: {
      paper: {
        margin: theme.spacing(4),
        borderRadius: 0,
        [theme.breakpoints.up('md')]: {
          borderRight: `${theme.spacing(4)}px solid ${
            theme.palette.yellow.main
          }`,
          borderRadius: theme.spacing(0, 2, 2, 0),
        },
      },
      paperWidthSm: {
        maxWidth: '650px',
      },
      paperWidthMd: {
        maxWidth: '704px',
        border: 0,
        borderRadius: 0,
      },
      paperWidthLg: {
        maxWidth: '809px',
      },
      paperFullScreen: {
        border: 'none',
      },
      paperFullWidth: {
        width: `calc(100% - ${theme.spacing(8)}px)`,
      },
      paperScrollPaper: {
        maxHeight: `calc(100% - ${theme.spacing(8)}px)`,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: theme.spacing(3, 16, 3, 4),
        borderRadius: theme.spacing(0, 4, 4, 0),
        borderRight: `${theme.spacing(4)}px solid ${theme.palette.red.main}`,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      message: {
        padding: 0,
        marginBottom: theme.spacing(1),
      },
      action: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
      },
    },
    MuiSwitch: {
      root: {
        width: 50,
        height: 28,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(23px)',
          '& + $track': {
            backgroundColor: theme.palette.background.default + '!important',
            opacity: 1,
          }
        },
        '&$focusVisible $thumb': {
          color: '#fff',
          border: '6px solid #fff',
        },
      },
      thumb: {
        transform: 'translate(2px, 3px)',
        width: 20,
        height: 20,
        backgroundColor: theme.palette.red.main,
      },
      track: {
        borderRadius: 28 / 2,
        border: `2px solid ${theme.palette.grey.dark}`,
        backgroundColor: theme.palette.background.default,
        opacity: 1,
        '$checked &': {
          backgroundColor: theme.palette.background.default,
        }
      },
    }
  }
}

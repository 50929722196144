import { useIntl } from 'react-intl'
import {
  parseISO,
  format,
  formatDistanceToNowStrict,
  differenceInDays as differenceInDaysOrigin,
  isSameDay,
  isBefore,
} from 'date-fns'

import { de, enGB as en } from 'date-fns/locale'

export default function useDateFns() {
  const intl = useIntl()
  const locales = { de, en }

  function formatDate(date, formatStr) {
    return format(parseISO(date || new Date()), formatStr || 'dd.MM.yyyy', {
      locale: locales[intl.locale],
    })
  }

  function formatDistanceToNow(date) {
    return formatDistanceToNowStrict(parseISO(date), {
      addSuffix: true,
      locale: locales[intl.locale],
    })
  }

  function formatDateYear(date, formatStr) {
    return format(parseISO(date, formatStr, new Date()), 'yyyy')
  }

  function differeneInDaysFromNow(date) {
    return differenceInDaysOrigin(parseISO(date), new Date())
  }

  function differenceInDays(dateA, dateB) {
    return differenceInDaysOrigin(parseISO(dateA), parseISO(dateB))
  }

  return {
    formatDate,
    formatDistanceToNow,
    formatDateYear,
    differeneInDaysFromNow,
    isSameDay,
    isBefore,
    differenceInDays,
  }
}

import React, { useContext, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Icon from '@objects/icon'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useLocation } from '@reach/router'
import {
  LogoJsonLd,
  SitelinksSearchBoxJsonLd,
  JsonLd,
} from 'gatsby-plugin-next-seo'

import Hidden from '@material-ui/core/Hidden'

import TextParserContext from '@providers/textParserProvider'
import DialogContext from '@providers/dialogProvider'

import Header from '@components/header'
import Footer from '@components/footer'
import MetaTags from '@objects/metatags'
import Button from '@objects/button'
import Overlay from '@objects/overlay'
import BreadCrumb from '@objects/breadcrumb'
import BannerNewsletter from '@objects/bannerNewsletter'

const useStyles = makeStyles((theme) => ({
  layoutRoot: {
    '&$grey': {
      background: theme.palette.background.grey,
    },
  },
  grey: {},
  skipLink: {
    position: 'absolute',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    fontWeight: 700,
    marginLeft: 8,
    marginTop: 2,
    marginBottom: 2,
    top: '-26px',
    left: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: `calc(50% - ${parseInt(theme.container.lg, 10) / 2}px)`,
    },
    '&:focus': {
      position: 'relative',
      top: '0px',
      textDecoration: 'underline',
    },
  },
}))

function Layout({ children, pageContext }) {
  const intl = useIntl()
  const location = useLocation()
  const classes = useStyles()
  const { updateGlossaryData } = useContext(TextParserContext)
  const { updateStreamQuery, StreamQuery } = useContext(DialogContext)
  const {
    siteSearchIndex,
    allContentfulPage,
    allContentfulTopicCategoryList,
    allContentfulGlossarEntry,
  } = useStaticQuery(graphql`
    query {
      siteSearchIndex {
        index
      }
      allContentfulPage {
        nodes {
          node_locale
          id
          slug
          title
          modules {
            key
            contentfulfields {
              ... on ContentfulStaticField {
                __typename
                key
                value {
                  childMarkdownRemark {
                    html
                  }
                }
                link
                media {
                  fluid(quality: 80, maxWidth: 1920) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  file {
                    contentType
                    fileName
                    url
                    details {
                      size
                    }
                  }
                  title
                  description
                }
              }
            }
          }
        }
      }
      allContentfulTopicCategoryList(
        filter: { identifier: { eq: "topiccategories" } }
      ) {
        nodes {
          identifier
          title
          node_locale
          categories {
            naviTitle
            title
            slug
            node_locale
            nextLevel {
              ... on ContentfulArticle {
                __typename
                naviTitle
                title
                slug
              }
              ... on ContentfulTopicCategory {
                __typename
                naviTitle
                title
                slug
              }
            }
          }
        }
      }
      allContentfulGlossarEntry {
        nodes {
          node_locale
          description {
            json
          }
          title
          synonyms
          tooltipMessage
          tooltipOnly
        }
      }
    }
  `)

  function getStaticFields() {
    return allContentfulPage.nodes?.find(
      (config) =>
        config.slug === pageContext.slug &&
        config.node_locale === pageContext.locale
    )?.modules
  }

  function getMetaNaviFields() {
    const metaNaviFields = []

    const pages = allContentfulPage.nodes || []

    pages.map((page) => {
      if (page.id === 'eb2e077b-2af5-5db7-ad94-8bf86d8550c4') {
        metaNaviFields[0] = page
      }
      if (page.id === '59791148-6366-54c4-9c40-83c69bdf08b5') {
        metaNaviFields[1] = page
      }
    })

    return metaNaviFields
  }

  function getTopicCategories() {
    return allContentfulTopicCategoryList.nodes
      .find((node) => node.node_locale === pageContext.locale)
      ?.categories.filter(
        (cat) => cat.node_locale === pageContext.locale && cat.slug && cat.title
      )
      ?.map((cat) => ({
        title: cat.naviTitle ?? cat.title,
        slug: `${intl.formatMessage({
          id: 'inform.path',
        })}${cat.slug}/`,
        nextlevel: cat.nextLevel
          .filter((filterItem) => filterItem.title && filterItem.slug)
          .map((item) => ({
            title: item.naviTitle ?? item.title,
            slug:
              item.__typename === 'ContentfulTopicCategory'
                ? `${intl.formatMessage({
                    id: 'inform.path',
                  })}${item.slug}/`
                : `${intl.formatMessage({
                    id: 'inform.path',
                  })}${cat.slug}/${item.slug}/`,
          })),
      }))
  }

  function getTopicCategoriesMobile() {
    return allContentfulTopicCategoryList.nodes
      .find((node) => node.node_locale === pageContext.locale)
      ?.categories.filter(
        (cat) => cat.node_locale === pageContext.locale && cat.slug && cat.title
      )
      ?.map((cat) => ({
        title: cat.naviTitle ?? cat.title,
        activeSlug: `${intl.formatMessage({
          id: 'inform.path',
        })}${cat.slug}/`,
        nextlevel: [].concat(
          [
            {
              title: cat.naviTitle ?? cat.title,
              slug: `${intl.formatMessage({
                id: 'inform.path',
              })}${cat.slug}/`,
            },
          ],
          cat.nextLevel
            .filter((filterItem) => filterItem.title && filterItem.slug)
            .map((item) => ({
              title: item.naviTitle ?? item.title,
              slug:
                item.__typename === 'ContentfulTopicCategory'
                  ? `${intl.formatMessage({
                      id: 'inform.path',
                    })}${item.slug}/`
                  : `${intl.formatMessage({
                      id: 'inform.path',
                    })}${cat.slug}/${item.slug}`,
            }))
        ),
      }))
  }

  function onClickSkipLink(e) {
    e.preventDefault()
    const id = e.currentTarget.getAttribute('href')
    const target = document.querySelector(id)
    target.focus()
  }

  useEffect(() => {
    updateGlossaryData(
      allContentfulGlossarEntry.nodes.filter(
        (node) => node.node_locale === pageContext.locale
      )
    )
  }, [])

  useEffect(() => {
    if (
      StreamQuery &&
      location.pathname.indexOf(intl.formatMessage({ id: 'forum.path' })) === -1
    ) {
      updateStreamQuery(null)
    }
  }, [location])

  /**
   * @description Generated Breadcrumblist.
   */
  const crumbList = [].concat(
    pageContext.breadcrumbs || [],
    pageContext.frontmatter?.breadcrumbs?.map((crumb) => {
      return {
        label: intl.formatMessage({
          id: crumb.label,
        }),
        link: intl.formatMessage({
          id: crumb.link,
        }),
      }
    }) || [],
    CrumbItem(pageContext?.page?.naviTitle ?? pageContext?.page?.title)
  )

  /**
   * @description Transforms label and link to object
   * @param {String} label
   * @param {String} [link=null] Optional link
   * @returns {{label: String, link: String}} Label and Link for the Breadcrumb
   */
  function CrumbItem(label, link = null) {
    let result = {
      label: label ?? '',
    }
    if (link) result.link = link
    return label ? result : []
  }

  const excludedUrls = ['/']

  pageContext.modules = getStaticFields()

  const isHome = pageContext.page?.title?.startsWith('Home')

  function handleScreenreaderToTopClick(e) {
    const mainContent = document.getElementById('main');
    const screenReaderToTopButton = document.getElementById('screen-reader-to-top');

    screenReaderToTopButton.blur();

    mainContent.setAttribute('tabindex', '-1');
    mainContent.focus();
    mainContent.setAttribute('tabindex', '0');
    e.stopPropagation()
  }
  return (
    <div
      className={clsx(classes.layoutRoot, {
        [classes[pageContext?.frontmatter?.pageBackground]]:
          pageContext?.frontmatter?.pageBackground,
      })}
    >
      <MetaTags
        locale={pageContext.locale}
        meta={{ canonical: pageContext.canonical }}
      />
      <LogoJsonLd
        logo="https://www.deutschland-spricht-ueber-5g.de/img/logo/5G_logo.svg"
        url={pageContext.canonical}
      />
      <SitelinksSearchBoxJsonLd
        url={pageContext.canonical}
        searchHandlerQueryStringUrl={`${location.origin}/suchergebnisse/#searchquery`}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'GovernmentOrganization',
          legalName: 'Bundesministerium für Digitales und Verkehr',
          name: 'Deutschland spricht über 5G',
          address: {
            streetAddress: 'Invalidenstraße 44',
            addressLocality: 'Berlin',
            addressRegion: 'BER',
            postalCode: '10115',
            addressCountry: 'DE',
          },
          contactPoint: [
            {
              telephone: '+4930183000',
              contactType: 'customer service',
              areaServed: 'DE',
              availableLanguage: ['German', 'English'],
            },
          ],
        }}
      />
      <a className={classes.skipLink} href="#main" onClick={onClickSkipLink}>
        <FormattedMessage id="skiplink" />
        <Icon name="ArrowDown" size="small" />
      </a>
      {!!isHome && <BannerNewsletter />}
      <Header
        searchIndex={siteSearchIndex?.index}
        topiclist={{
          mobile: getTopicCategoriesMobile(),
          desktop: getTopicCategories(),
        }}
        cta={pageContext.frontmatter?.header?.cta || 'default'}
        articleId={pageContext.frontmatter?.header?.articleId || ''}
        metaNaviFields={getMetaNaviFields()}
      />
      <Overlay />
      <div id="main" tabIndex={0} />
      {(pageContext.slug || pageContext.matchPath) &&
        !excludedUrls.includes(pageContext.slug) && (
          <Hidden mdDown>
            <BreadCrumb crumbList={crumbList} />
          </Hidden>
        )}

      {children}
      <Button type="icon" color="red" backtop />
      <div
        type="button"
        id="screen-reader-to-top"
        onClick={(e) => handleScreenreaderToTopClick(e)}
        aria-label={intl.formatMessage({ id: 'screenreader.to.top' })}
      ></div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Layout

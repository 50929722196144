export const eventBus = {
  on: function (event, callback) {
    document.addEventListener(event, (e) => callback(e.detail))
  },
  dispatch: function (event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }))
  },
  remove: function (event, callback) {
    document.removeEventListener(event, callback)
  },
}
export const ANSWER_SUBMIT_SUCCESS = 'answer_submit_success'
export const COMMENT_SUBMIT_SUCCESS = 'comment_submit_success'
export const ARTICLE_LIKED = 'article_liked'

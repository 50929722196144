import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@objects/button'
import useBasket from '@hooks/useBasket'
import Icon from '@objects/icon'
import Container from '@objects/container'
import { FormattedMessage } from 'react-intl'
import Copy from '@objects/copy'
import ToggleComponentContext from '@providers/toggleComponentProvider'

const useStyles = makeStyles((theme) => ({
  basketBarRoot: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    background: theme.palette.primary.main,
    zIndex: 1010,
    paddingTop: '46px',
    paddingBottom: '48px',
  },
  basketBarInner: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  basketInfo: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  icon: {
    fontSize: '22px',
    marginRight: '15px',
  },
  basketButton: {
    backgroundColor: theme.palette.default.main,
    marginTop: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
}))

export default function BasketBar() {
  const classes = useStyles()
  const { basket } = useBasket()
  const { toggleOverlay } = useContext(ToggleComponentContext)

  function openBasket() {
    toggleOverlay(true, 'displayBasket', 'default', {
      trackingID: 'dialog',
      width: 'xl',
    })
  }

  return (
    <div data-testid="BasketBar" className={classes.basketBarRoot}>
      <Container
        className={classes.basketBarInner}
        type={'nomargin'}
        ariaLabel="arialabel.basketbar"
      >
        <Copy className={classes.basketInfo}>
          <Icon className={classes.icon} name={'Basket'} />
          <FormattedMessage
            id="dialogue.basketBar.itemAmount"
            values={{
              totalAmount: basket.length,
            }}
          />
        </Copy>
        <div>
          <Button
            className={classes.basketButton}
            icon={'TextArrow'}
            onClick={openBasket}
          >
            <FormattedMessage id={'dialogue.popup.form.basket.order.step'} />
          </Button>
        </div>
      </Container>
    </div>
  )
}

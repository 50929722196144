import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
  content: {
    padding: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
  },
  headline: {
    marginBottom: theme.spacing(5),
  },
  copy: {
    marginBottom: theme.spacing(6),
  },
  link: {
    ...theme.typography.link,
  },
}))

function Glossary({ word, description, link, synonyms }) {
  const classes = useStyles()
  const { toggleOverlay } = useContext(ToggleComponentContext)

  return (
    <div className={classes.content}>
      <Headline id="dialog-title" level={5} className={classes.headline}>
        {word}
      </Headline>
      <Copy
        className={classes.copy}
        html={description}
        type="faq"
        component="div"
        parseGlossary
        glossaryExcludes={synonyms}
      />
      <Button type="text" to={link} onClick={() => toggleOverlay(false)}>
        <FormattedMessage id="glossary.popup.link" />
      </Button>
    </div>
  )
}

Glossary.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  word: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  synonyms: PropTypes.arrayOf(PropTypes.string),
}

export default Glossary

import React, { useState, useRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

import { throttle } from 'lodash'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Backdrop from '@material-ui/core/Backdrop'

import Search from '@components/search'
import MetaNavigation from '@components/metaNavigation'
import HorizontalNav from '@components/navigation/horizontalNav'
import DrawerNav from '@components/navigation/drawerNav'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'
import Burger from '@objects/burger'

import SiteMapDE from '@config/sitemap.de'
import SiteMapEN from '@config/sitemap.en'
import useDialog from '@hooks/useDialog'
import { eventBus, ANSWER_SUBMIT_SUCCESS } from '@services/eventBus'

const SiteMaps = {
  de: SiteMapDE,
  en: SiteMapEN,
}

const breakpointExtraSmall = '@media (max-width: 360px)'

const useStyles = makeStyles((theme) => ({
  root: {
    transitionProperty: 'transform',
    transitionDuration: theme.transitions.duration.complex,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    lineHeight: 1,
    '&:after': {
      content: '""',
      display: 'block',
      height: '10px',
      position: 'absolute',
      bottom: '-10px',
      left: 0,
      right: 0,
      background:
        'linear-gradient(to bottom, rgba(75, 75, 75, 0.18), rgba(75, 75, 75, 0.05) 60%, transparent)',
    },
  },
  rootSticky: {
    top: 0,
    left: 'auto',
    right: 0,
    position: 'relative',
    '@supports (position: sticky)': {
      position: 'sticky',
    },
  },
  elevate: {
    zIndex: theme.zIndex.modal + 1,
  },
  hidden: {
    transform: 'translateY(-110%)',
  },
  toolbar: {
    position: 'relative',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    [breakpointExtraSmall]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  navigationdesktop: {
    display: 'block',
    // navgiation cannot be hidden by <Hidden></Hidden> component. Because hidden-componen is not SSR. Navigation needs to bee SSR for SEO.
    [theme.breakpoints.down('lg')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  grow: {
    flexGrow: 1,
  },
  elementSeparation: {
    maxWidth: theme.spacing(8),
    flexGrow: 1,
  },
  search: {
    cursor: 'pointer',
    marginRight: theme.spacing(4),
    [breakpointExtraSmall]: {
      marginRight: theme.spacing(2),
    },
  },
  menuButton: {
    padding: theme.spacing(2, 4),
    marginLeft: theme.spacing(4),
  },
  logo: {
    '&:focus': {
      outline: `1px dotted ${theme.palette.text.primary}`,
    },
  },
  logoMarginDE: {
    marginRight: theme.spacing(8),
    [theme.breakpoints.up('xl')]: {
      marginRight: theme.spacing(4),
    },
    [breakpointExtraSmall]: {
      marginRight: theme.spacing(2),
    },
  },
  logoMargin: {
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(21),
    },
  },
  logoImg: {
    [theme.breakpoints.down('sm')]: {
      width: '90px',
    },
    height: '55px',
    width: '140px',
    verticalAlign: 'top',
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(15),
      width: '153px', // fix for flex/getBoundingClientRect
    },
  },
  backdrop: {
    zIndex: theme.zIndex.appBar,
  },
  visuallyHiddenStyle: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: '0',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: '0',
    '& ul': {
      paddingLeft: '0',
      listStyleType: 'none',
    },
  },
}))

function Header({
  className,
  searchIndex,
  topiclist,
  cta,
  articleId,
  metaNaviFields,
}) {
  const intl = useIntl()
  const classes = useStyles()
  const { PostData } = useDialog()
  const CompRef = useRef(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'))

  const NavItems = SiteMaps[intl.locale].navigation

  function getFullNavigation(type) {
    return NavItems.map((item) => {
      return item.baseNextlevel
        ? {
            ...item,
            nextlevel: []
              .concat(item.baseNextlevel, topiclist[type])
              .concat(item.bottomNextlevel ? item.bottomNextlevel : []),
          }
        : item
    })
  }

  useEffect(() => {
    let lastScrollPos = window.pageYOffset
    const compHeight = CompRef.current.offsetHeight

    const throttledScrollHandler = throttle((e) => {
      let st = window.pageYOffset
      if (st > lastScrollPos && st > compHeight) {
        setIsHidden(true)
      } else if (st < lastScrollPos) {
        setIsHidden(false)
      }
      lastScrollPos = st
    }, 250)

    window.addEventListener('scroll', throttledScrollHandler)

    return () => {
      window.removeEventListener('scroll', throttledScrollHandler)
    }
  }, [])

  function toggleBackdrop(open) {
    setNavOpen(open)
  }

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open)
  }

  useEffect(() => {
    const body = document.body
    const htmlEl = document.getElementsByTagName('html')[0]
    // ios fix background
    if (drawerOpen) {
      body.style.height = '100%'
      htmlEl.style.height = '100%'
      body.style.position = 'relative'
      htmlEl.style.position = 'relative'
      htmlEl.style.overflow = 'hidden'
    } else {
      body.style.height = ''
      htmlEl.style.height = ''
      body.style.position = ''
      htmlEl.style.position = ''
      htmlEl.style.overflow = ''
    }
  }, [drawerOpen])

  const articleButton = () => {
    return (
      <CreateContentButton type="secondary">
        {intl.formatMessage({ id: 'header.cta.createArticle' })}
      </CreateContentButton>
    )
  }

  const answerButton = () => {
    if (PostData?.isDeleted) {
      return <></>
    }
    return (
      <CreateContentButton
        contentType="createAnswer"
        articleId={PostData.articleId}
        type="secondary"
        onSuccessCallback={(data) => {
          eventBus.dispatch(ANSWER_SUBMIT_SUCCESS, data)
        }}
      >
        {intl.formatMessage({ id: 'header.cta.createAnswer' })}
      </CreateContentButton>
    )
  }

  function renderCTA() {
    switch (cta) {
      case 'createDiscussionButton':
        if (PostData?.isSingle) {
          return answerButton()
        } else {
          return articleButton()
        }

      case 'createArticle':
        return articleButton()
      case 'createAnswer':
        return answerButton()
      default:
        return (
          <Button
            type="secondary"
            to={intl.formatMessage({ id: 'dialog.path' })}
          >
            {intl.formatMessage({ id: 'header.cta.dialog' })}
          </Button>
        )
    }
  }

  function ScreenReaderNavList({ navData }) {
    const renderNavItems = (items) => (
      <ul aria-label={intl.formatMessage({ id: 'navigation.unordered.list' })}>
        {items.map(
          (item) =>
            item && (
              <li key={item.slugId || item.slug}>
                <a
                  href={
                    item.slugId
                      ? intl.formatMessage({ id: item.slugId })
                      : item.slug
                  }
                >
                  {item.messageId
                    ? intl.formatMessage({ id: item.messageId })
                    : item.title}
                </a>
                {item.nextlevel && renderNavItems(item.nextlevel)}
              </li>
            )
        )}
      </ul>
    )

    const renderTopLevelNavItems = () => {
      return navData.map((navItem) => {
        if (!navItem.nextlevel) {
          return (
            <a
              href={intl.formatMessage({ id: navItem.slugId })}
              key={navItem.slugId || navItem.slug}
              aria-label={`${intl.formatMessage({
                id: 'navigation.header',
              })} - ${intl.formatMessage({
                id: navItem.messageId,
              })}`}
            >
              {intl.formatMessage({ id: navItem.messageId })}
            </a>
          )
        } else {
          return (
            <div
              aria-label={`${intl.formatMessage({
                id: 'navigation.header',
              })} - ${intl.formatMessage({
                id: navItem.messageId,
              })}`}
              role="group"
              key={navItem.slugId || navItem.slug}
            >
              {renderNavItems([navItem])}
            </div>
          )
        }
      })
    }

    return (
      <div
        className={classes.visuallyHiddenStyle}
        role="navigation"
        aria-label="Navigation"
      >
        {renderTopLevelNavItems()}
      </div>
    )
  }

  const currentNavItems = topiclist?.desktop
    ? getFullNavigation('desktop')
    : NavItems

  return (
    <>
      <Hidden lgDown>
        <Backdrop className={classes.backdrop} open={navOpen} />
      </Hidden>
      <Fragment>
        <Hidden lgDown>
          <MetaNavigation metaNaviFields={metaNaviFields} />
        </Hidden>
        <AppBar
          ref={CompRef}
          data-testid="header"
          aria-label="Header"
          className={clsx(classes.root, {
            [classes.hidden]: isDesktop && isHidden,
            [classes.elevate]: drawerOpen,
            [classes.rootSticky]: isDesktop,
          })}
          position="relative"
          color="inherit"
          elevation={0}
        >
          <Toolbar className={classes.toolbar} disableGutters={true}>
            <Link
              className={clsx(
                classes.logo,
                intl.locale === 'de' ? classes.logoMarginDE : classes.logoMargin
              )}
              to="/"
              onClick={toggleDrawer(false)}
              data-track-content
              data-tracking-id="header-home"
            >
              <img
                className={classes.logoImg}
                src="/img/logo/5G_logo.svg"
                alt={intl.formatMessage({ id: 'header.logo.alt' })}
              />
            </Link>
            <Hidden lgDown>
              <div className={classes.grow} />
            </Hidden>
            <ScreenReaderNavList navData={currentNavItems} />
            <HorizontalNav
              className={classes.navigationdesktop}
              items={currentNavItems}
              onHover={toggleBackdrop}
              open={navOpen}
            />
            {intl.locale === 'de' && (
              <Hidden lgDown>
                <div className={classes.elementSeparation} />
                {renderCTA()}
              </Hidden>
            )}
            <div className={classes.grow} />
            {intl.locale === 'de' && (
              <Search
                className={classes.search}
                searchIndex={searchIndex}
                onClick={toggleDrawer(false)}
              />
            )}
            <Hidden xlUp>
              <div
                className={classes.menuButton}
                onClick={toggleDrawer(!drawerOpen)}
              >
                <Burger active={drawerOpen} />
              </div>
              <DrawerNav
                items={
                  topiclist?.mobile ? getFullNavigation('mobile') : NavItems
                }
                openState={drawerOpen}
                toggleDrawer={toggleDrawer}
                metaNaviFields={metaNaviFields}
              />
            </Hidden>
          </Toolbar>
        </AppBar>
      </Fragment>
    </>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  searchIndex: PropTypes.any,
  topiclist: PropTypes.shape({
    mobile: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        naviTitle: PropTypes.string,
        title: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    desktop: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        naviTitle: PropTypes.string,
        title: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
  }),
  cta: PropTypes.oneOf([
    'default',
    'createArticle',
    'createAnswer',
    'createDiscussionButton',
  ]),
  articleId: PropTypes.string,
}

export default Header

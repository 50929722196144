import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import NavItem from './horizontalNavItem'

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listLevel1: {
    ...theme.typography.navigation,
    display: 'flex',
  },
  listLevel2: {
    display: 'inline-block',
    minHeight: '100%',
    padding: theme.spacing(5, 0, 6, 0),
    borderRadius: '0 0 0 20px',
  },
  listLevel2Full: {
    backgroundColor: theme.palette.background.default,
  },
  level2: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#fff',
    left: 0,
    borderRadius: '0 0 20px 20px',
    top: '100%',
    zIndex: 1,
    ':hover > &, .focusActive + &': {
      display: 'block',
    },
    '&:before': {
      content: '""',
      display: 'block',
      height: theme.spacing(13),
      position: 'absolute',
      top: -theme.spacing(10),
      left: 0,
      right: 0,
    },
    '&:after': {
      content: '""',
      display: 'block',
      height: '10px',
      position: 'absolute',
      top: '0px',
      left: 0,
      right: 0,
      background:
        'linear-gradient(to bottom, rgba(75, 75, 75, 0.18), rgba(75, 75, 75, 0.05) 60%, transparent)',
    },
  },
  level2Full: {
    backgroundColor: theme.palette.background.grey,
    width: theme.spacing(200),
  },
  level3: {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    padding: theme.spacing(5),
    overflow: 'hidden',
    ':hover > &, .focusActive + &': {
      marginLeft: theme.spacing(-10),
      paddingLeft: theme.spacing(10),
      display: 'block',
    },
  },
}))

function HorizontalNav({ className, items, onHover, open }) {
  const intl = useIntl()
  const classes = useStyles()
  const CompRef = useRef(null)
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const isBrowser = typeof window !== 'undefined'

  // For a11y -> close submenu on escape
  useEffect(() => {
    function onListener(event) {
      if (event.key === 'Escape') {
        handleClick()
      }
    }
    document.addEventListener('keydown', onListener)

    return () => document.removeEventListener('keydown', onListener)
  }, [])

  useEffect(() => {
    const secondLevel = [
      ...CompRef.current.querySelectorAll(`.${classes.level2}`),
    ]
    const thirdLevel = [
      ...CompRef.current.querySelectorAll(`.${classes.level3}`),
    ]

    setTimeout(() => {
      secondLevel.forEach((nav, i) => {
        nav.style.display = 'block'
        nav.style.left = `${nav.parentNode.offsetLeft - theme.spacing(5)}px`
      })
      thirdLevel.forEach((nav, i) => {
        nav.style.left = `${
          nav.parentNode.getBoundingClientRect().width + theme.spacing(6)
        }px`
      })
      secondLevel.forEach((nav, i) => {
        nav.style.removeProperty('display')
      })
    }, 10)
  }, [isBrowser && window.innerWidth, intl.locale])

  function renderNavList(items, level, isFull) {
    return items.reduce(
      // eslint-disable-next-line no-use-before-define
      (items, item) => reduceItems({ items, item, level, isFull }),
      []
    )
  }

  function handleClick() {
    CompRef.current.style.pointerEvents = 'none'
    onHover(false)
    window.setTimeout(() => {
      CompRef.current.style.removeProperty('pointer-events')
    }, 0)
    handleNavigate()
  }

  function handleNavigate() {
    const activeItems = [...CompRef.current?.querySelectorAll('.focusActive')]

    activeItems.forEach((item) => {
      item.classList.remove('focusActive')
    })
  }

  function reduceItems({ items, item, level, parentFull }) {
    const hasSub = item.nextlevel && item.nextlevel.length > 0
    const isFull = level === 1 && item.depth > 2

    if (items.length < 10) {
      items.push(
        <NavItem
          level={level}
          key={item.messageId || item.slug}
          to={item.slugId ? intl.formatMessage({ id: item.slugId }) : item.slug}
          activeSlug={
            item.activeSlugId
              ? intl.formatMessage({ id: item.activeSlugId })
              : item.activeSlug
          }
          title={
            item.messageId
              ? intl.formatMessage({ id: item.messageId })
              : item.title
          }
          showDivider={item.divider || false}
          onMouseEnter={level === 1 && hasSub ? () => onHover(true) : null}
          onMouseLeave={level === 1 && hasSub ? () => onHover(false) : null}
          onClick={handleClick}
          onNavigate={handleNavigate}
        >
          {hasSub && (
            <div
              className={clsx(classes[`level${level + 1}`], {
                [classes.level2Full]: isFull,
              })}
              aria-hidden={!open}
              aria-expanded={open}
            >
              <ul
                className={clsx(
                  classes.list,
                  classes[`listLevel${level + 1}`],
                  {
                    [classes.listLevel2Full]: isFull,
                  }
                )}
              >
                {renderNavList(item.nextlevel, level + 1)}
              </ul>
            </div>
          )}
        </NavItem>
      )
    }

    return items
  }

  return (
    <nav
      className={clsx(className)}
      aria-hidden="true"
    >
      <ul
        ref={CompRef}
        data-testid="list"
        className={clsx(classes.list, classes.listLevel1)}
      >
        {renderNavList(items, 1)}
      </ul>
    </nav>
  )
}

HorizontalNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  onHover: PropTypes.func,
}

export default HorizontalNav

import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import Cookies from 'js-cookie'

import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'

import NavItem from './drawerNavItem'
import CreateContentButton from '@objects/button/createContentButton'
import MetaNavigation from '@components/metaNavigation'

const useStyles = makeStyles((theme) => ({
  listLevel1: {
    lineHeight: 1,
    ...theme.typography.navigation,
  },
  drawerWrapper: {
    zIndex: theme.zIndex.appBar - 1,
  },
  drawer: {
    width: '80vw',
    height: 'auto',
    minHeight: '80vh',
    maxHeight: '100%',
    overflowY: 'auto',
    borderRadius: theme.spacing(0, 0, 0, 4),
    paddingTop: '95px',
  },
  drawerBanner: {
    width: '80vw',
    height: 'auto',
    minHeight: '80vh',
    maxHeight: '100%',
    overflowY: 'auto',
    borderRadius: theme.spacing(0, 0, 0, 4),
    paddingTop: '195px',
  },
  cta: {
    padding: theme.spacing(10),
    textAlign: 'center',
    ...theme.typography.teaser,
    '& p': {
      margin: theme.spacing(0, 0, 5, 0),
    },
  },
}))

function DrawerNav({ className, items, openState, toggleDrawer, metaNaviFields }) {
  const intl = useIntl()
  const classes = useStyles()

  const isBannerClosed = Cookies.get('closeBanner')

  function renderNavList(items, level) {
    return (
      <List
        component="div"
        className={classes[`listLevel${level + 1}`]}
        disablePadding
      >
        {items.reduce(
          // eslint-disable-next-line no-use-before-define
          (items, item) => reduceItems({ items, item, level }),
          []
        )}
      </List>
    )
  }

  function reduceItems({ items, item, level }) {
    items.push(
      <NavItem
        level={level}
        key={item.messageId || item.slug || item.activeSlug}
        to={item.slugId ? intl.formatMessage({ id: item.slugId }) : item.slug}
        activeSlug={
          item.activeSlugId
            ? intl.formatMessage({ id: item.activeSlugId })
            : item.activeSlug
        }
        title={
          item.messageId
            ? intl.formatMessage({ id: item.messageId })
            : item.title
        }
        drawerClass={isBannerClosed ? classes.drawer : classes.drawerBanner}
        onClick={toggleDrawer}
      >
        {item.nextlevel &&
          item.nextlevel.length > 0 &&
          renderNavList(item.nextlevel, level + 1)}
      </NavItem>
    )

    return items
  }

  return (
    <SwipeableDrawer
      data-testid="drawer"
      className={clsx(className, classes.drawerWrapper)}
      anchor={'right'}
      open={openState}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      PaperProps={{
        classes: {
          root: isBannerClosed ? classes.drawer : classes.drawerBanner,
        },
      }}
    >
      {renderNavList(items, 0)}
      <div className={classes.cta}>
        <p>{intl.formatMessage({ id: 'navigation.cta' })}</p>
        <CreateContentButton
          color="red"
          type="secondary"
          onClick={toggleDrawer(false)}
        >
          {intl.formatMessage({ id: 'button.createArticle' })}
        </CreateContentButton>
      </div>
      <MetaNavigation metaNaviFields={metaNaviFields} />
    </SwipeableDrawer>
  )
}

DrawerNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  openState: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
}

export default DrawerNav

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
      '&.Mui-focused': {
        '& .MuiInputBase-input': {
          borderColor: theme.palette.background.focus,
        },
      },
      '&.Mui-error': {
        '& .MuiInputBase-input': {
          borderColor: theme.palette.error.main,
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main,
        },
      },
      '&.Mui-disabled': {
        '& .MuiInputBase-input': {
          backgroundColor: theme.palette.background.light,
          borderColor: theme.palette.grey.main,
        },
      },
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(3, 4),
      height: 'auto',
      borderRadius: 32,
      border: `${theme.spacing(0.5)}px solid ${theme.palette.grey.medium}`,
      backgroundColor: theme.palette.common.white,
      ...theme.typography.input,
    },
  },
}))(TextField)

function TextInput({ className, id, ...props }) {
  return (
    <StyledTextField
      className={className}
      data-testid="input"
      id={id}
      {...props}
      inputProps={
        props.error
          ? {
              'aria-describedby': `error-${id}`,
            }
          : {}
      }
    />
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
}

export default TextInput

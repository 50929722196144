import { useContext } from 'react'

import TrackingContext from '@providers/trackingProvider'

export default function useTracking() {
  const TrackingCtx = useContext(TrackingContext)

  return {
    pushMessage: TrackingCtx.PushMessage,
  }
}

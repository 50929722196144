import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

function Headline({ className, level, children, id, html, ...props }) {
  function renderChildren() {
    if (html) return null
    return children
  }
  function Level() {
    const Props = {
      id: id,
      className: className,
      ...(html && {
        dangerouslySetInnerHTML: {
          __html: html,
        },
      }),
      ...props,
    }
    switch (level) {
      case 1:
        return (
          <Typography variant={'h1'} {...Props}>
            {renderChildren()}
          </Typography>
        )
      case 3:
        return (
          <Typography variant={'h3'} {...Props}>
            {renderChildren()}
          </Typography>
        )
      case 4:
        return (
          <Typography variant={'h4'} {...Props}>
            {renderChildren()}
          </Typography>
        )
      case 5:
        return (
          <Typography variant={'h5'} {...Props}>
            {renderChildren()}
          </Typography>
        )
      case 6:
        return (
          <Typography variant={'h6'} {...Props}>
            {renderChildren()}
          </Typography>
        )
      default:
        return (
          <Typography variant={'h2'} {...Props}>
            {renderChildren()}
          </Typography>
        )
    }
  }
  return <>{Level()}</>
}

Headline.propTypes = {
  className: PropTypes.string,
  level: PropTypes.number,
  id: PropTypes.string,
  children: PropTypes.any,
  html: PropTypes.string,
}

export default Headline

import React, { useEffect, useRef } from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { WidgetInstance } from 'friendly-challenge'
import FormHelperText from './helperText'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  recaptchaRoot: {
    marginTop: theme.spacing(8),

    '& .frc-container': {
      transition: 'unset !important',
      transitionProperty: 'all',
      transitionDuration: '200ms !important',
      transitionTimingFunction: theme.transitions.easing.easeInOut,
    },
    '& .frc-content': {
      flexGrow: 'unset',
    },
    /*   '& .frc-captcha': {
      background: 'rgb(245, 242, 240)',
      border: 'none',
    }, */
    '& .white': {
      background: 'white',
      border: 'none',
    },

    '& .gray': {
      background: 'rgb(245, 242, 240)',
      border: 'none',
    },

    '& .frc-text': {
      marginBottom: '4px',
    },
    '& .frc-banner': {
      display: 'none',
    },
    '& .frc-icon': {
      width: '48px',
      height: '48px',
      marginLeft: '0',
    },
    '& .frc-button': {
      alignItems: 'center',
      border: 'none',
      outline: 'none',
      background: 'none',
      fontFamily: 'inherit',
      lineHeight: 1.1,
      cursor: 'pointer',
      transitionProperty: 'all !important',
      transitionDuration: '200ms !important',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1) !important',
      height: theme.spacing(9),
      padding: theme.spacing(0, 5),
      ...theme.typography.buttonSecondary,
      borderRadius: '25px',
      backgroundColor: theme.palette.yellow.main,
      '&:focus': {
        background: theme.palette.background.focus,
        color: theme.palette.text.invert,
      },
      '&:not(.disabled):hover': {
        backgroundColor: theme.palette.orange.main,
        color: 'inherit',
      },
      '&.disabled': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey.main,
      },
    },
  },
  error: {
    marginTop: theme.spacing(3),
  },
}))

function FriendlyCaptcha({ name, background }) {
  const classes = useStyles()
  const [field, meta, helper] = useField(name)
  const intl = useIntl()
  const container = useRef()
  const widget = useRef()

  useEffect(() => {
    widget.current = new WidgetInstance(container.current, {
      puzzleEndpoint: 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle',
      language: intl.locale,
      doneCallback: doneCallback,
      sitekey: 'FCMUHQI5LO1GLQ1A',
    })

    return () => {
      if (widget.current !== undefined) widget.current.reset()
    }
  }, [container])

  function doneCallback(solution) {
    helper.setValue(solution)
  }

  return (
    <div
      data-testid="friendlycaptcha"
      data-usercentrics="Friendly Captcha"
      className={clsx(classes.recaptchaRoot)}
    >
      <div
        className={clsx('frc-captcha', background)}
        ref={container}
        id={`friendly-captcha-${name}`}
      />
      {meta.touched && meta.error && (
        <FormHelperText className={classes.error} type="error">
          {meta.error}
        </FormHelperText>
      )}
    </div>
  )
}

FriendlyCaptcha.propTypes = {
  id: PropTypes.string,
  sitekey: PropTypes.string,
  name: PropTypes.string,
}

export default FriendlyCaptcha

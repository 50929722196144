//"title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "media", "numberList", "bulletList", "quote", "code", "clear", "save"
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl, FormattedMessage } from 'react-intl'

import MUIRichTextEditor from 'mui-rte'
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { useField } from 'formik'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from './helperText'

const useStyles = makeStyles((theme) => ({
  richtext: {
    position: 'relative',
    cursor: 'pointer',
    '& .MuiIconButton-root': {
      padding: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-of-type': {
        marginLeft: theme.spacing(2),
      },
      '&:not(:first-of-type)': {
        position: 'relative',
        '&:before': {
          content: '""',
          cursor: 'pointer',
          position: 'absolute',
          left: '-5px',
          top: '25%',
          height: '50%',
          width: '2px',
          backgroundColor: theme.palette.grey.light,
        },
      },
    },
    '& .MuiIconButton-colorPrimary': {
      color: theme.palette.red.light,
    },
    '& .MuiTypography-colorPrimary': {
      color: theme.palette.red.light,
    },
  },

  errormessage: {
    width: '100%',
    padding: theme.spacing(2.5, 4, 3, 4),
    marginBottom: theme.spacing(4),
    borderBottomLeftRadius: theme.spacing(6),
    borderBottomRightRadius: theme.spacing(6),
    backgroundColor: theme.palette.red.light,
    color: theme.palette.text.invert,
    ...theme.typography.helpertext,
  },
  error: {
    '& $chars': {
      bottom: theme.spacing(13),
    },
    '& > div:first-child': {
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.light,
      marginBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      '& .MuiPaper-root': {
        color: theme.palette.red.light,
      },
      '& .MuiIconButton-root': {
        '&:not(:first-of-type)': {
          '&:before': {
            backgroundColor: theme.palette.red.light,
          },
        },
      },
    },
  },
  focus: {
    '& > div:first-child': {
      borderColor: theme.palette.background.focus,
    },
  },
  chars: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(-7.5),
    color: theme.palette.grey.main,
    ...theme.typography.teaser,
    [theme.breakpoints.up('sm')]: {
      top: 'auto',
      bottom: theme.spacing(2.5),
    },
  },
}))

const StyledInputLabel = withStyles((theme) => ({
  root: {
    ...theme.typography.label,
    padding: theme.spacing(0, 5),
    color: 'inherit',
  },
}))(InputLabel)

function RichTextEditor({
  className,
  id,
  label,
  maxLength,
  showInfoText,
  ignoreReset,
  ...props
}) {
  const classes = useStyles()
  const intl = useIntl()
  const [char, setChar] = useState(maxLength)
  const [initialized, setInitialized] = useState(false)
  const [defaultValue, setDefaultValue] = useState(null)
  const [focused, setFocused] = useState(false)

  const [field, meta, helper] = useField(props)

  const rtRef = useRef(null)
  const richtextRef = useRef(null)

  function rteChange(data) {
    const text = stateToHTML(data.getCurrentContent())
    helper.setValue(text === '<p><br></p>' ? '' : text, true)
    setChar(maxLength - data.getCurrentContent().getPlainText().length)
  }

  function rteBlur() {
    setFocused(false)
  }

  function rteFocus() {
    setFocused(true)
  }

  function setFocus() {
    // eslint-disable-next-line no-unused-expressions
    rtRef.current?.focus()
  }

  function parseValue(value) {
    const contentHTML = convertFromHTML(field.value)
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    )
    return JSON.stringify(convertToRaw(state))
  }

  useEffect(() => {
    if (field.value !== '' && !initialized) {
      setDefaultValue(parseValue(field.value))
    }
    setInitialized(true)

    richtextRef?.current
      ?.querySelector(`#${id}-toolbar`)
      ?.setAttribute('role', 'toolbar')

    richtextRef?.current
      ?.querySelector('[role="textbox"]')
      ?.setAttribute('aria-describedby', `error-${id}`)
  }, [])

  useEffect(() => {
    if (field.value === '' && initialized && !ignoreReset) {
      // delete content of field, when form is reset with initial values
      setDefaultValue(parseValue(field.value))
    }
  }, [field.value])

  return (
    <>
      {label && (
        <StyledInputLabel onClick={setFocus} htmlFor={`${id}-editor`}>
          {label}
        </StyledInputLabel>
      )}
      <div
        ref={richtextRef}
        className={clsx(classes.richtext, {
          [classes.error]: meta.touched && meta.error,
          [classes.focus]: focused,
        })}
      >
        <MUIRichTextEditor
          id={id}
          ref={rtRef}
          controls={['bold', 'italic', 'numberList', 'bulletList']}
          label={intl.formatMessage({ id: 'richtext.placeholder' })}
          maxLength={maxLength}
          inlineToolbar={true}
          inlineToolbarControls={['bold', 'italic', 'link']}
          onChange={rteChange}
          onBlur={rteBlur}
          onFocus={rteFocus}
          defaultValue={defaultValue}
          {...props}
        />
        <div className={classes.chars}>
          <FormattedMessage
            id="richtext.count"
            values={{
              count: char,
            }}
          />
        </div>
        {meta.touched && meta.error && (
          <div className={classes.errormessage} id={`error-${id}`}>
            {meta.error}
          </div>
        )}
      </div>
      {showInfoText && (
        <FormHelperText spaced>
          <FormattedMessage
            id="dialogue.popup.form.field.text.info"
            values={{
              a: function terms(terms) {
                return (
                  <a
                    className={classes.link}
                    href={intl.formatMessage({
                      id: 'netiquette.path',
                    })}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {terms}
                  </a>
                )
              },
            }}
          />
        </FormHelperText>
      )}
    </>
  )
}

RichTextEditor.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  showInfoText: PropTypes.bool,
  ignoreReset: PropTypes.bool,
}

RichTextEditor.defaultProps = {
  maxLength: 800,
  ignoreReset: false,
}

export default RichTextEditor

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  ytRoot: {
    margin: theme.spacing(6, -4, 15, -4),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(10, 0, 20, 0),
    },
  },
  ytWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: 'calc(9 / 16 * 100%)',
    overflow: 'hidden',
    '$thumbWrapper, & iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& .uc-embedding-container': {
      position: 'absolute',
      zIndex: 100,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '& img': {
        display: 'none',
      },
      '& .uc-embedding-more-info': {
        width: '80%',
        borderRadius: '25px',
        fontFamily: 'inherit',
        lineHeight: 1.1,
        cursor: 'pointer',
        transitionProperty: 'all',
        transitionDuration: theme.transitions.duration.shorter,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        height: theme.spacing(9),
        padding: theme.spacing(0, 5),
        backgroundColor: theme.palette.background.grey,
        color: theme.palette.red.main,
        '&:focus': {
          background: theme.palette.background.focus,
          color: theme.palette.text.invert,
        },
        '&:not(.disabled):hover': {
          backgroundColor: theme.palette.grey.main,
        },
        '&.disabled': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.grey.main,
        },
      },
      '& .uc-embedding-accept': {
        width: '80%',
        borderRadius: '25px',
        fontFamily: 'inherit',
        lineHeight: 1.1,
        cursor: 'pointer',
        transitionProperty: 'all',
        transitionDuration: theme.transitions.duration.shorter,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        height: theme.spacing(9),
        padding: theme.spacing(0, 5),
        backgroundColor: theme.palette.yellow.main,
        color: 'inherit',
        '&:focus': {
          background: theme.palette.background.focus,
          color: theme.palette.text.invert,
        },
        '&:not(.disabled):hover': {
          backgroundColor: theme.palette.orange.main,
          color: 'inherit',
        },
        '&.disabled': {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.grey.main,
        },
      },
    },
  },
  thumbWrapper: {
    position: 'relative',
    color: theme.palette.yellow.main,
  },
  play: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.yellow.main,
    textAlign: 'center',
  },
  playText: {
    margin: theme.spacing(2, 0, 0, 0),
    ...theme.typography.buttonPrimary,
  },
  caption: {
    display: 'flex',
    marginTop: theme.spacing(2),
    ...theme.typography.caption,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  copyright: {
    ...theme.typography.info,
    marginLeft: 'auto',
  },
}))

function YouTube({
  className,
  ytid,
  copyright,
  description,
  thumbnail,
  alt,
  id,
}) {
  const classes = useStyles()
  const ytiFrame = useRef()
  const [showiFrame, setShowiFrame] = useState(false)

  const isBrowser = typeof window !== 'undefined'

  if (isBrowser && !!window._paq) {
    window._paq.push(['MediaAnalytics::scanForMedia'])
  }

  function thumbnailClick() {
    ytiFrame?.current?.setAttribute(
      'src',
      ytiFrame.current.getAttribute('src') + '?autoplay=1'
    )
    setShowiFrame(true)
  }

  return (
    <div
      data-track-content
      data-tracking-id={id}
      className={clsx(classes.ytRoot, className)}
      data-testid={'youtube-video'}
    >
      <div className={classes.ytWrapper}>
        <iframe
          ref={ytiFrame}
          src={`https://www.youtube.com/embed/${ytid}`}
          frameborder="0"
          allowfullscreen
        ></iframe>
        {!showiFrame && (
          <div
            className={classes.thumbWrapper}
            onClick={thumbnailClick}
            data-matomo-title="My custom Video title"
          >
            <Img
              fluid={
                thumbnail
                  ? { ...thumbnail, media: `(min-width: 0px)` }
                  : {
                      aspectRatio: 1.7778,
                      src: `https://img.youtube.com/vi/${ytid}/maxresdefault.jpg`,
                      srcSet: `https://img.youtube.com/vi/${ytid}/maxresdefault.jpg`,
                      sizes: '',
                      media: `(min-width: 0px)`,
                    }
              }
              alt={alt || `Youtube Video ${ytid}`}
              backgroundColor={true}
            />
            <div className={classes.play}>
              <Button type="icon" icon="Play" />
              <p className={classes.playText}>
                <FormattedMessage id="button.play" />
              </p>
            </div>
          </div>
        )}
      </div>
      {(description || copyright) && (
        <div className={classes.caption}>
          {description && <div>{description}</div>}
          {copyright && <div className={classes.copyright}>{copyright}</div>}
        </div>
      )}
    </div>
  )
}

YouTube.propTypes = {
  className: PropTypes.string,
  ytid: PropTypes.string.isRequired,
  copyright: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.object,
  alt: PropTypes.string,
  id: PropTypes.string,
}

export default YouTube

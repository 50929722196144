import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const BasketComponentContext = React.createContext({
  addItem: () => {
    return null
  },
  removeItem: () => {
    return null
  },
  clearBasket: () => {
    return null
  },
  setDeleted: () => {
    return null
  },
  basket: [],
})

export function BasketComponentProvider({ children }) {
  const storageKey = 'basketStorage'
  const [basket, setBasket] = useState([])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const basketStorage = window.localStorage.getItem(storageKey)

      const basketStorageParsed = basketStorage ? JSON.parse(basketStorage) : []
      setBasket(() => basketStorageParsed)
    }
  }, [])

  function addItem(item) {
    setBasket((basket) => {
      const newBasket = basket.filter((basketItem) => {
        return basketItem.id !== item.id
      })
      newBasket.push(item)

      window.localStorage.setItem(storageKey, JSON.stringify(newBasket))

      return newBasket
    })
  }

  function removeItem(itemId) {
    setBasket((basket) => {
      const newBasket = basket.filter((basketItem) => {
        return basketItem.id !== itemId
      })
      window.localStorage.setItem(storageKey, JSON.stringify(newBasket))
      return newBasket
    })
  }

  function clearBasket() {
    setBasket(() => {
      window.localStorage.setItem(storageKey, JSON.stringify([]))
      return []
    })
  }

  return (
    <BasketComponentContext.Provider
      value={{ addItem, removeItem, clearBasket, basket }}
    >
      {children}
    </BasketComponentContext.Provider>
  )
}

BasketComponentProvider.propTypes = {
  children: PropTypes.any,
}

export default BasketComponentContext

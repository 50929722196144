import React from 'react'
import { useIntl } from 'react-intl'

import api from '@api'
import CreateContentForm from './createContent/form'

import { articleSchema } from './validationSchema'
import PropTypes from 'prop-types'

function CreateArticleForm({ onSuccessCallback }) {
  const intl = useIntl()

  const formInitialValues = {
    title: '',
    topics: [],
  }

  const steps = {
    titles: [
      intl.formatMessage({
        id: 'dialogue.popup.form.createContent.article.step',
      }),
      intl.formatMessage({ id: 'dialogue.popup.form.chooseTopics.step' }),
      intl.formatMessage({ id: 'dialogue.popup.form.publish.article.step' }),
    ],
    forms: ['article', 'topics', 'userData'],
  }

  return (
    <CreateContentForm
      steps={steps}
      validationSchema={articleSchema}
      initialValues={formInitialValues}
      onSubmit={api().createArticle}
      onSuccessCallback={onSuccessCallback}
    />
  )
}

CreateArticleForm.propTypes = {
  onSuccessCallback: PropTypes.func,
}

export default CreateArticleForm

import React, { useEffect, useState, useRef } from 'react'
import { navigate, Link } from 'gatsby'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTracking, useToggleComp } from '@hooks'

import { makeStyles } from '@material-ui/core/styles'

import Icon from '@objects/icon'
import TextInput from '@objects/formFields/textInput'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  searchInputRoot: {
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
  input: {
    flex: 1,
  },
  button: {
    flex: '0 0 auto',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
    },
  },
  suggest: {
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateY(100%)',
    pointerEvents: 'none',
    zIndex: -1,

    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: theme.spacing(4),

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontWeight: 700,

    '&.has-results': {
      opacity: 1,
      visibility: 'visible',
      pointerEvents: 'inherit',
      zIndex: 10,
    },
  },
  suggestLink: {
    padding: theme.spacing(3, 6, 3, 0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '85%',
    position: 'relative',
    '&:hover, &:focus': {
      color: theme.palette.red.light,
    },
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

function SearchInput({
  className,
  searchfunc,
  resultsAll,
  noautosuggest,
  searchquery,
  onKeyEnter,
  onSuggestClick,
  isShown,
}) {
  const intl = useIntl()
  const classes = useStyles()
  const { pushMessage } = useTracking()
  const { toggleOverlay } = useToggleComp()
  const inputRef = useRef(null)
  const suggestRef = useRef(null)
  const [forceSuggestHide, setForceSuggestHide] = useState(false)
  const [currentValue, setCurrentValue] = useState()
  const [TrackingTimeOut, setTrackingTimeOut] = useState(() => {})
  const [suggestPosition, setSuggestPosition] = useState(-1)

  useEffect(() => {
    if (
      searchquery &&
      inputRef?.current?.querySelector('input').value !== searchquery
    ) {
      inputRef.current.querySelector('input').value = searchquery
      setCurrentValue(searchquery)
    }
  }, [searchquery])

  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        inputRef.current.querySelector('input').focus()
      }, 200)
    }
  }, [isShown])

  function renderAutoSuggest() {
    return [
      ...resultsAll.slice(0, 5).map((result, i) => {
        return (
          <Link
            data-track-content
            data-tracking-id={result.path}
            key={`${result.slug}${i}`}
            className={classes.suggestLink}
            to={result.path}
            onClick={suggestClick}
          >
            {result.title}
            <Icon className={classes.icon} name="TextArrow" size="small" />
          </Link>
        )
      }),
      <Link
        data-track-content
        data-tracking-id={`/suchergebnisse/#searchquery=${currentValue}`}
        key="toallresults"
        className={classes.suggestLink}
        to={`/suchergebnisse/#searchquery=${currentValue}`}
        onClick={() => {
          toggleOverlay(false)
          suggestClick()
        }}
      >
        <FormattedMessage
          id="search.suggest.all"
          values={{
            count: resultsAll.length > 5 ? resultsAll.length : '',
          }}
        />
      </Link>,
    ]
  }

  function suggestClick() {
    if (onSuggestClick) onSuggestClick()
  }

  function inputChangeEvent(ev) {
    clearTimeout(TrackingTimeOut)
    const value = ev.target.value
    setForceSuggestHide(value.length < 4)
    searchfunc(value)
    setSuggestPosition(-1)

    // put trackingevent in a timeout to prevent spamming unnessecary query-strings
    if (value.length > 3) {
      setTrackingTimeOut(
        setTimeout(() => {
          pushMessage('custom.search-pushed', {
            searchquery: value,
          })
        }, 1000)
      )
    }
    setCurrentValue(value)
  }

  function onInputKeydown(ev) {
    if (ev.key === 'Enter') {
      navigate(
        `${intl.formatMessage({
          id: 'search.results.path',
        })}#searchquery=${currentValue}`
      )
      if (onKeyEnter) onKeyEnter()
    }
  }

  function onKeydown(ev) {
    if (suggestRef?.current) {
      const links = suggestRef.current.querySelectorAll('a')
      if (
        ev.key === 'ArrowDown' ||
        (ev.key === 'Tab' && !ev.shiftKey && suggestPosition > -1)
      ) {
        const newpos = suggestPosition + 1
        if (newpos < links.length) {
          links[newpos].focus()
          setSuggestPosition(newpos)
          ev.preventDefault()
        }
      } else if (
        suggestPosition > -1 &&
        (ev.key === 'ArrowUp' || (ev.key === 'Tab' && ev.shiftKey))
      ) {
        const newpos = suggestPosition - 1
        if (newpos < 0) {
          // eslint-disable-next-line no-unused-expressions
          inputRef.current?.querySelector('input').focus()
        } else {
          links[newpos].focus()
        }
        setSuggestPosition(newpos)
        ev.preventDefault()
      }
    }
  }

  return (
    <div ref={inputRef} className={clsx(className, classes.searchInputRoot)}>
      <TextInput
        className={classes.input}
        type="text"
        placeholder={intl.formatMessage({ id: 'search.input.placeholder' })}
        onChange={(ev) => inputChangeEvent(ev, false)}
        onKeyPress={(ev) => onInputKeydown(ev, false)}
        onKeyDown={(ev) => onKeydown(ev, false)}
        autoFocus
        variant="outlined"
      />
      <Button
        className={classes.button}
        to={`/suchergebnisse#searchquery=${currentValue}`}
        onClick={(ev) => suggestClick(ev, false)}
        role="button"
      >
        <FormattedMessage id={'search.input'} />
      </Button>
      {!noautosuggest && (
        <div
          ref={suggestRef}
          className={clsx(classes.suggest, {
            'has-results': resultsAll.length && !forceSuggestHide,
          })}
          onKeyDown={(ev) => onKeydown(ev, false)}
        >
          {renderAutoSuggest()}
        </div>
      )}
    </div>
  )
}

SearchInput.propTypes = {
  className: PropTypes.string,
  searchfunc: PropTypes.func.isRequired,
  resultsAll: PropTypes.array.isRequired,
  noautosuggest: PropTypes.bool,
  onKeyEnter: PropTypes.func,
  onSuggestClick: PropTypes.func,
  isShown: PropTypes.bool,
  searchquery: PropTypes.string,
}

export default SearchInput

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  item: {
    '&:hover $toggle': {
      transform: 'scale(1, -1)',
    },
  },
  inner: {
    display: 'inline-block',
    outline: 'none',
  },
  label: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  active: {
    '&, & + $icon': {
      color: theme.palette.red.main,
    },
    ':focus > &, :focus > & + $icon': {
      color: 'inherit',
    },
    ':hover > &, :hover > & + $icon': {
      color: 'inherit',
    },
  },
  icon: {
    verticalAlign: '-15%',
    marginLeft: theme.spacing(5),
  },
  toggle: {
    marginLeft: theme.spacing(2),
    transitionProperty: 'transform',
    transitionDuration: theme.transitions.duration.shorter,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(3),
    },
  },
  itemLevel1: {
    padding: theme.spacing(1, 3, 1, 3),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1, 4, 1, 4),
    },
    '& > $inner': {
      '&:focus $label, & $label:focus': {
        fontWeight: 700,
        textDecoration: 'underline',
      },
    },
    '&:hover > $inner': {
      '&, & $label, & $label:hover, & $icon': {
        color: theme.palette.red.light,
        textDecoration: 'none',
        fontWeight: 400,
      },
    },
  },
  itemLevel2: {
    lineHeight: '20px',
    padding: theme.spacing(0, 10, 0, 5),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  itemLevel2Sub: {
    padding: 0,
    '& > $inner': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(2, 3, 2, 13),
      borderRadius: theme.spacing(0, 5, 5, 0),
      transitionProperty: 'width background-color',
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
    },
    '& $inner:focus': {
      color: theme.palette.text.invert,
      backgroundColor: theme.palette.background.focus,
      width: 'calc(100% + 20px)',
      outline: 'none',
    },
    '& > $inner $label:focus': {
      fontWeight: 700,
      textDecoration: 'underline',
    },
    '&:hover > $inner, & > $inner.focusActive': {
      color: theme.palette.red.light,
      backgroundColor: theme.palette.grey.light,
      width: 'calc(100% + 20px)',
    },
    '&:hover > $inner $label': {
      textDecoration: 'none',
      fontWeight: 400,
    },
  },
  itemLevel3: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  labelLevel2: {
    padding: theme.spacing(2, 5),
    borderRadius: theme.spacing(5),
    '&:focus': {
      backgroundColor: theme.palette.background.focus,
      color: theme.palette.text.invert,
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.red.light,
      backgroundColor: theme.palette.grey.light,
    },
  },
  labelLevel3: {
    padding: theme.spacing(2, 5),
    borderRadius: theme.spacing(5),
    maxWidth: '100%',
    overflow: 'hidden',
    // whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    verticalAlign: 'top',

    '&:focus': {
      backgroundColor: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
    '&:hover': {
      backgroundColor: theme.palette.yellow.main,
      color: 'inherit',
    },
  },
  divider: {
    marginTop: '4px',
    paddingTop: '8px',
    marginLeft: '16px',
    width: '100%',
    borderTop: `solid 2px ${theme.palette.grey.light}`,
    '& > a': {
      marginLeft: '-16px',
    },
  },
}))

function NavItem({
  children,
  className,
  to,
  activeSlug,
  title,
  level,
  showDivider,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onNavigate,
}) {
  const classes = useStyles()
  const location = useLocation()

  function Wrapper({ children, hasChildren }) {
    const labelClasses = clsx(classes.label, {
      [classes[`labelLevel${level}`]]: !hasChildren,
    })
    if (to) {
      return (
        <Link
          className={labelClasses}
          color="inherit"
          to={to}
          activeClassName={classes.active}
          partiallyActive={to.match(/\//g)?.length > 2}
          onClick={onClick}
          onFocus={(ev) => {
            ev.stopPropagation()
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onNavigate()
            }
          }}
        >
          {children}
        </Link>
      )
    } else {
      return (
        <span
          className={clsx(labelClasses, {
            [classes.active]:
              activeSlug && location.pathname.startsWith(activeSlug),
          })}
        >
          {children}
        </span>
      )
    }
  }

  function openSubmenu(ev) {
    if (ev.key === 'Enter') {
      const items = [...ev.target.parentNode.parentNode.children]

      if (ev.target.classList.contains('focusActive')) {
        ev.target.classList.remove('focusActive')
        onMouseLeave && onMouseLeave()
      } else {
        items.forEach((item) => {
          const activeItem = item.querySelector('.focusActive')

          if (activeItem) {
            activeItem.classList.remove('focusActive')
          }
        })
        ev.target.classList.add('focusActive')
        onMouseEnter && onMouseEnter()
      }
      ev.stopPropagation()
    }
  }

  function onFocus(ev) {
    if (!ev.target.classList.contains('.focusActive')) {
      const activeItem =
        ev.target.parentNode.parentNode.querySelector('.focusActive')

      if (activeItem) {
        activeItem.classList.remove('focusActive')
      }
    }
    ev.stopPropagation()
  }

  return (
    <li
      className={clsx(className, classes.item, classes[`itemLevel${level}`], {
        [classes[`itemLevel${level}Sub`]]: children && level > 1,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={clsx(classes.inner, showDivider && classes.divider)}
        onKeyPress={level < 3 && children ? openSubmenu : null}
        tabIndex={children ? 0 : null}
        onFocus={onFocus}
        aria-haspopup={children ? true : false}
      >
        <Wrapper hasChildren={children ? true : false}>{title}</Wrapper>
        {children && (
          // ToDo: flip icon on first level open
          <Icon
            className={clsx(classes.icon, {
              [classes.toggle]: level === 1,
            })}
            name={level === 1 ? 'ToggleClose' : 'Next'}
            size="small"
          />
        )}
      </div>
      {children && children}
    </li>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  activeSlug: PropTypes.string,
  title: PropTypes.string.isRequired,
  level: PropTypes.number,
  showDivider: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onNavigate: PropTypes.func,
}

export default NavItem

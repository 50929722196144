module.exports = {
  navigation: [
    {
      messageId: 'navigation.inform',
      activeSlugId: 'inform.path',
      depth: 3,
      baseNextlevel: [
        {
          slugId: 'inform.path',
          messageId: 'navigation.inform.overview',
        },
      ],
    },
    { slugId: 'faq.path', messageId: 'footer.faq' },
    { slugId: 'glossary.path', messageId: 'navigation.glossary' },
  ],
  footer: [
    {
      headingId: 'footer.initiative',
      items: [
        { slugId: 'about.path', messageId: 'footer.about' },
        { slugId: 'contact.path', messageId: 'footer.contact' },
      ],
    },
    {
      headingId: 'footer.populartopics',
      items: [
        { slugId: 'footer.inform.path', messageId: 'footer.inform' },
        { slugId: 'dialog.path', messageId: 'footer.dialog' },
        { slugId: 'footer.faq.path', messageId: 'footer.faq' },
        { slugId: 'footer.glossary.path', messageId: 'footer.glossary' },
      ],
    },
    {
      headingId: 'footer.legal',
      items: [
        { slugId: 'imprint.path', messageId: 'footer.imprint' },
        { slugId: 'dataprivacy.path', messageId: 'footer.dataprivacy' },
        { slugId: 'accessiblity.path', messageId: 'footer.accessiblity' },
      ],
    },
  ],
}

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '14px',
    },
  },
  containerMobile: {
    padding: theme.spacing(1.25, 0),
  },

  containerDesktop: {
    paddingRight: theme.spacing(7.5),
  },

  text: {
    color: theme.palette.grey.dark,
    fontSize: '14px',
    paddingRight: theme.spacing(2),
  },
}))

function MetaNavigationItem({ title, icon, path }) {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Box
      className={clsx(
        classes.container,
        isDesktop ? classes.containerDesktop : classes.containerMobile
      )}
    >
      <Link className={classes.text} to={path}>
        {title}
      </Link>
      {!!icon && <Icon name={icon} />}
    </Box>
  )
}

MetaNavigationItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default MetaNavigationItem

/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
require('@formatjs/intl-getcanonicallocales/polyfill')
require('@formatjs/intl-pluralrules/polyfill')
require('@formatjs/intl-pluralrules/locale-data/en')
require('@formatjs/intl-pluralrules/locale-data/de')

const React = require('react')
const ReactIntl = require('react-intl')
const Layout = require('./../src/components/layout/index').default
const Metatags = require('./../src/objects/metatags/index').default

const { messages } = require('./../src/translations/index')
const { defaultLang } = require('./../src/translations/index')
const CssBaseline = require('@material-ui/core/CssBaseline').default
const { ThemeProvider } = require('@material-ui/core/styles')

const { DialogProvider } = require('../src/providers/dialogProvider')
const { TrackingProvider } = require('../src/providers/trackingProvider')
const {
  ToggleComponentProvider,
} = require('../src/providers/toggleComponentProvider')

const { TextParserProvider } = require('../src/providers/textParserProvider')

const {
  BasketComponentProvider,
} = require('../src/providers/basketComponentProvider')

const { SurveyProvider } = require('../src/providers/surveyProvider')

const createTheme = require('../config/theme/index')
const muitheme = createTheme()

exports.wrapPage = ({ element, props }) => {
  const hreflang = !!props.pageContext.locale
    ? props.pageContext.locale
    : 'no locale data'

  const hreflang_alt =
    !!props.data &&
    !!props.data.SlugByContentfulId &&
    !!props.data.SlugByContentfulId.node_locale
      ? props.data.SlugByContentfulId.node_locale
      : props.pageContext.locale === 'en'
      ? 'de'
      : (props.pageContext.locale === 'de' && props.path === '/informieren/') ||
        (props.pageContext.locale === 'de' && props.path === '/faqs/') ||
        (props.pageContext.locale === 'de' && props.path === '/glossar/')
      ? 'en'
      : null

  const href = !!props.path ? props.path : 'no fullPath data'
  const href_alt =
    !!props.data &&
    !!props.data.SlugByContentfulId &&
    !!props.data.SlugByContentfulId.fields
      ? props.data.SlugByContentfulId.fields.fullPath
      : props.path === '/en/'
      ? '/informieren/'
      : props.path === '/informieren/'
      ? '/en/'
      : props.path === '/en/glossary/'
      ? '/glossar/'
      : props.path === '/glossar/'
      ? '/en/glossary/'
      : props.path === '/en/faqs/'
      ? '/faqs/'
      : props.path === '/faqs/'
      ? '/en/faqs/'
      : null

  const href_default = hreflang === 'de' ? href : href_alt

  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ThemeProvider theme={muitheme}>
        <TrackingProvider>
          <SurveyProvider>
            <BasketComponentProvider>
              <TextParserProvider {...props}>
                <ToggleComponentProvider {...props}>
                  <DialogProvider {...props}>
                    {props.pageContext?.page?.metaTags && (
                      <Metatags
                        locale={props.pageContext.locale}
                        meta={props.pageContext.page.metaTags}
                        hreflang={hreflang}
                        hreflang_alt={hreflang_alt}
                        href={href}
                        href_alt={href_alt}
                        href_default={href_default}
                      />
                    )}
                    <CssBaseline />
                    <Layout {...props}>{element}</Layout>
                  </DialogProvider>
                </ToggleComponentProvider>
              </TextParserProvider>
            </BasketComponentProvider>
          </SurveyProvider>
        </TrackingProvider>
      </ThemeProvider>
    </ReactIntl.IntlProvider>
  )
}

import React from 'react'
import * as Yup from 'yup'
import { FormattedMessage } from 'react-intl'

const article = Yup.object({
  title: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.title.article.error" />
  ),
  text: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.text.article.error" />
  ),
})
const answer = Yup.object({
  title: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.title.answer.error" />
  ),
  text: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.text.answer.error" />
  ),
})
const comment = Yup.object({
  text: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.text.comment.error" />
  ),
})
const topics = Yup.object({
  topics: Yup.array().min(
    1,
    <FormattedMessage id="dialogue.popup.form.field.topics.error" />
  ),
})
const userData = Yup.object({
  userName: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.name.error" />
  ),
  userEmail: Yup.string()
    .email(<FormattedMessage id="dialogue.popup.form.field.email.error" />)
    .required(
      <FormattedMessage id="dialogue.popup.form.field.email.error.required" />
    ),
  termsOfPrivacy: Yup.boolean().oneOf(
    [true],
    <FormattedMessage id="dialogue.popup.form.field.dataprivacy.error" />
  ),
  friendlyCaptchaCode: Yup.string()
    .nullable()
    .required(
      <FormattedMessage id="dialogue.popup.form.field.recaptcha.error" />
    ),
})

const orderAddress = Yup.object({
  firstName: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.firstName.error" />
  ),
  lastName: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.lastName.error" />
  ),
  company: Yup.string(),
  department: Yup.string(),
  streetAndNumber: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.streetAndNumber.error" />
  ),
  postalCodeAndCity: Yup.string().required(
    <FormattedMessage id="dialogue.popup.form.field.postalCodeAndCity.error" />
  ),
  phoneNumber: Yup.string(),
  orderEmail: Yup.string()
    .email(<FormattedMessage id="dialogue.popup.form.field.email.error" />)
    .required(
      <FormattedMessage id="dialogue.popup.form.field.orderEmail.error" />
    ),
  orderMessage: Yup.string(),
})

const orderOverview = Yup.object({
  termsOfPrivacy: Yup.boolean().oneOf(
    [true],
    <FormattedMessage id="dialogue.popup.form.field.dataprivacy.error" />
  ),
  friendlyCaptchaCode: Yup.string()
    .nullable()
    .required(
      <FormattedMessage id="dialogue.popup.form.field.recaptcha.error" />
    ),
})

const articleSchema = [article, topics, userData]
const answerSchema = [answer, userData]
const commentSchema = [comment, userData]
const orderSchema = { orderAddress, orderOverview }

export { articleSchema, answerSchema, commentSchema, orderSchema }

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import finalCheck from '@utils/textParser/finalCheck'
import getGlossaryLink from '@utils/textParser/getGlossaryLink'

const TextParserContext = React.createContext()

export function TextParserProvider({ children }) {
  const [glossaryData, setGlossaryData] = useState({})
  const [glossaryInitialized, setGlossaryInitialized] = useState(false)
  let reg = new RegExp(
    '(?<![\\w\\u0080-\\uFFFF])(' +
      Object.keys(glossaryData).join('|') +
      ')(?![\\w\\u0080-\\uFFFF])',
    'g'
  )

  function parseText(text, excludes = [], type, found) {
    let wordsToExamine = []
    let foundEntries = found || []
    let words = []

    // initial checks
    if (!!text && text.length > 1) {
      if (
        Array.isArray(text) &&
        text.some((item) => typeof item === 'object')
      ) {
        text = text.filter(
          (item) => typeof item === 'object' || typeof item === 'string'
        )
      } else {
        // convert arrays and strings to single arrays
        if (Array.isArray(text)) {
          text = [text.join(' ').replace(/\s+([.,])/g, '$1')]
        } else if (typeof text === 'string') {
          text = [text.replace(/\s+([.,])/g, '$1')]
        } else {
          console.error('Expected text to be either an array or a string')
        }
      }
    }

    // STRINGS
    if (typeof text === 'string') {
      words = text.split(/\s+/).map((word, i) => {
        if (glossaryData[word]) {
          const elementSuffixSpace = ' '
          foundEntries.push(word)
          return (
            <>
              {elementSuffixSpace}
              {getGlossaryLink(word, i, type, glossaryData)}
              {elementSuffixSpace}
            </>
          )
        } else {
          return word
        }
      })
      wordsToExamine.push(words)
    }

    // ARRAYS, OBJECTS
    if (typeof text === 'object') {
      if (!Array.isArray(text)) {
        text = [text];
      }
      words = text
        .flatMap((item) => {
          if (typeof item === 'object') {
            return item
          } else {
            return item.split(reg)
          }
        })
        .map((word, i) => {
          if (!!glossaryData[word] && !foundEntries.includes(word)) {
            foundEntries.push(word)
            return getGlossaryLink(word, i, type, glossaryData)
          } else {
            return word
          }
        })
      wordsToExamine.push(words)
    }

    const parsedText = wordsToExamine.length > 0 && wordsToExamine[0].map((word, i) => {
      if (!foundEntries.includes(word) && !glossaryData[word]) {
        return word
      } else {
        return getGlossaryLink(word, i, type, glossaryData)
      }
    })

    return {
      text: finalCheck(parsedText, glossaryData),
      foundEntries: foundEntries,
    }
  }

  function updateGlossaryData(data) {
    const newData = {}
    for (let i = 0; i < data.length; i++) {
      if (data[i].synonyms) {
        for (let j = 0; j < data[i].synonyms.length; j++) {
          newData[data[i].synonyms[j]] = {
            id: data[i].synonyms[0].replace(/\s+/g, ''),
            title: data[i].title,
            tooltipMessage: data[i].tooltipMessage,
            tooltipOnly: data[i].tooltipOnly,
            description: data[i].description.json.content,
            synonyms: data[i].synonyms,
          }
        }
      }
    }
    setGlossaryData(newData)
    setGlossaryInitialized(true)
  }

  return (
    <TextParserContext.Provider
      value={{ parseText, updateGlossaryData, glossaryInitialized }}
    >
      {children}
    </TextParserContext.Provider>
  )
}

TextParserProvider.propTypes = {
  children: PropTypes.any,
}

export default TextParserContext

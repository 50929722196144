import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import Img from 'gatsby-image'

import { makeStyles } from '@material-ui/core/styles'

import Copy from '@objects/copy'
import Icon from '@objects/icon'
import YouTube from '@objects/youtube'

import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(13, 8),
  },
  headline: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '36px',
  },
  createdAt: {
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: theme.spacing(3),
  },
  copy: {
    fontSize: '14px',
    lineHeight: '22px',
    marginTop: theme.spacing(3),
    color: theme.palette.text.hint,
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    color: theme.palette.red.main,
    '&:hover': {
      color: theme.palette.red.light,
      textDecoration: 'none',
    },
    '&:focus': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
  },
  downloadIcon: {
    marginLeft: theme.spacing(2),
  },
  bottomCopy: {
    fontSize: '24px',
    lineHeight: '28px',
    marginTop: theme.spacing(5),
  },
  contentfulImage: {
    marginTop: theme.spacing(10),
    border: `${theme.palette.grey.light} solid 1px`,
  },
  contentfulVideo: {
    width: '100%',
    marginTop: theme.spacing(9),
  },
  contentfulYoutube: {
    marginTop: theme.spacing(9),
    marginBottom: 0,
  },
  hasButton: {
    display: 'flex',
    justifyContent: 'right',
  },
  navItem: {
    lineHeight: 'normal',
    display: 'inline-flex',
    marginTop: theme.spacing(4),
  },
  navIcon: {
    marginLeft: theme.spacing(2),
  },
}))

function DisplayMedia({ mediaItem, hasButton }) {
  const classes = useStyles()
  const intl = useIntl()

  function renderMedia(mediaItem, classes) {
    if (mediaItem.type === 'ContentfulImage') {
      return (
        <Img
          className={classes.contentfulImage}
          fluid={{ ...mediaItem.thumbnail, media: `(min-width: 0px)` }}
          alt={mediaItem.alt}
          backgroundColor="grey"
        />
      )
    } else if (mediaItem.type === 'ContentfulYoutube') {
      return (
        <YouTube
          className={classes.contentfulYoutube}
          ytid={mediaItem.youTubeKey}
          title={mediaItem.title}
          thumbnail={mediaItem.thumbnail}
          alt={mediaItem.alt}
          id={mediaItem.id}
        />
      )
    } else {
      return (
        <video
          data-track-content
          data-tracking-id={mediaItem.id}
          controls
          className={classes.contentfulVideo}
        >
          <source src={mediaItem.file.url} type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )
    }
  }

  function formattedFileContentType(item) {
    const contentType = item.file?.contentType
    if (contentType) {
      return ` ${contentType.replace('image/', '').toUpperCase()}`
    }
    return ''
  }

  function formattedFileSize(item) {
    const size = item.file?.details?.size
    if (!!size && size > 0) {
      const mbSize = size / 1000000
      return `(${mbSize.toFixed(2)}MB)`
    }
    return ''
  }

  return (
    <div className={classes.mainContainer}>
      <Copy className={classes.headline}>{mediaItem.title}</Copy>
      <Copy className={classes.createdAt}>{mediaItem.createdAt}</Copy>
      {mediaItem.description && (
        <Copy
          className={classes.copy}
          component={'div'}
          markdown={mediaItem.description}
        />
      )}
      {mediaItem.type === 'ContentfulImage' && (
        <>
          <a
            data-track-content
            data-tracking-id={mediaItem.id}
            data-tracking="true"
            data-tracking-category={mediaItem.category}
            data-tracking-action="Link Klick"
            data-tracking-name={
              mediaItem.trackingTitle === null
                ? 'Name Missing'
                : mediaItem.trackingTitle
            }
            href={mediaItem.file.url}
            className={classes.download}
            rel="noreferrer"
            target="_blank"
          >
            <FormattedMessage id={'download'} />
            {formattedFileContentType(mediaItem)} {formattedFileSize(mediaItem)}
            <Icon
              className={classes.downloadIcon}
              name={'Download'}
              size={'inherit'}
            />
          </a>
        </>
      )}
      {renderMedia(mediaItem, classes)}
      {mediaItem.type !== 'ContentfulImage' && (
        <Copy className={classes.bottomCopy}>{mediaItem.title}</Copy>
      )}
      {!!hasButton && (
        <div className={classes.hasButton}>
          <Button
            className={classes.navItem}
            type="secondary"
            color="red"
            onClick={() => {
              toggleOverlay(false)
            }}
            to={intl.formatMessage({
              id: 'media.path',
            })}
            useScrollOffset={false}
            dataTracking="true"
            dataTrackingCategory="Button"
            dataTrackingAction="Klick"
          >
            Alle Medien
            <Icon className={classes.navIcon} name="TextArrow" size="inherit" />
          </Button>
        </div>
      )}
    </div>
  )
}

DisplayMedia.propTypes = {
  mediaItem: PropTypes.object,
  //hasButton: PropTypes.bool,
}

export default DisplayMedia

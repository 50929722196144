import React, { useState } from 'react'

const SurveyContext = React.createContext()

export function SurveyProvider({ children }) {
  const [password, setPassword] = useState('')
  const [data, setData] = useState('')
  const [hasKanalSonstiges, setHasKanalSonstiges] = useState(false)

  return (
    <SurveyContext.Provider
      value={{
        password,
        setPassword,
        data,
        setData,
        hasKanalSonstiges,
        setHasKanalSonstiges,
      }}
    >
      {children}
    </SurveyContext.Provider>
  )
}

export default SurveyContext

import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { graphql, useStaticQuery } from 'gatsby'
import { useField } from 'formik'

import { makeStyles } from '@material-ui/core/styles'

import CheckboxField from '@objects/formFields/checkboxField'
import FormHelperText from '@objects/formFields/helperText'
import Headline from '@objects/headline'

const useStyles = makeStyles((theme) => ({
  headline: {
    maxWidth: '465px',
    margin: theme.spacing(10, 'auto', 5, 'auto'),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
    },
  },
  topics: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      columns: 2,
    },
  },
  checkboxOption: {
    ...theme.typography.checkboxOption,
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}))

function TopicsForm() {
  const intl = useIntl()
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField('topics')

  const { contentfulTopicCategoryList: { categories: topics = [] } = {} } =
    useStaticQuery(graphql`
      query {
        contentfulTopicCategoryList(
          identifier: { eq: "topiccategories" }
          node_locale: { eq: "de" }
        ) {
          categories {
            title
          }
        }
      }
    `) || {}

  return (
    <>
      <Headline className={classes.headline} level={3}>
        {intl.formatMessage({
          id: 'dialogue.popup.form.chooseTopics.headline',
        })}
      </Headline>
      <FormHelperText type={meta.touched && !!meta.error ? 'error' : 'info'}>
        {intl.formatMessage({
          id: 'dialogue.popup.form.field.topics.info',
        })}
      </FormHelperText>
      <div
        className={classes.topics}
        role="group"
        aria-labelledby="checkbox-group"
      >
        {topics.map((topic, i) => {
          return (
            <Fragment key={`topic${i}`}>
              {i !== 0 && <br />}
              <CheckboxField
                name={'topics'}
                value={topic.title}
                label={topic.title}
                isArray
              />
            </Fragment>
          )
        })}
      </div>
    </>
  )
}

TopicsForm.propTypes = {}

export default TopicsForm

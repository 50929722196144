import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function Metatags({
  meta,
  locale,
  hreflang,
  hreflang_alt,
  href,
  href_alt,
  href_default,
}) {
  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      {meta?.metaTitle && <title>{meta.metaTitle}</title>}

      {meta?.metaDescription && (
        <meta name="description" content={meta.metaDescription} />
      )}
      {meta?.ogTitle && <meta property="og:title" content={meta.ogTitle} />}
      <meta name="twitter:card" content="summary" />
      {meta?.ogDescription && (
        <meta property="og:description" content={meta.ogDescription} />
      )}
      {meta?.ogImage && <meta property="og:image" content={meta.ogImage} />}
      {meta?.canonical && <link rel="canonical" href={meta.canonical} />}
      {meta?.noIndex && <meta name="robots" content="noindex" />}

      {!!href_alt && !!hreflang_alt && (
        <link rel="alternate" hreflang={hreflang_alt} href={href_alt} />
      )}
      {!!href && !!hreflang && (
        <link rel="alternate" hreflang={hreflang} href={href} />
      )}
      {!!href_default && (
        <link rel="alternate" hreflang="x-default" href={href_default} />
      )}
    </Helmet>
  )
}

Metatags.propTypes = {
  meta: PropTypes.object,
  locale: PropTypes.string,
}

Metatags.defaultProps = {
  locale: 'de',
}

export default Metatags

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'

import Icon from '@objects/icon'
import Container from '@objects/container'
import useDialog from '@hooks/useDialog'

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 1000,
    position: 'relative',
  },
  crumbList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    color: theme.palette.grey.dark,
    display: 'flex',
    alignItems: 'flex-end',
    ...theme.typography.breadcrumb,
  },
  crumbItem: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      flex: '1 1 auto',
      overflow: 'hidden',
    },
  },
  link: {
    color: theme.palette.secondary.main,
    whiteSpace: 'nowrap',
    '&:focus': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
    '&:hover': {
      color: theme.palette.secondary.light,
      textDecoration: 'none',
    },
  },
  label: {
    color: theme.palette.secondary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  home: {
    position: 'relative',
    display: 'inline-block',
    '&:focus': {
      color: theme.palette.text.invert,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: theme.spacing(-2.5),
        left: theme.spacing(-2.5),
        background: theme.palette.background.focus,
        right: theme.spacing(-2.5),
        bottom: theme.spacing(-2.5),
        borderRadius: '50%',
      },
    },
    '&:hover': {
      color: theme.palette.secondary.light,
      '&:before': {
        content: 'none !important',
      },
    },
    '& svg': {
      position: 'relative',
      verticalAlign: 'top',
    },
  },
  next: {
    margin: theme.spacing(0, 3, 0.25, 5),
    verticalAlign: 'bottom',
    fontSize: 12,
  },
}))

function BreadCrumbs({ crumbList }) {
  const classes = useStyles()
  const intl = useIntl()
  const { PostData } = useDialog()

  function renderCrumbs() {
    const curCrumbs = [...crumbList]
    if (PostData.isSingle && PostData.articleTitle && curCrumbs.length > 1) {
      curCrumbs.push({
        label: PostData.articleTitle,
      })
    }
    return curCrumbs.map((crumb, i) => {
      if (crumb.link) {
        return (
          <li className={classes.crumbItem} key={`${crumb.link}${i}`}>
            <Icon className={classes.next} name="Next" size="small" />
            <Link
              to={`${crumb.link}`}
              data-testid={'crumblink'}
              className={clsx(classes.link)}
            >
              {crumb.label}
            </Link>
          </li>
        )
      } else {
        return (
          <li className={classes.crumbItem} key={`${crumb.link}${i}`}>
            <Icon className={classes.next} name="Next" size="small" />
            <span
              key={`${crumb.label}${i}`}
              data-testid={'crumblabel'}
              className={classes.label}
            >
              {crumb.label}
            </span>
          </li>
        )
      }
    })
  }
  return (
    <Container type="breadcrumb" className={classes.container}
    ariaLabel="arialabel.breadcrumb"
    >
      <nav
        aria-label={intl.formatMessage({ id: 'navigation.breadcrumb.label' })}
      >
        <ol className={classes.crumbList}>
          <li>
            <Link
              className={clsx(classes.home)}
              to={intl.formatMessage({ id: 'home.path' })}
            >
              <Icon
                name="Home"
                aria-label={intl.formatMessage({
                  id: 'navigation.breadcrumb.home',
                })}
                aria-hidden={false}
              />
            </Link>
          </li>
          {renderCrumbs()}
        </ol>
      </nav>
    </Container>
  )
}

BreadCrumbs.propTypes = {
  crumbList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default BreadCrumbs

import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'
import Copy from '@objects/copy'
import Icon from '@objects/icon'
import Button from '@objects/button'
import CustomSelect from '@objects/customSelect'
import Carousel from '@objects/carousel'
import useBasket from '@hooks/useBasket'
import Img from 'gatsby-image/withIEPolyfill'
import ToggleComponentContext from '@providers/toggleComponentProvider'
import BasketComponentContext from '../../../../providers/basketComponentProvider'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(13, 8),
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  headline: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '36px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: '30px',
  },
  copy: {
    fontSize: '14px',
    lineHeight: '22px',
    marginTop: '10px',
    color: theme.palette.text.hint,
  },
  download: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginTop: '20px',
    color: theme.palette.red.main,
    '&:hover': {
      color: theme.palette.red.light,
      textDecoration: 'none',
    },
    '&:focus': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
  },
  downloadIcon: {
    marginLeft: theme.spacing(2),
  },

  imagesContainer: {
    marginRight: '0',
    width: '100%',
    marginBottom: theme.spacing(5),

    [theme.breakpoints.up('lg')]: {
      width: '45%',
      marginBottom: 0,
      marginRight: '50px',
    },

    '& .swiper-slide': {
      height: 'auto',
      '& .gatsby-image-wrapper': {
        height: '100%',
      },
    },
  },
  carouselRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& .swiper-slide': {
      '& .gatsby-image-wrapper': {
        height: '100%',
      },
    },
  },
  informationContainer: {
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      width: '55%',
    },
  },
  infoTop: {
    paddingBottom: '30px',
    borderBottom: '1px solid #000000',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '112px',
    },
  },
  subheadline: {
    fontSize: '24px',
    lineHeight: '28px',
    marginTop: '30px',
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  option: {
    '&:first-child': {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      '&:first-child': {
        marginRight: '32px',
        marginBottom: 0,
      },
    },
  },
  openOrderButton: {
    lineHeight: 'normal',
    marginTop: theme.spacing(10),
    marginBottom: '31px',
  },
  openOrderIcon: {
    marginLeft: theme.spacing(2),
  },
  hasButton: {
    display: 'flex',
    justifyContent: 'right',
    borderTop: '1px solid #000000',
    marginTop: theme.spacing(6),
  },
  navItem: {
    lineHeight: 'normal',
    display: 'inline-flex',
    marginTop: theme.spacing(6),
  },
  navIcon: {
    marginLeft: theme.spacing(2),
  },
}))

function DisplayPrintMaterial({ printMaterial, hasButton }) {
  const classes = useStyles()
  const intl = useIntl()
  const [selectedVariant, setSelectedVariant] = useState(
    printMaterial.variant ?? 0
  )
  const availableAmounts = [
    { value: 50, text: '50', selectedText: '50 Stück' },
    { value: 100, text: '100', selectedText: '100 Stück' },
    { value: 150, text: '150', selectedText: '150 Stück' },
    { value: 200, text: '200', selectedText: '200 Stück' },
    { value: 250, text: '250', selectedText: '250 Stück' },
  ]
  const [selectedAmount, setSelectedAmount] = useState(
    printMaterial.amount ?? availableAmounts[0].value
  )
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const { addItem } = useBasket(BasketComponentContext)

  const [displayCarousel, setDisplayCarousel] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setDisplayCarousel(true)
    }, 1)
  }, [])

  function formattedFileContentType() {
    const contentType = getVariantFile().contentType
    let cleanContentType = contentType
    if (cleanContentType) {
      cleanContentType = cleanContentType.replace('image/', '')
      cleanContentType = cleanContentType.replace('application/', '')
      return ` ${cleanContentType.toUpperCase()}`
    }
    return ''
  }

  function formattedFileSize() {
    const size = getVariantFile().details?.size
    if (!!size && size > 0) {
      const mbSize = size / 1000000
      return `(${mbSize.toFixed(2)}MB)`
    }
    return ''
  }

  function openOrderDialog() {
    toggleOverlay(false)
    toggleOverlay(true, 'displayBasket', 'default', {
      trackingID: 'dialog',
      width: 'xl',
    })
  }

  function getVariantFile() {
    return printMaterial.variants[selectedVariant].variantFile.file
  }

  function renderImgSection() {
    if (printMaterial.images.length > 1) {
      if (displayCarousel) {
        return (
          <Carousel
            className={clsx(classes.carouselRoot)}
            pagination={'betweenArrows'}
          >
            {(() => {
              return printMaterial.images.map((item, index) => {
                return (
                  <Img
                    key={item.id}
                    fluid={item.fluid}
                    alt={item.description}
                    backgroundColor="grey"
                    objectFit={'contain'}
                  />
                )
              })
            })()}
          </Carousel>
        )
      }
      return null
    }

    return (
      <Img
        key={printMaterial.images[0].id}
        fluid={printMaterial.images[0].fluid}
        alt={printMaterial.images[0].description}
        backgroundColor="grey"
        objectFit={'contain'}
      />
    )
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.imagesContainer}>{renderImgSection()}</div>
      <div className={classes.informationContainer}>
        <div
          className={clsx({
            [classes.infoTop]: !printMaterial.isOnlyDownload,
          })}
        >
          <Copy className={classes.headline}>{printMaterial.title}</Copy>
          <Copy className={classes.subtitle}>{printMaterial.subtitle}</Copy>
          <Copy
            className={classes.copy}
            component={'div'}
            markdown={printMaterial.copy.copy}
          />
          <a
            data-track-content
            data-tracking-id={printMaterial.id}
            data-tracking="true"
            data-tracking-category="Printmaterial"
            data-tracking-action="Link Klick"
            data-tracking-name={
              printMaterial.trackingTitle === null
                ? 'Name Missing'
                : printMaterial.trackingTitle
            }
            href={getVariantFile().url}
            className={classes.download}
            rel="noreferrer"
            target="_blank"
          >
            <FormattedMessage id={'displayPrintMaterial.download'} />
            {`${formattedFileContentType()} ${formattedFileSize()}`}
            <Icon
              className={classes.downloadIcon}
              name={'Download'}
              size={'inherit'}
            />
          </a>
        </div>
        {!printMaterial.isOnlyDownload && (
          <>
            <Copy className={classes.subheadline}>
              <FormattedMessage id={'displayPrintMaterial.order'} />
            </Copy>
            <Copy className={classes.copy}>
              <FormattedMessage id={'displayPrintMaterial.instructions'} />
            </Copy>
            <div className={classes.options}>
              {printMaterial.variants.length > 1 && (
                <CustomSelect
                  className={classes.option}
                  label={intl.formatMessage({
                    id: 'displayPrintMaterial.variant',
                  })}
                  onOptionSelected={(option) => {
                    setSelectedVariant(option)
                  }}
                  options={printMaterial.variants.map((item, index) => {
                    return {
                      value: index,
                      text: item.variantName,
                    }
                  })}
                  defaultValue={selectedVariant}
                  selectWidth={'275px'}
                />
              )}
              <CustomSelect
                className={classes.option}
                label={intl.formatMessage({
                  id: 'displayPrintMaterial.amount',
                })}
                onOptionSelected={(option) => {
                  setSelectedAmount(option)
                }}
                options={availableAmounts}
                defaultValue={selectedAmount}
                selectWidth={'136px'}
              />
            </div>
            <Button
              className={classes.openOrderButton}
              type="primary"
              onClick={() => {
                addItem({
                  id: printMaterial.id,
                  title: printMaterial.title,
                  subtitle: printMaterial.subtitle,
                  copy: printMaterial.copy,
                  images: printMaterial.images,
                  variants: printMaterial.variants,
                  IsOnlyDownload: true,
                  variant: selectedVariant,
                  amount: selectedAmount,
                })
                openOrderDialog()
              }}
            >
              <FormattedMessage id={'displayPrintMaterial.orderButton'} />
              <Icon
                className={classes.openOrderIcon}
                name={'Basket'}
                size="inherit"
              />
            </Button>
          </>
        )}

        {!!hasButton && (
          <div className={classes.hasButton}>
            <Button
              className={classes.navItem}
              type="secondary"
              color="red"
              onClick={() => {
                toggleOverlay(false)
              }}
              to={intl.formatMessage({
                id: 'media.path',
              })}
              useScrollOffset={false}
              dataTracking="true"
              dataTrackingCategory="Button"
              dataTrackingAction="Klick"
            >
              Alle Medien
              <Icon
                className={classes.navIcon}
                name="TextArrow"
                size="inherit"
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

DisplayPrintMaterial.propTypes = {
  printMaterial: PropTypes.object,
}

export default DisplayPrintMaterial

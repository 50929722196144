const BundesSansRegularSrc = require('../../../static/font/BundesSans-Web-Regular.woff')
const BundesSansItalicSrc = require('../../../static/font/BundesSans-Web-RegularItalic.woff')
const BundesSansMediumSrc = require('../../../static/font/BundesSans-Web-Medium.woff')
const BundesSansBoldSrc = require('../../../static/font/BundesSans-Web-Bold.woff')

const BundesSansRegular = {
  fontFamily: 'BundesSans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${BundesSansRegularSrc}) format('woff')
  `,
}

const BundesSansItalic = {
  fontFamily: 'BundesSans',
  fontStyle: 'italic',
  fontWeight: 400,
  src: `
    url(${BundesSansItalicSrc}) format('woff')
  `,
}

const BundesSansMedium = {
  fontFamily: 'BundesSans',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    url(${BundesSansMediumSrc}) format('woff')
  `,
}

const BundesSansBold = {
  fontFamily: 'BundesSans',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    url(${BundesSansBoldSrc}) format('woff')
  `,
}

module.exports = [
  BundesSansRegular,
  BundesSansItalic,
  BundesSansMedium,
  BundesSansBold,
]

const axios = require('axios')

module.exports = async (type, id) => {
  const likeMap = {
    article: 'articleId',
    answer: 'answerId',
    comment: 'commentId',
  }

  const like = await axios.put(
    process.env.GATSBY_FORUM_SERVICE_URL + `/api/like/${type}`,
    {
      [likeMap[type]]: id,
    }
  )

  return like
}

import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import ToggleComponentContext from '@providers/toggleComponentProvider'

import Container from '@objects/container'
import SearchInput from '@objects/searchinput'

const useStyles = makeStyles((theme) => ({
  searchOverlayRoot: {
    width: '100%',
    paddingTop: theme.spacing(25),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(43),
    },
  },
}))

function SearchType({ index }) {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay, OverlayIsActive } = useContext(ToggleComponentContext)

  /**
   * a result is object with following properties
   * {
   *  id: Contentful Content-ID
   *  title: Title/Headline
   *  tags: Array of String - Which are the categories
   *  copy: The textcontent of the Copy field in Contentful
   *  path: The full path
   *  type: The content type (e.g. Aktion, News, Faq)
   * }
   */

  const [resultsAll, setResultsAll] = useState([])

  function search(searchquery) {
    setResultsAll(
      index
        .search(searchquery, {})
        .map(({ ref }) => index.documentStore.getDoc(ref))
        .filter((result) => result.locale === intl.locale)
    )
  }

  return (
    <div className={classes.searchOverlayRoot}>
      <Container width="md" ariaLabel="arialabel.searchinput">
        <SearchInput
          searchfunc={search}
          resultsAll={resultsAll}
          onSuggestClick={() => toggleOverlay(false)}
          onKeyEnter={() => toggleOverlay(false)}
          isShown={OverlayIsActive}
        />
      </Container>
    </div>
  )
}

SearchType.propTypes = {
  index: PropTypes.object.isRequired,
}

export default SearchType

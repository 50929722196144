import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { useTextParser, useGlossaryScope } from '@hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    listStyle: 'none',
    paddingLeft: theme.spacing(13),
    margin: 0,
  },
  simple: {
    listStyle: 'decimal',
    paddingLeft: theme.spacing(6),
    '& $item': {
      padding: theme.spacing(0, 0, 0, 2),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  item: {
    position: 'relative',
    padding: theme.spacing(1, 0),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(7),
    },
  },
  bulletNumber: {
    ...theme.typography.bullet,
    backgroundColor: theme.palette.red.main,
    borderRadius: '50%',
    textAlign: 'center',
    verticalAlign: 'middle',
    position: 'absolute',
    top: 0,
    left: -theme.spacing(13),
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}))

function OrderedList({
  children,
  style,
  parseGlossary,
  glossaryExcludes,
  glossaryType,
}) {
  const classes = useStyles()
  const { parseText, glossaryInitialized } = useTextParser()
  const { foundEntries, updateFoundEntries } = useGlossaryScope()
  const [parsedChildren, setParsedChildren] = useState(children)

  let count = 0

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries || []
          )
          updateFoundEntries && updateFoundEntries(parsed.foundEntries)
          return parsed.text
        })
      )
    }
  }, [glossaryInitialized])

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries
          )
          return parsed.text
        })
      )
    }
  }, [foundEntries])

  return (
    <ol
      className={clsx(classes.root, {
        [classes.simple]: style === 'simple',
      })}
    >
      {parsedChildren.map((item, i) => {
        if (item) {
          count += 1
          return (
            <Typography
              component="li"
              className={classes.item}
              key={`${item.created}${i}`}
            >
              {style === 'default' && (
                <span className={classes.bulletNumber}>{count}</span>
              )}
              {item}
            </Typography>
          )
        } else {
          return <Fragment key={i} />
        }
      })}
    </ol>
  )
}

OrderedList.propTypes = {
  children: PropTypes.any,
  style: PropTypes.oneOf(['default', 'simple']),
  parseGlossary: PropTypes.bool,
  glossaryExcludes: PropTypes.arrayOf(PropTypes.string),
  glossaryType: PropTypes.oneOf(['overlay', 'inline']),
}

OrderedList.defaultProps = {
  style: 'default',
  glossaryType: 'overlay',
}

export default OrderedList

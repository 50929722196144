import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'

import LanguageSelector from '@components/languageSelector'
import MetaNavigationItem from './metaNavigationItem'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  metaNavi: {
    width: '100%',
    display: 'flex',
    background: theme.palette.background.grey,
  },
  metaNaviDesktop: {
    paddingLeft: theme.spacing(145),
    justifyContent: 'center',
    height: theme.spacing(8.5),
  },
  metaNaviMobile: {
    flexDirection: 'column',
    justifyContent: 'left',
    padding: theme.spacing(2.5, 0, 3.75, 5.25),
  },
}))

function MetaNavigation({ metaNaviFields }) {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'))
  const intl = useIntl()

  return (
    <Box
      className={clsx(
        classes.metaNavi,
        isDesktop ? classes.metaNaviDesktop : classes.metaNaviMobile
      )}
      role="navigation"
      aria-label="Meta Navigation"
    >
      {metaNaviFields[0] && (
        <MetaNavigationItem
          title={intl.formatMessage({
            id: 'metanav.easylanguage',
          })}
          icon="EasyLanguage"
          path={intl.formatMessage({
            id: 'easylanguage.path',
          })}
        />
      )}

      {metaNaviFields[1] && (
        <MetaNavigationItem
          title={intl.formatMessage({
            id: 'metanav.signlanguage',
          })}
          icon="SignLanguage"
          path={intl.formatMessage({
            id: 'signlanguage.path',
          })}
        />
      )}
      <LanguageSelector />
    </Box>
  )
}

export default MetaNavigation

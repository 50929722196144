import React from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import RichTextEditor from '@objects/formFields/richtext'

import TextField from '@objects/formFields/textField'
import clsx from 'clsx'
import { Form } from 'formik'

const useStyles = makeStyles((theme) => ({
  items: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-15px -21px',
  },
  item: {
    padding: '15px 21px',
  },
  itemHalfW: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  itemFullW: {
    width: '100%',
  },
}))

function OrderAddressForm() {
  const intl = useIntl()
  const classes = useStyles()
  return (
    <Form id="printMaterialOrderAddress" data-testid="orderAddress">
      <div className={classes.items}>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="firstName-input"
            fullWidth
            variant="outlined"
            name="firstName"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.firstName.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.firstName.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="lastName-input"
            fullWidth
            variant="outlined"
            name="lastName"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.lastName.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.lastName.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="company-input"
            fullWidth
            variant="outlined"
            name="company"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.company.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.company.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="department-input"
            fullWidth
            variant="outlined"
            name="department"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.department.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.department.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="streetAndNumber-input"
            fullWidth
            variant="outlined"
            name="streetAndNumber"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.streetAndNumber.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.streetAndNumber.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="postalCodeAndCity-input"
            fullWidth
            variant="outlined"
            name="postalCodeAndCity"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.postalCodeAndCity.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.postalCodeAndCity.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="phoneNumber-input"
            fullWidth
            variant="outlined"
            name="phoneNumber"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.phoneNumber.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.phoneNumber.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemHalfW)}>
          <TextField
            id="orderEmail-input"
            fullWidth
            variant="outlined"
            name="orderEmail"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.orderEmail.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.orderEmail.placeholder`,
            })}
          />
        </div>
        <div className={clsx(classes.item, classes.itemFullW)}>
          <RichTextEditor
            key={'orderMessage-input'}
            id="orderMessage-input"
            name="orderMessage"
            ignoreReset
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.orderMessage.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.orderMessage.placeholder`,
            })}
            showInfoText
          />
        </div>
      </div>
    </Form>
  )
}

OrderAddressForm.propTypes = {}

export default OrderAddressForm

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox as FormCheckbox, Box } from '@material-ui/core'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 2,
    width: theme.spacing(6),
    height: theme.spacing(6),
    position: 'relative',
    '&.error': {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.main,
    },
    '&.disabled': {
      backgroundColor: theme.palette.background.light,
    },
  },
  checkedIcon: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: theme.spacing(-0.5),
    transform: 'translateY(-45%)',
  },
}))

function Checkbox({ className, name, error, ...props }) {
  const classes = useStyles()

  return (
    <>
      <FormCheckbox
        data-testid="checkbox"
        className={clsx(className, classes.checkbox, { error: error })}
        name={name}
        checkedIcon={
          <Box
            component={'span'}
            className={clsx(classes.icon)}
            border={2}
            borderColor={'grey.dark'}
          >
            <Icon
              name="Checkmark"
              className={classes.checkedIcon}
              size="large"
            />
          </Box>
        }
        icon={
          <Box
            component={'span'}
            className={clsx(classes.icon, {
              error: error,
              disabled: props.disabled,
            })}
            border={2}
            borderColor={props.disabled ? 'grey.main' : 'grey.dark'}
            borderRadius={2}
          />
        }
        {...props}
        inputProps={
          error
            ? {
                'aria-describedby': `error-${props.id}`,
              }
            : {}
        }
      />
    </>
  )
}

Checkbox.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

export default Checkbox

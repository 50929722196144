import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.GATSBY_FORUM_SERVICE_URL}/api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 15000,
})

export default apiClient

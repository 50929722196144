import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'
import clsx from 'clsx'
import { Link } from 'gatsby'
import { Form } from 'formik'

import { makeStyles } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'
import Button from '@objects/button'
import CustomSelect from '@objects/customSelect'
import CheckboxField from '@objects/formFields/checkboxField'
import FriendlyCaptcha from '@objects/formFields/friendlyCaptcha'

const useStyles = makeStyles((theme) => ({
  headline: {
    maxWidth: '465px',
    margin: theme.spacing(0, 'auto'),
    textAlign: 'center',
  },
  itemsSmallMargin: {
    marginTop: '31px',
  },
  itemsBigMargin: {
    marginTop: '61px',
  },
  itemsEmpty: {
    textAlign: 'center',
  },
  itemRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '&:not(:first-child)': {
      marginTop: '40px',
    },
  },
  itemImage: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '22%',
      height: '85px',
      border: `1px solid ${theme.palette.grey.light}`,
    },
  },
  infoColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '48%',
      marginTop: 0,
    },
  },
  itemTitle: {
    fontSize: '18px',
    lineHeight: '23px',
    fontWeight: 500,
  },
  itemAmount: {
    marginTop: '4px',
    marginBottom: '5px',
    lineHeight: '20px',
    fontSize: '12px',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '12px',
    lineHeight: '22px',
    fontWeight: 'bold',
  },
  deleteIcon: {
    marginRight: '5px',
    fontSize: '15px',
  },
  itemAction: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: '20%',
      marginTop: 0,
      textAlign: 'right',
    },
  },
  itemButton: {
    fontSize: '18px',
    lineHeight: 'normal',
    fontWeight: 'normal',
    height: '36px',
  },
  itemCustomSelect: {
    textAlign: 'left',
  },
  maxAmountWarning: {
    width: '100%',
    marginTop: '20px',
    marginBottom: 0,
    color: theme.palette.grey.dark,
  },
  maxAmountIcon: {
    fontSize: '20px',
    marginRight: '12px',
    marginLeft: '8px',
    verticalAlign: 'sub',
    color: theme.palette.grey.main,
  },
  link: {
    ...theme.typography.link,
  },
  separator: {
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    paddingTop: '26px',
    width: '100%',
    '$itemAction + &': {
      paddingTop: '35px',
    },
  },
  basketTitle: {
    marginTop: '19px',
    lineHeight: '23px',
    fontWeight: 500,
    fontSize: '18px',
  },
  orderAddressTitle: {
    marginTop: '29px',
    lineHeight: '23px',
    fontWeight: 500,
    fontSize: '18px',
  },
  orderAddress: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '21px',
  },
  orderAddressCopy: {
    whiteSpace: 'pre-wrap',
    lineHeight: '23px',
    fontSize: '18px',

    '&:not(:first-child)': {
      marginTop: '8px',
    },
  },
  finalValidation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '40px',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '35%',
    },
  },
  findMore: {
    marginTop: theme.spacing(4),
  },
}))

function OrderForm({
  items,
  isOverview,
  address,
  handleItemChange,
  handleAddressChange,
  handleRemoveItem,
  handleUpdateItemAmount,
  handleEmptyBasket,
}) {
  const intl = useIntl()
  const classes = useStyles()

  const availableAmounts = [
    { value: 50, text: '50', selectedText: '50 Stück' },
    { value: 100, text: '100', selectedText: '100 Stück' },
    { value: 150, text: '150', selectedText: '150 Stück' },
    { value: 200, text: '200', selectedText: '200 Stück' },
    { value: 250, text: '250', selectedText: '250 Stück' },
  ]

  function renderItems() {
    return items.map((item, index) => {
      return (
        <div
          data-testid="orderItem"
          key={`orderItem-${index}`}
          className={classes.itemRow}
        >
          <Img
            className={classes.itemImage}
            objectFit={'contain'}
            fluid={item.images[0].fluid}
            alt={item.images[0].description}
            backgroundColor={'grey'}
          />
          <div className={classes.infoColumn}>
            <Copy className={classes.itemTitle}>{item.title}</Copy>
            {isOverview && (
              <Copy className={classes.itemAmount}>
                <FormattedMessage
                  id="dialogue.popup.form.basket.orderOverview.itemAmount"
                  values={{
                    totalAmount: item.amount,
                  }}
                />
              </Copy>
            )}
            <Button
              type={'text'}
              noIcon
              className={classes.deleteButton}
              onClick={() => {
                handleRemoveItem(item)
              }}
            >
              <Icon
                className={classes.deleteIcon}
                name={'Delete'}
                size="inherit"
              />
              <FormattedMessage
                id={'dialogue.popup.form.basket.order.deleteButton'}
              />
            </Button>
          </div>
          <div className={classes.itemAction}>
            {isOverview ? (
              <Button
                className={classes.itemButton}
                color="outline"
                onClick={
                  handleItemChange
                    ? () => {
                        handleItemChange(index)
                      }
                    : null
                }
              >
                <FormattedMessage
                  id={'dialogue.popup.form.basket.orderOverview.change'}
                />
              </Button>
            ) : (
              <CustomSelect
                className={classes.itemCustomSelect}
                onOptionSelected={(option) => {
                  handleUpdateItemAmount(item, option)
                }}
                options={availableAmounts}
                defaultValue={item.amount}
                selectWidth={'100%'}
              />
            )}
          </div>
          {item.amount ===
            availableAmounts[availableAmounts.length - 1].value &&
            !isOverview && (
              <p className={classes.maxAmountWarning}>
                <Icon
                  className={classes.maxAmountIcon}
                  name={'Info'}
                  size="inherit"
                />
                <FormattedMessage
                  id={'dialogue.popup.form.basket.order.maxAmountWarning'}
                  values={{
                    a: function contact(contact) {
                      return (
                        <Link
                          className={classes.link}
                          to={intl.formatMessage({ id: 'contact.path' })}
                        >
                          {contact}
                        </Link>
                      )
                    },
                  }}
                />
              </p>
            )}
          <div className={classes.separator}></div>
        </div>
      )
    })
  }

  function renderEmptyBasket() {
    return (
      <>
        <Copy className={classes.itemTitle}>
          <FormattedMessage id="dialogue.popup.form.basket.emptyBasket" />
        </Copy>
        <Button
          className={classes.findMore}
          color="outline"
          onClick={() => {
            handleEmptyBasket()
          }}
          to={intl.formatMessage({ id: 'media.path.printMaterial' })}
        >
          <FormattedMessage id={'dialogue.popup.form.basket.button.findMore'} />
        </Button>
      </>
    )
  }

  function renderAddress() {
    return (
      <>
        <Copy className={classes.orderAddressTitle}>
          <FormattedMessage id="dialogue.popup.form.basket.orderOverview.orderAddress" />
        </Copy>
        <div data-testid="orderAddress" className={classes.orderAddress}>
          <div>
            <Copy className={classes.orderAddressCopy}>
              {`${address.firstName} ${address.lastName}`}
            </Copy>
            <Copy className={classes.orderAddressCopy}>{address.company}</Copy>
            <Copy className={classes.orderAddressCopy}>
              {address.department}
            </Copy>
            <Copy className={classes.orderAddressCopy}>
              {`${address.streetAndNumber}, ${address.postalCodeAndCity}`}
            </Copy>
          </div>
          <Button
            className={classes.itemButton}
            color="outline"
            onClick={handleAddressChange}
          >
            <FormattedMessage id="dialogue.popup.form.basket.orderOverview.change" />
          </Button>
        </div>
      </>
    )
  }

  function renderFinalValidation() {
    return (
      <Form id="printMaterialOrderOverview">
        <div className={classes.finalValidation}>
          <CheckboxField
            name={'askUserToReceiveEmails'}
            labelId="dialogue.popup.form.field.orderOverview.askToReceiveEmails"
          />
          <CheckboxField
            name="termsOfPrivacy"
            labelId="dialogue.popup.form.field.dataprivacy.label"
            labelLink="dataprivacy.path"
          />
          <FriendlyCaptcha name="friendlyCaptchaCode" background="white" />
        </div>
      </Form>
    )
  }

  return (
    <>
      <Headline className={classes.headline} level={3}>
        {isOverview ? (
          <FormattedMessage id="dialogue.popup.form.basket.orderOverview.headline" />
        ) : (
          <FormattedMessage
            id="dialogue.popup.form.basket.order.headline"
            values={{
              totalArticles: items.length,
            }}
          />
        )}
      </Headline>
      {isOverview && (
        <Copy className={classes.basketTitle}>
          <FormattedMessage id="dialogue.popup.form.basket.orderOverview.basket" />
        </Copy>
      )}
      <div
        className={clsx({
          [classes.itemsBigMargin]: !isOverview,
          [classes.itemsSmallMargin]: isOverview,
          [classes.itemsEmpty]: !!!items.length,
        })}
      >
        {renderItems()}
        {!!!items.length && isOverview && renderEmptyBasket()}
      </div>
      {isOverview && !!items.length && renderAddress()}
      {isOverview && !!items.length && renderFinalValidation()}
    </>
  )
}

OrderForm.propTypes = {
  isOverview: PropTypes.bool,
  handleAddressChange: PropTypes.func,
  handleItemChange: PropTypes.func,
  handleRemoveItem: PropTypes.func,
  handleUpdateItemAmount: PropTypes.func,
  handleEmptyBasket: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.array,
      title: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
  address: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    department: PropTypes.string,
    streetAndNumber: PropTypes.string,
    postalCodeAndCity: PropTypes.string,
    phoneNumber: PropTypes.string,
    orderEmail: PropTypes.string,
    orderMessage: PropTypes.string,
  }),
}

export default OrderForm

// colors for primary and secondary is only to overwrite default theme colors,
// we need to verify which values from our palette might make sense
module.exports = {
  grey: {
    main: '#b4b5b6',
    light: '#e2e3e5',
    medium: '#595959',
    dark: '#4b4b4b',
  },
  red: {
    main: '#aa1932',
    light: '#e11414',
  },
  yellow: {
    main: '#ffcc00',
  },
  orange: {
    main: '#ffaa32',
  },
  text: {
    primary: '#000000',
    disabled: '#b4b5b6',
    hint: '#4b4b4b',
    secondary: '#575556',
    invert: '#ffffff',
  },
  error: {
    main: '#e11414',
    light: '#fdecec',
  },
  primary: {
    main: '#ffcc00',
    dark: '#ffaa32',
  },
  secondary: {
    main: '#aa1932',
    light: '#e11414',
  },
  background: {
    default: '#ffffff',
    grey: '#f3f3f3',
    light: '#f0f1f2',
    focus: '#000000',
  },
  default: {
    main: '#ffffff',
  },
}

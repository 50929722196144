import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'

import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'

import { useTracking, useToggleComp } from '@hooks'
import Search from './types/search'
import Popup from './types/popup'
import Glossary from './types/glossary'
import DisplayMedia from './types/displayMedia'
import DisplayPrintMaterial from './types/displayPrintMaterial'
import DisplayBasket from '@components/forms/basketForm'
import CreateArticleForm from '@components/forms/createArticleForm'
import CreateAnswerForm from '@components/forms/createAnswerForm'
import CreateCommentForm from '@components/forms/createCommentForm'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    padding: theme.spacing(3),
    cursor: 'pointer',
    zIndex: 1,
    background: 'none',
    font: 'inherit',
    border: 0,
    outline: 0,
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
  closeIcon: {
    '&:focus': {
      color: theme.palette.text.invert,
      backgroundImage: `radial-gradient(ellipse at center, ${theme.palette.background.focus} 0%,${theme.palette.background.focus} 65%,transparent 67%,transparent 100%)`,
      borderRadius: '50%',
    },
  },
  closeSearch: {
    ...theme.typography.link,
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(14),
    },
    [theme.breakpoints.up('xl')]: {
      right: '50%',
      transform: `translateX(${parseInt(theme.container.lg, 10) / 2}px)`,
    },
  },
  closeLabel: {
    ...theme.typography.helpertext,
    marginRight: theme.spacing(2),
  },
  closeInvert: {
    color: theme.palette.text.invert,
    '&:focus': {
      color: theme.palette.text.primary,
      backgroundImage: `radial-gradient(ellipse at center, ${theme.palette.background.default} 0%,${theme.palette.background.default} 65%,transparent 67%,transparent 100%)`,
      borderRadius: '50%',
    },
  },
  noYellowBorder: {
    border: 0,
    borderRadius: 0,
  },
}))

function Overlay({ className }) {
  const overlayRef = useRef(null)
  const { pushMessage } = useTracking()
  const { OverlayIsActive, toggleOverlay, customProps } = useToggleComp()
  const classes = useStyles()
  const intl = useIntl()

  useEffect(() => {
    if (OverlayIsActive) {
      pushMessage(`custom.overlay-show-${customProps.trackingID}`, {
        message: customProps.trackingID,
      })
    }
  }, [OverlayIsActive])

  const handleClose = () => {
    toggleOverlay(false)
  }
  const callback = customProps?.onSuccessCallback
    ? customProps.onSuccessCallback
    : // () => {} // noop
      (response) => {
        console.info(`Submit ${customProps.type} default callback`, response)
      }
  function renderOverlayContent() {
    switch (customProps.type) {
      case 'search':
        return <Search index={customProps.index} />
      case 'createArticle':
        return <CreateArticleForm onSuccessCallback={callback} />
      case 'createAnswer':
        return (
          <CreateAnswerForm
            articleId={customProps.articleId}
            onSuccessCallback={callback}
          />
        )
      case 'createComment':
        return (
          <CreateCommentForm
            answerId={customProps.answerId}
            onSuccessCallback={callback}
          />
        )
      case 'glossary':
        return (
          <Glossary
            word={customProps.word}
            description={customProps.description}
            link={customProps.link}
            synonyms={customProps.synonyms}
          />
        )
      case 'displayMedia':
        return (
          <DisplayMedia
            mediaItem={customProps.mediaItem}
            hasButton={customProps.hasButton}
          />
        )
      case 'displayPrintMaterial':
        return (
          <DisplayPrintMaterial
            printMaterial={customProps.printMaterial}
            hasButton={customProps.hasButton}
          />
        )
      case 'displayBasket':
        return <DisplayBasket />
      default:
        return (
          <Popup
            contentType={customProps.type}
            onAcceptance={customProps.onAcceptance}
          />
        )
    }
  }

  return (
    <Dialog
      ref={overlayRef}
      data-testid="overlay"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={clsx(className, {
        active: OverlayIsActive,
      })}
      classes={{
        paper:
          customProps.type === 'displayMedia' ||
          customProps.type === 'displayPrintMaterial'
            ? classes.noYellowBorder
            : null,
      }}
      onClose={handleClose}
      open={OverlayIsActive}
      keepMounted
      fullScreen={customProps.dialogType === 'fullscreen'}
      maxWidth={customProps.width || 'lg'}
      fullWidth
    >
      {customProps.closeButton !== 'hide' && (
        <button
          data-testid="close-button"
          className={clsx(classes.close, {
            [classes.closeSearch]: customProps.type === 'search',
            [classes.closeIcon]: customProps.dialogType !== 'fullscreen',
            [classes.closeInvert]: customProps.closeButton === 'invert',
          })}
          onClick={handleClose}
          aria-label={intl.formatMessage({ id: 'overlay.close' })}
        >
          {customProps.dialogType === 'fullscreen' && (
            <span className={classes.closeLabel}>
              <FormattedMessage id="overlay.close" />
            </span>
          )}
          <Icon
            name="Close"
            color="inherit"
            size={customProps.type === 'search' ? 'small' : 'default'}
          />
        </button>
      )}

      {OverlayIsActive && renderOverlayContent()}
    </Dialog>
  )
}

Overlay.propTypes = {
  className: PropTypes.string,
}

export default Overlay

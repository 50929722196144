import React from 'react'
import GlossaryLink from '@objects/glossaryLink'
const getGlossaryLink = (word, i, type, glossaryData) => {
    if (typeof word === 'object') {
      return word
    } else {
      let glossaryItem = glossaryData[word]
      return (
        <GlossaryLink
          key={i}
          id={i}
          word={glossaryItem.id}
          tooltipMessage={glossaryItem.tooltipMessage}
          tooltipOnly={glossaryItem.tooltipOnly}
          title={glossaryItem.title}
          description={glossaryItem.description}
          synonyms={glossaryItem.synonyms}
          type={type || 'overlay'}
        >
          {word}
        </GlossaryLink>
      )
    }
  }

  export default getGlossaryLink

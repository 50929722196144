import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'

import { useField } from 'formik'

import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Checkbox from './checkbox'
import FormHelperText from './helperText'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    ...theme.typography.helpertext,
    color: theme.palette.text.hint,
    marginRight: 'auto',
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  checkboxOption: {
    alignItems: 'flex-start',
    ...theme.typography.checkboxOption,
    '&.error': {
      color: theme.palette.error.main,
    },
    '& $label': {
      lineHeight: 1.3,
      padding: theme.spacing(3, 0),
    },
  },
  link: {
    ...theme.typography.link,
  },
  label: {},
}))

function CheckboxField(props) {
  const classes = useStyles()
  const intl = useIntl()
  const { label, labelId, labelLink, value, isArray, disabled } = props
  const [field, meta] = useField(props)

  function getLabel() {
    if (label) {
      return label
    } else if (labelId) {
      return (
        <FormattedMessage
          id={labelId}
          values={{
            a: function terms(terms) {
              return labelLink ? (
                <a
                  className={classes.link}
                  href={intl.formatMessage({
                    id: labelLink,
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  {terms}
                </a>
              ) : (
                terms
              )
            },
          }}
        />
      )
    } else {
      return ''
    }
  }

  return (
    <>
      <FormControlLabel
        classes={{
          root: isArray ? classes.checkboxOption : classes.checkbox,
          label: classes.label,
        }}
        checked={isArray ? field.value.includes(value) : field.value}
        control={
          <Checkbox
            {...field}
            value={value}
            error={meta.touched && !!meta.error}
            disabled={disabled}
            id={labelId}
          />
        }
        label={getLabel()}
      />
      {meta.touched && meta.error && !isArray && (
        <FormHelperText type="error" id={`error-${labelId}`}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  )
}

CheckboxField.propTypes = {
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelLink: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  isArray: PropTypes.bool,
  disabled: PropTypes.bool,
}
export default CheckboxField

const axios = require('axios')

module.exports = async (opts = {}) => {
  const optKeys = Object.keys(opts)
  let validKeys = true
  optKeys.forEach((key) => {
    if (
      !['seek', 'limit', 'sortOrder', 'topicIds', 'overviewModul'].includes(key)
    ) {
      console.error('invalid forum post query option: ', key)
      validKeys = false
    }
  })
  if (!validKeys) {
    return
  }

  const defaultOpts = {
    seek: null,
    limit: 10,
    sortOrder: 'Newest',
    overviewModul: false,
    topicIds: [],
  }

  const posts = await axios.post(
    process.env.GATSBY_FORUM_SERVICE_URL + '/api/query/articles/filter',
    {
      ...defaultOpts,
      ...opts,
    }
  )

  return posts
}

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import Stepper from '@objects/stepper'
import Button from '@objects/button'

import ContentForm from './content'
import TopicsForm from './topics'
import UserDataForm from './userData'

const useStyles = makeStyles((theme) => ({
  createArticleRoot: {
    padding: theme.spacing(10, 5, 5, 5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20),
    },
  },
  actions: {
    display: 'flex',
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
  },
  next: {
    marginLeft: 'auto',
  },
}))

function renderActiveFormStep(form) {
  switch (form) {
    case 'article':
      return <ContentForm type="article" />
    case 'answer':
      return <ContentForm type="answer" />
    case 'comment':
      return <ContentForm type="comment" />
    case 'topics':
      return <TopicsForm />
    case 'userData':
      return <UserDataForm />
    default:
      return null
  }
}

function CreateContentForm({
  steps,
  validationSchema,
  initialValues,
  onSubmit,
  onSuccessCallback,
}) {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const [activeStep, setActiveStep] = useState(0)
  const isLastStep = activeStep === steps.titles.length - 1

  const currentValidationSchema = validationSchema[activeStep]

  const completeInitialValues = {
    ...initialValues,
    text: '',
    userName: localStorage.getItem('userName') || '',
    userEmail: localStorage.getItem('userEmail') || '',
    informAuthorOfNewContent: false,
    termsOfPrivacy: false,
    friendlyCaptchaCode: '',
    accessToken: localStorage.getItem('accessToken') || '',
  }

  function _submitForm(values) {
    localStorage.setItem('userName', values.userName)
    localStorage.setItem('userEmail', values.userEmail)

    onSubmit(values)
      .then((response) => {
        if (response.data.status === 'UserApprovalNeeded') {
          toggleOverlay(true, 'confirmation', 'default', {
            trackingID: 'confirmation',
            width: 'sm',
            closeButton: 'hide',
            onAcceptance: () => toggleOverlay(false),
          })
        } else if (response.data.status === 'EditorApprovalNeeded') {
          toggleOverlay(true, 'editorapproval', 'default', {
            trackingID: 'editorapproval',
            width: 'sm',
            closeButton: 'hide',
            onAcceptance: () => toggleOverlay(false),
          })
        } else if (response.data.status === 'UserIsBlacklisted') {
          toggleOverlay(true, 'blacklist', 'default', {
            trackingID: 'blacklist',
            width: 'sm',
            closeButton: 'hide',
            onAcceptance: () => toggleOverlay(false),
          })
        } else if (response.data.status === 'IsPublished') {
          // TODO: GDIAL-154, if answer/comment on postpage reload and scrollto new content
          toggleOverlay(false)
          onSuccessCallback && onSuccessCallback(response.data)
        }
      })
      .catch((err) => {
        console.error('CreateContent error: ', err)
      })
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions)
    } else {
      actions.setTouched({})
      actions.setSubmitting(false)
      setActiveStep(activeStep + 1)
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1)
  }

  return (
    <div data-testid="createContent" className={classes.createArticleRoot}>
      <Stepper steps={steps.titles} activeStep={activeStep} />

      <Formik
        initialValues={completeInitialValues}
        validationSchema={currentValidationSchema}
        onSubmit={_handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form id="createContent">
            {renderActiveFormStep(steps.forms[activeStep])}

            <div className={classes.actions}>
              {activeStep !== 0 && (
                <Button color="outline" onClick={_handleBack}>
                  {intl.formatMessage({ id: 'dialogue.popup.form.prev' })}
                </Button>
              )}
              <Button
                className={classes.next}
                disabled={isSubmitting}
                formSubmit
                formNoValidate
              >
                <FormattedMessage id="dialogue.popup.form.next" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

CreateContentForm.propTypes = {
  steps: PropTypes.shape({
    titles: PropTypes.arrayOf(PropTypes.string),
    forms: PropTypes.array,
  }),
  validationSchema: PropTypes.array,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSuccessCallback: PropTypes.func,
}

export default CreateContentForm

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import { Fade } from '@material-ui/core'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    outline: 'none',
    background: 'none',
    fontFamily: 'inherit',
    lineHeight: 1.1,
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionDuration: theme.transitions.duration.shorter,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
  fullwidth: {
    width: '100%',
  },
  primary: {
    height: theme.spacing(12),
    padding: theme.spacing(0, 5),
    ...theme.typography.buttonPrimary,
    borderRadius: '25px',
  },
  secondary: {
    height: theme.spacing(9),
    padding: theme.spacing(0, 5),
    ...theme.typography.buttonSecondary,
    borderRadius: '25px',
  },
  text: {
    ...theme.typography.buttonText,
    ...theme.typography.link,
    padding: 0,
    display: 'inline',
  },
  yellow: {
    backgroundColor: theme.palette.yellow.main,
    '&:focus': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
    '&:not(.disabled):hover': {
      backgroundColor: theme.palette.orange.main,
      color: 'inherit',
    },
    '&.disabled': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey.main,
    },
  },
  grey: {
    backgroundColor: theme.palette.background.grey,
    color: theme.palette.red.main,
    '&:focus': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
    '&:not(.disabled):hover': {
      backgroundColor: theme.palette.grey.main,
    },
    '&.disabled': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey.main,
    },
  },
  red: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.red.main,
    '&:focus': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
    '&:not(.disabled):hover': {
      backgroundColor: theme.palette.red.light,
      color: theme.palette.common.white,
    },
    '&.disabled': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey.main,
    },
  },
  backtop: {
    height: 'auto',
    padding: theme.spacing(4),
    display: 'inline-block',
    position: 'fixed',
    zIndex: 5,
    right: theme.spacing(25),
    bottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(5),
    },
  },
  outline: {
    color: theme.palette.red.main,
    border: '2px solid currentColor',
    backgroundColor: theme.palette.background.default,
    '&:focus': {
      color: theme.palette.text.primary,
    },
    '&:not(.disabled):hover': {
      color: theme.palette.red.light,
    },
  },
  outlineGrey: {
    ...theme.typography.buttonText,
    fontWeight: 400,
    color: theme.palette.grey.main,
    border: `2px solid ${theme.palette.grey.medium}`,
    backgroundColor: theme.palette.background.default,
    '&:focus': {
      color: theme.palette.text.primary,
    },
    '&:not(disabled):hover': {
      color: theme.palette.grey.dark,
    },
  },
  icon: {
    padding: theme.spacing(4),
    borderRadius: '50%',
  },
  play: {
    padding: theme.spacing(7),
    '& svg': {
      transform: 'translateX(10%)',
    },
  },
  iconText: {
    marginLeft: theme.spacing(1),
    verticalAlign: '-4px',
  },
}))

function Wrapper({
  children,
  className,
  type,
  fullwidth,
  color,
  icon,
  hidden,
  to,
  formSubmit,
  link,
  href,
  backtop,
  useScrollOffset,
  isVisible,
  isExternal,
  dataTracking,
  dataTrackingCategory,
  dataTrackingAction,
  dataTrackingName,
  ...props
}) {
  const classes = useStyles()
  const intl = useIntl()
  const btnClasses = clsx(className, classes.root, classes[type], {
    [classes[color]]: type !== 'text',
    [classes.play]: type === 'icon' && icon === 'Play',
    [classes.fullwidth]: fullwidth,
    disabled: props.disabled,
  })

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  function scrollTo(to) {
    const el = document.querySelector(to)
    const scrollOffset = useScrollOffset ? 100 : 0
    if (el) {
      window.scrollTo({
        top: el.getBoundingClientRect().y + window.scrollY - scrollOffset,
        behavior: 'smooth',
      })
    }
  }

  if (hidden) {
    return null
  } else if (to && to.startsWith('#')) {
    return (
      <button
        type="button"
        className={btnClasses}
        onClick={() => scrollTo(to)}
        data-testid={'buttonscroll'}
        data-tracking={dataTracking}
        data-tracking-category={dataTrackingCategory}
        data-tracking-action={dataTrackingAction}
        data-tracking-name={dataTrackingName}
        {...props}
      >
        {children}
      </button>
    )
  } else if (to || (link && !isExternal(link))) {
    return (
      <Link
        data-track-content
        data-tracking-id={to || link}
        className={btnClasses}
        to={to || link}
        data-testid={'buttongatsbylink'}
        {...props}
      >
        {children}
      </Link>
    )
  } else if (href || (link && isExternal(link))) {
    let external = href || link
    if (!external.match(/^((http[s]?|ftp|mailto|tel|fax|javascript):)/)) {
      external = `https://${external}`
    }
    return (
      <a
        data-track-content
        data-tracking-id={external}
        className={btnClasses}
        href={external}
        // eslint-disable-next-line no-script-url
        target={external.includes('javascript:') ? null : '_blank'}
        rel="noopener noreferrer"
        data-testid={'buttonlink'}
        {...props}
      >
        {children}
      </a>
    )
  } else if (backtop) {
    return (
      <Fade in={isVisible} timeout={1000}>
        <button
          type="button"
          className={clsx(btnClasses, classes.backtop)}
          data-testid={'buttonbacktop'}
          aria-hidden={true}
          {...props}
          onClick={scrollTop}
        >
          <Icon name="BackTop" size="large" />
        </button>
      </Fade>
    )
  } else {
    return (
      <button
        type={formSubmit ? 'submit' : 'button'}
        className={btnClasses}
        data-testid={'buttondefault'}
        {...props}
      >
        {children}
      </button>
    )
  }
}

/**
 *
 * @description You can set "to" for internal links, "href" for external links or "link",
 * when object should determine by the given string, if it is internal or external.
 * If link is set and link includes "www." it is considerd as external.
 * Otherwise if link is set on link does NOT include "www." it is considered as internal.
 */
function Button({
  className,
  children,
  hidden,
  to,
  href,
  link,
  backtop,
  icon,
  noIcon,
  type,
  color,
  formSubmit,
  fullwidth,
  useScrollOffset,
  dataTracking,
  dataTrackingCategory,
  dataTrackingAction,
  dataTrackingName,
  ...props
}) {
  const classes = useStyles()
  const [isVisible, setVisibility] = useState(false)
  const isExternal = (url) => {
    return (url.match(/^((http[s]?|ftp|mailto|tel|fax|javascript):)|www\./) && !url.includes('www.deutschland-spricht-ueber-5g.de')) ? true : false;
  }

  useEffect(() => {
    if (!backtop) return
    setVisibility(false)
    document.addEventListener('scroll', toggleVisibility)
    return () => {
      document.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  function toggleVisibility() {
    if (window.pageYOffset > 50) {
      setVisibility(true)
    } else {
      setVisibility(false)
    }
  }

  function renderIcon() {
    if (type === 'icon') {
      return <Icon name={icon} size="large" />
    } else {
      const external = href || (link && isExternal(link))
      const name = icon
        ? icon
        : type === 'text'
        ? external
          ? 'TextExternalArrow'
          : 'TextArrow'
        : null

      if (name) {
        return <Icon name={name} className={classes.iconText} size="inherit" />
      }
    }

    return null
  }

  return (
    <Wrapper
      className={className}
      hidden={hidden}
      to={to}
      href={href}
      link={link}
      backtop={backtop}
      icon={icon}
      type={type}
      color={color}
      formSubmit={formSubmit}
      fullwidth={fullwidth}
      isVisible={isVisible}
      useScrollOffset={useScrollOffset}
      isExternal={isExternal}
      dataTracking={dataTracking}
      dataTrackingCategory={dataTrackingCategory}
      dataTrackingAction={dataTrackingAction}
      dataTrackingName={dataTrackingName}
      {...props}
    >
      {!(type === 'icon') && children}
      {!noIcon && renderIcon()}
    </Wrapper>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  link: PropTypes.string,
  backtop: PropTypes.bool,
  formSubmit: PropTypes.bool,
  fullwidth: PropTypes.bool,
  icon: PropTypes.string,
  noIcon: PropTypes.bool,
  useScrollOffset: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'text', 'icon']),
  color: PropTypes.oneOf(['yellow', 'red', 'outline', 'grey', 'outlineGrey']),
}

Button.defaultProps = {
  type: 'primary',
  color: 'yellow',
  useScrollOffset: true,
}

export default Button

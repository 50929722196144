import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import api from '@api'
import CreateContentForm from './createContent/form'

import { commentSchema } from './validationSchema'

function CreateCommentForm({ answerId, onSuccessCallback }) {
  const intl = useIntl()

  const formInitialValues = {
    answerId: parseInt(answerId, 10),
  }

  const steps = {
    titles: [
      intl.formatMessage({
        id: 'dialogue.popup.form.createContent.comment.step',
      }),
      intl.formatMessage({ id: 'dialogue.popup.form.publish.comment.step' }),
    ],
    forms: ['comment', 'userData'],
  }

  return (
    <CreateContentForm
      steps={steps}
      validationSchema={commentSchema}
      initialValues={formInitialValues}
      onSubmit={api().createComment}
      onSuccessCallback={onSuccessCallback}
    />
  )
}

CreateCommentForm.propTypes = {
  answerId: PropTypes.number,
  onSuccessCallback: PropTypes.func,
}

export default CreateCommentForm

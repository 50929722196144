import React from 'react'
import PropTypes from 'prop-types'

import SvgIcon from '@material-ui/core/SvgIcon'

import Info from '../../../static/img/svgIcons/icon_information.inline.svg'
import Album from '../../../static/img/svgIcons/icon_album.inline.svg'
import ArrowDown from '../../../static/img/svgIcons/icon_arrow_down.inline.svg'
import ArrowUp from '../../../static/img/svgIcons/icon_arrow_up.inline.svg'
import ArrowRight from '../../../static/img/svgIcons/icon_arrow_right.inline.svg'
import ArrowLeft from '../../../static/img/svgIcons/icon_arrow_left.inline.svg'
import CallMadeIcon from '@material-ui/icons/CallMade'
import ChevronLeftBig from '../../../static/img/svgIcons/icon_big_chevron_left.inline.svg'
import ChevronRightBig from '../../../static/img/svgIcons/icon_big_chevron_right.inline.svg'
import Article from '../../../static/img/svgIcons/icon_article.inline.svg'
import BackTop from '../../../static/img/svgIcons/icon_backtop.inline.svg'
import Checkmark from '../../../static/img/svgIcons/icon_checkmark.inline.svg'
import ChevronLeft from '../../../static/img/svgIcons/icon_chevron_left.inline.svg'
import ChevronRight from '../../../static/img/svgIcons/icon_chevron_right.inline.svg'
import ChevronDown from '../../../static/img/svgIcons/icon_chevron_down.inline.svg'
import ChevronUp from '../../../static/img/svgIcons/icon_chevron_up.inline.svg'
import Contribute from '../../../static/img/svgIcons/icon_contribution.inline.svg'
import Close from '../../../static/img/svgIcons/icon_close.inline.svg'
import Download from '../../../static/img/svgIcons/icon_download.inline.svg'
import Facebook from '../../../static/img/svgIcons/icon_facebook.inline.svg'
import Image from '../../../static/img/svgIcons/icon_image.inline.svg'
import Twitter from '../../../static/img/svgIcons/icon_twitter.inline.svg'
import Youtube from '../../../static/img/svgIcons/icon_youtube.inline.svg'
import Like from '../../../static/img/svgIcons/icon_like.inline.svg'
import LikeOutline from '../../../static/img/svgIcons/icon_like_outline.inline.svg'
import LocationPin from '../../../static/img/svgIcons/icon_location_pin.inline.svg'
import Home from '../../../static/img/svgIcons/icon_home.inline.svg'
import Link from '../../../static/img/svgIcons/icon_link.inline.svg'
import Minus from '../../../static/img/svgIcons/icon_minus.inline.svg'
import Plus from '../../../static/img/svgIcons/icon_plus.inline.svg'
import Pen from '../../../static/img/svgIcons/icon_pen.inline.svg'
import Question from '../../../static/img/svgIcons/icon_question.inline.svg'
import Search from '../../../static/img/svgIcons/icon_search.inline.svg'
import Play from '../../../static/img/svgIcons/icon_play.inline.svg'
import Basket from '../../../static/img/svgIcons/icon_basket.inline.svg'
import Delete from '../../../static/img/svgIcons/icon_delete.inline.svg'
import Logo from '../../../static/img/svgIcons/icon_logo.inline.svg'
import Infographic from '../../../static/img/svgIcons/icon_infographic.inline.svg'
import RadioButtonActive from '../../../static/img/svgIcons/icon_radio_button_active.inline.svg'
import RadioButtonInactive from '../../../static/img/svgIcons/icon_radio_button_inactive.inline.svg'
import PrintMaterial from '../../../static/img/svgIcons/icon_printmaterial.inline.svg'
import Video from '../../../static/img/svgIcons/icon_video.inline.svg'
import Instagram from '../../../static/img/svgIcons/icon_instagram.inline.svg'
import Translation from '../../../static/img/svgIcons/icon_translation.inline.svg'
import EasyLanguage from '../../../static/img/svgIcons/icon_easy_language.inline.svg'
import SignLanguage from '../../../static/img/svgIcons/icon_sign_language.inline.svg'

import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'

function Icon({ className, name, size, color, htmlColor, ...props }) {
  const svgProps = {
    className: className,
    fontSize: size,
    color: color,
    viewBox: '0 0 47 47',
    htmlColor: htmlColor,
    'aria-labelledby': name,
    role: 'img',
    ...props,
  }

  function renderIcon() {
    switch (name) {
      case 'Album':
        return (
          <SvgIcon {...svgProps}>
            <Album />
          </SvgIcon>
        )
      case 'ToggleClose':
        return (
          <SvgIcon {...svgProps}>
            <ChevronDown />
          </SvgIcon>
        )
      case 'ToggleOpen':
        return (
          <SvgIcon {...svgProps}>
            <ChevronUp />
          </SvgIcon>
        )
      case 'ArrowDown':
        return (
          <SvgIcon {...svgProps}>
            <ArrowDown />
          </SvgIcon>
        )
      case 'ArrowUp':
        return (
          <SvgIcon {...svgProps}>
            <ArrowUp />
          </SvgIcon>
        )
      case 'ArrowLeft':
        return (
          <SvgIcon {...svgProps}>
            <ArrowLeft />
          </SvgIcon>
        )
      case 'TextArrow':
        return (
          <SvgIcon {...svgProps}>
            <ArrowRight />
          </SvgIcon>
        )
      case 'TextExternalArrow':
        return (
          <SvgIcon {...svgProps}>
            <CallMadeIcon />
          </SvgIcon>
        )
      case 'Article':
        return (
          <SvgIcon {...svgProps}>
            <Article />
          </SvgIcon>
        )
      case 'BackTop':
        return (
          <SvgIcon {...svgProps}>
            <BackTop />
          </SvgIcon>
        )
      case 'Checkmark':
        return (
          <SvgIcon {...svgProps}>
            <Checkmark />
          </SvgIcon>
        )
      case 'Prev':
        return (
          <SvgIcon {...svgProps}>
            <ChevronLeft />
          </SvgIcon>
        )
      case 'PrevBig':
        return (
          <SvgIcon {...svgProps}>
            <ChevronLeftBig />
          </SvgIcon>
        )
      case 'Next':
        return (
          <SvgIcon {...svgProps}>
            <ChevronRight />
          </SvgIcon>
        )
      case 'NextBig':
        return (
          <SvgIcon {...svgProps}>
            <ChevronRightBig />
          </SvgIcon>
        )
      case 'Close':
        return (
          <SvgIcon {...svgProps}>
            <Close />
          </SvgIcon>
        )
      case 'Contribute':
        return (
          <SvgIcon {...svgProps}>
            <Contribute />
          </SvgIcon>
        )
      case 'Download':
        return (
          <SvgIcon {...svgProps}>
            <Download />
          </SvgIcon>
        )
      case 'Facebook':
        return (
          <SvgIcon {...svgProps}>
            <Facebook />
          </SvgIcon>
        )
      case 'Image':
        return (
          <SvgIcon {...svgProps}>
            <Image />
          </SvgIcon>
        )
      case 'Twitter':
        return (
          <SvgIcon {...svgProps}>
            <Twitter />
          </SvgIcon>
        )
      case 'Youtube':
        return (
          <SvgIcon {...svgProps}>
            <Youtube />
          </SvgIcon>
        )

      case 'Instagram':
        return (
          <SvgIcon {...svgProps}>
            <Instagram />
          </SvgIcon>
        )
      case 'Home':
        return (
          <SvgIcon {...svgProps}>
            <Home />
          </SvgIcon>
        )
      case 'Info':
        return (
          <SvgIcon {...svgProps}>
            <Info />
          </SvgIcon>
        )
      case 'Like':
        return (
          <SvgIcon {...svgProps}>
            <Like />
          </SvgIcon>
        )
      case 'LikeOutline':
        return (
          <SvgIcon {...svgProps}>
            <LikeOutline />
          </SvgIcon>
        )
      case 'Link':
        return (
          <SvgIcon {...svgProps}>
            <Link />
          </SvgIcon>
        )
      case 'LocationPin':
        return (
          <SvgIcon {...svgProps}>
            <LocationPin />
          </SvgIcon>
        )
      case 'AccordionOpen':
        return (
          <SvgIcon {...svgProps}>
            <Plus />
          </SvgIcon>
        )
      case 'AccordionClose':
        return (
          <SvgIcon {...svgProps}>
            <Minus />
          </SvgIcon>
        )
      case 'Pen':
        return (
          <SvgIcon {...svgProps}>
            <Pen />
          </SvgIcon>
        )
      case 'Question':
        return (
          <SvgIcon {...svgProps}>
            <Question />
          </SvgIcon>
        )
      case 'Search':
        return (
          <SvgIcon {...svgProps}>
            <Search />
          </SvgIcon>
        )
      case 'Play':
        return (
          <SvgIcon {...svgProps}>
            <Play />
          </SvgIcon>
        )
      case 'Basket':
        return (
          <SvgIcon {...svgProps}>
            <Basket />
          </SvgIcon>
        )
      case 'Delete':
        return (
          <SvgIcon {...svgProps}>
            <Delete />
          </SvgIcon>
        )
      case 'Logo':
        return (
          <SvgIcon {...svgProps}>
            <Logo />
          </SvgIcon>
        )
      case 'PrintMaterial':
        return (
          <SvgIcon {...svgProps}>
            <PrintMaterial />
          </SvgIcon>
        )
      case 'Infographic':
        return (
          <SvgIcon {...svgProps}>
            <Infographic />
          </SvgIcon>
        )
      case 'Video':
        return (
          <SvgIcon {...svgProps}>
            <Video />
          </SvgIcon>
        )
      case 'RadioButtonActive':
        return (
          <SvgIcon {...svgProps}>
            <RadioButtonActive />
          </SvgIcon>
        )
      case 'RadioButtonInactive':
        return (
          <SvgIcon {...svgProps}>
            <RadioButtonInactive />
          </SvgIcon>
        )

      case 'AssignmentIndOutlined':
        return (
          <SvgIcon {...svgProps}>
            <AssignmentIndOutlinedIcon />
          </SvgIcon>
        )
      case 'Translation':
        return (
          <SvgIcon viewBox="0 0 17 14">
            <Translation />
          </SvgIcon>
        )
      case 'EasyLanguage':
        return (
          <SvgIcon viewBox="0 0 13 14">
            <EasyLanguage />
          </SvgIcon>
        )
      case 'SignLanguage':
        return (
          <SvgIcon viewBox="0 0 12 14">
            <SignLanguage />
          </SvgIcon>
        )
      default:
        return null
    }
  }
  return renderIcon()
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOf([
    'Search',
    'Play',
    'Album',
    'ToggleClose',
    'ToggleOpen',
    'ArrowDown',
    'ArrowUp',
    'ArrowLeft',
    'TextArrow',
    'TextExternalArrow',
    'Article',
    'BackTop',
    'Close',
    'Contribute',
    'Image',
    'Prev',
    'PrevBig',
    'Next',
    'NextBig',
    'Facebook',
    'Twitter',
    'Youtube',
    'Instagram',
    'Download',
    'Info',
    'Home',
    'AccordionOpen',
    'AccordionClose',
    'Pen',
    'Like',
    'LikeOutline',
    'Link',
    'LocationPin',
    'Question',
    'Checkmark',
    'Basket',
    'Delete',
    'Infographic',
    'Logo',
    'Video',
    'PrintMaterial',
    'RadioButtonActive',
    'RadioButtonInactive',
    'AssignmentIndOutlined',
    'Translation',
    'EasyLanguage',
    'SignLanguage',
  ]),
  size: PropTypes.oneOf(['inherit', 'small', 'default', 'large']),
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'action',
    'error',
    'disabled',
  ]),
  htmlColor: PropTypes.string,
}

Icon.defaultProps = {
  size: 'default',
  color: 'inherit',
}

export default Icon

import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import api from '@api'
import CreateContentForm from './createContent/form'

import { answerSchema } from './validationSchema'

function CreateAnswerForm({ articleId, onSuccessCallback }) {
  const intl = useIntl()

  const formInitialValues = {
    articleId: parseInt(articleId, 10),
    title: '',
  }

  const steps = {
    titles: [
      intl.formatMessage({
        id: 'dialogue.popup.form.createContent.answer.step',
      }),
      intl.formatMessage({ id: 'dialogue.popup.form.publish.answer.step' }),
    ],
    forms: ['answer', 'userData'],
  }

  return (
    <CreateContentForm
      steps={steps}
      validationSchema={answerSchema}
      initialValues={formInitialValues}
      onSubmit={api().createAnswer}
      onSuccessCallback={onSuccessCallback}
    />
  )
}

CreateAnswerForm.propTypes = {
  articleId: PropTypes.number,
  onSuccessCallback: PropTypes.func,
}

export default CreateAnswerForm

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'

const TrackingContext = React.createContext()

export function TrackingProvider({ children }) {
  const location = useLocation()

  useEffect(() => {
    customPageviewEvent(location.pathname)
  }, [location.pathname])

  function customPageviewEvent(path) {
    if (!window._mtm) return
    window._mtm.push({
      event: 'custom.pageview',
      path: path,
    })
  }

  function PushMessage(evt, message) {
    if (!window._mtm) return
    window._mtm.push({
      event: evt || 'custom.message',
      ...message,
    })
  }

  return (
    <TrackingContext.Provider value={{ PushMessage }}>
      {children}
    </TrackingContext.Provider>
  )
}

TrackingProvider.propTypes = {
  children: PropTypes.any,
}

export default TrackingContext

const axios = require('axios')

module.exports = async (opts = {}) => {
  const id = parseInt(opts.id)

  if (!id) {
    return new Promise(({ reject }) => {
      reject('ID not an integer')
    })
  }

  const posts = await axios.get(
    process.env.GATSBY_FORUM_SERVICE_URL + `/api/query/articles/${id}`
  )

  return posts
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'

const DialogContext = React.createContext()

export function DialogProvider({ children }) {
  const [PostData, setPostData] = useState({})
  const [StreamQuery, setStreamQuery] = useState(null)

  function updatePostData(data) {
    setPostData((PostData) => ({ ...PostData, ...data }))
  }

  function updateStreamQuery(query) {
    if (query !== null) {
      query.seek = null
      if (StreamQuery?.limit) {
        query.limit += StreamQuery.limit
      }
    }

    setStreamQuery(query)
  }

  return (
    <DialogContext.Provider
      value={{ PostData, updatePostData, StreamQuery, updateStreamQuery }}
    >
      {children}
    </DialogContext.Provider>
  )
}

DialogProvider.propTypes = {
  children: PropTypes.any,
}

export default DialogContext

import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'

import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'

import useStaticModule from '@hooks/useStaticModule'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
  content: {
    padding: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(15),
    },
    textAlign: 'center',
  },
  headline: {
    marginBottom: theme.spacing(5),
  },
  copy: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  linebreak: {
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  link: {
    ...theme.typography.link,
  },
}))

function Popup({ contentType, onAcceptance }) {
  const intl = useIntl()
  const classes = useStyles()

  const { contentfulModule } = useStaticQuery(graphql`
    query {
      contentfulModule(key: { eq: "hollydaymode" }) {
        id
        contentful_id
        key
        contentfulfields {
          id
          value {
            value
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          key

          media {
            description
            file {
              url
            }
          }
        }
      }
    }
  `)

  const { getStaticFieldValue, getStaticFieldMedia } = useStaticModule([
    contentfulModule,
  ])

  const content = {
    netiquette: {
      img: '/img/popups/netiquette.jpg',
      headline: 'dialogue.popup.netiquette.headline',
      copy: 'dialogue.popup.netiquette.copy',
      button: {
        label: 'dialogue.popup.netiquette.button',
        action: onAcceptance,
      },
    },
    confirmation: {
      img: '/img/popups/confirm.jpg',
      headline: 'dialogue.popup.confirmation.headline',
      copy: 'dialogue.popup.confirmation.copy',
      button: {
        label: 'dialogue.popup.confirmation.button',
        action: onAcceptance,
      },
    },
    editorapproval: {
      img: '/img/popups/confirm.jpg',
      headline: 'dialogue.popup.editorapproval.headline',
      copy: 'dialogue.popup.editorapproval.copy',
      button: {
        label: 'dialogue.popup.editorapproval.button',
        action: onAcceptance,
      },
    },
    blacklist: {
      img: '/img/popups/confirm.jpg',
      headline: 'dialogue.popup.blacklist.headline',
      copy: 'dialogue.popup.blacklist.copy',
      button: {
        label: 'dialogue.popup.blacklist.button',
        action: onAcceptance,
      },
    },
    newsletterSuccess: {
      copy: 'newsletter.subscribe.thank',
      button: {
        label: 'newsletter.subscribe.button',
        action: onAcceptance,
      },
    },
    newsletterError: {
      copy: 'newsletter.subscribe.error',
      button: {
        label: 'newsletter.subscribe.button',
        action: onAcceptance,
      },
    },
    contactSuccess: {
      copy: 'contact.thank',
      button: {
        label: 'contact.thank.button',
        action: onAcceptance,
      },
    },

    holidayMode: {
      img:
        getStaticFieldMedia('hollydaymode', 'hollydaymode.popup.image')?.file
          ?.url || null,
      headline: getStaticFieldValue(
        'hollydaymode',
        'hollydaymode.popup.headline'
      ),
      copy: getStaticFieldValue('hollydaymode', 'hollydaymode.popup.copy'),
      button: {
        label: getStaticFieldValue('hollydaymode', 'holiday.popup.button'),
        action: onAcceptance,
      },
    },

    holidayModeDialog: {
      img:
        getStaticFieldMedia('hollydaymode', 'holiday.dialog.popup.image')?.file
          ?.url || null,
      headline: getStaticFieldValue(
        'hollydaymode',
        'hollydaymode.popup.headline'
      ),
      copy: getStaticFieldValue('hollydaymode', 'holiday.dialog.popup.copy'),
      button: {
        label: getStaticFieldValue('hollydaymode', 'holiday.popup.button'),
        action: onAcceptance,
      },
    },
    contactError: {
      copy: 'contact.error',
      button: {
        label: 'contact.error.button',
        action: onAcceptance,
      },
    },
    orderConfirmation: {
      img: '/img/popups/confirm.jpg',
      headline: 'dialogue.popup.orderConfirmation.headline',
      copy: 'dialogue.popup.orderConfirmation.copy',
      button: {
        label: 'dialogue.popup.orderConfirmation.button',
        action: onAcceptance,
      },
    },
    surveyLoginError: {
      copy: 'survey.login.error',
      button: {
        label: 'survey.login.error.button',
        action: onAcceptance,
      },
    },
    surveySaveSuccess: {
      copy: 'survey.thank',
      button: {
        label: 'survey.thank.button',
        action: onAcceptance,
      },
    },
    surveySaveError: {
      copy: 'survey.error',
      button: {
        label: 'survey.error.button',
        action: onAcceptance,
      },
    },
  }

  return (
    <>
      {content[contentType]?.img && (
        <Img
          className={classes.img}
          fluid={{
            src: content[contentType].img,
            aspectRatio: 2.6417,
            srcSet: '',
            sizes: '',
            media: `(min-width: 0px)`,
          }}
          alt=""
          backgroundColor={true}
        />
      )}

      {content[contentType] && (
        <div className={classes.content}>
          {content[contentType].headline && (
            <Headline id="dialog-title" level={3} className={classes.headline}>
              {['holidayMode', 'holidayModeDialog'].includes(contentType) ? (
                content[contentType].headline
              ) : (
                <FormattedMessage id={content[contentType].headline} />
              )}
            </Headline>
          )}

          {content[contentType].copy &&
            (['holidayMode', 'holidayModeDialog'].includes(contentType) ? (
              <Copy
                className={classes.copy}
                html={content[contentType].copy}
              ></Copy>
            ) : (
              <Copy className={classes.copy}>
                <FormattedMessage
                  id={content[contentType].copy}
                  values={{
                    // eslint-disable-next-line react/display-name
                    a: (...chunks) => (
                      <a
                        data-track-content
                        data-tracking-id={intl.formatMessage({
                          id: 'netiquette.path',
                        })}
                        className={classes.link}
                        rel="noreferrer"
                        target="_blank"
                        href={intl.formatMessage({ id: 'netiquette.path' })}
                      >
                        {chunks}
                      </a>
                    ),
                    break: <span className={classes.linebreak} />,
                  }}
                />
              </Copy>
            ))}

          <Button onClick={content[contentType].button.action}>
            <FormattedMessage id={content[contentType].button.label} />
          </Button>
        </div>
      )}
    </>
  )
}

Popup.propTypes = {
  children: PropTypes.any,
  contentType: PropTypes.oneOf([
    'netiquette',
    'confirmation',
    'editorapproval',
    'blacklist',
    'holidayMode',
    'holidayModeDialog',
    'newsletterSuccess',
    'newsletterError',
    'contactSuccess',
    'contactError',
    'orderConfirmation',
    'surveyLoginError',
  ]),
  onAcceptance: PropTypes.func,
}

export default Popup

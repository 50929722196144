import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
const useStyles = makeStyles((theme) => ({
  containerRoot: {
    ...theme.mixins.containerSpacing,
    backgroundColor: (props) => props.background,
  },
  contentRoot: {
    maxWidth: (props) => theme.container[props.width || 'lg'],
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: (props) => theme.spacing(4 + (props.sidebar ? 92 : 0)),
    },
  },
  padded: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(20),
    marginTop: 0,
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(25),
    },
  },
  article: {
    ...theme.mixins.article,
    marginBottom: 0,
  },
  breadcrumb: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  nomargin: {
    marginBottom: 0,
  },
  top: {
    marginTop: theme.spacing(10),
  },
}))

function Inner({ children, padded, background, classes }) {
  if (background || padded) {
    return (
      <div
        className={clsx(classes.contentRoot, {
          [classes.padded]: padded,
        })}
      >
        {children}
      </div>
    )
  } else {
    return <>{children}</>
  }
}

function Container(props) {
  const classes = useStyles(props)
  const intl = useIntl()
  const {
    children,
    className,
    padded,
    type,
    width,
    background,
    sidebar,
    ariaLabel,
    ...other
  } = props

  return (
    <div
      className={clsx(className, classes.containerRoot, {
        [classes.contentRoot]: !padded && !background,
        [classes[type]]: type,
      })}
      {...other}
      role="region"
      aria-label={ariaLabel ? intl.formatMessage({ id: ariaLabel }) : undefined}
    >
      <Inner padded={padded} background={background} classes={classes}>
        {children}
      </Inner>
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  padded: PropTypes.bool,
  type: PropTypes.oneOf(['article', 'breadcrumb', 'nomargin', 'top']),
  width: PropTypes.string,
  background: PropTypes.string,
  sidebar: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

export default Container

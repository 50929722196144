import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    cursor: 'pointer',
    display: 'inline-block',
    border: 0,
    outline: 0,
    background: 'none',
    padding: 0,
    font: 'inherit',
    color: 'inherit',
    '&:focus': {
      backgroundImage:
        'radial-gradient(ellipse at center, #fff 0%,#fff 69%,transparent 69%,transparent 100%)',
      '& > svg': {
        color: theme.palette.text.primary,
      },
    },
  },
  invert: {
    '&:focus': {
      backgroundImage:
        'radial-gradient(ellipse at center, #000 0%,#000 69%,transparent 69%,transparent 100%)',
      '& > svg': {
        color: theme.palette.text.invert,
      },
    },
  },
}))

function SocialIcon({ className, item, invert, ...iconProps }) {
  const classes = useStyles()

  return item.href ? (
    <a
      data-track-content
      data-tracking-id={item.name}
      href={item.href}
      key={item.name}
      rel="noopener noreferrer"
      target="_blank"
      className={clsx(className, classes.socialIcon, {
        [classes.invert]: invert,
      })}
      data-testid="social-icon"
      aria-label={item.name}
    >
      <Icon {...iconProps} />
    </a>
  ) : item.onClick ? (
    <button
      data-track-content
      data-tracking-id={item.name}
      key={item.name}
      onClick={item.onClick}
      className={clsx(className, classes.socialIcon, {
        [classes.invert]: invert,
      })}
      data-testid="social-icon-click"
      aria-label={item.name}
    >
      <Icon {...iconProps} />
    </button>
  ) : (
    <></>
  )
}

SocialIcon.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  invert: PropTypes.bool,
}

export default SocialIcon

import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import RichTextEditor from '@objects/formFields/richtext'

import TextField from '@objects/formFields/textField'
import Headline from '@objects/headline'

const useStyles = makeStyles((theme) => ({
  headline: {
    maxWidth: '465px',
    margin: theme.spacing(10, 'auto', 5, 'auto'),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
    },
  },
  title: {
    marginBottom: theme.spacing(8),
  },
}))

function ContentForm({ type }) {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <>
      <Headline className={classes.headline} level={3}>
        {intl.formatMessage({
          id: `dialogue.popup.form.createContent.${type}.headline`,
        })}
      </Headline>
      {type !== 'comment' && (
        <div className={classes.title}>
          <TextField
            id="title-input"
            fullWidth
            variant="outlined"
            name="title"
            label={intl.formatMessage({
              id: `dialogue.popup.form.field.title.${type}.label`,
            })}
            placeholder={intl.formatMessage({
              id: `dialogue.popup.form.field.title.${type}.placeholder`,
            })}
            infoText={intl.formatMessage({
              id: `dialogue.popup.form.field.title.${type}.info`,
            })}
          />
        </div>
      )}
      <RichTextEditor
        id="text-input"
        name="text"
        label={intl.formatMessage({
          id: `dialogue.popup.form.field.text.${type}.label`,
        })}
        showInfoText
      />
    </>
  )
}

ContentForm.propTypes = {
  type: PropTypes.oneOf(['article', 'answer', 'comment']),
}

export default ContentForm

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Copy from '@objects/copy'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  typeOrange: {},
  label: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.hint,
    marginBottom: theme.spacing(3),
  },
  isOpen: {},
  selectRoot: {
    overflow: 'hidden',
    backgroundColor: (props) =>
      props.type === 'orange'
        ? theme.palette.yellow.main
        : theme.palette.default.main,
    borderRadius: (props) =>
      props.type === 'orange' ? '0 20px 20px 0' : '20px',
    height: (props) => props.selectHeight,
    width: (props) => props.selectWidth,
    maxWidth: '300px',
    minWidth: '132px',

    '& .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.palette.red.main,
      },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.red.light,
    },
    '$isOpen & .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },

    '$typeOrange & .MuiOutlinedInput-notchedOutline, $typeOrange &.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderWidth: '0',
      },
    '$typeOrange &:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0',
    },
    '$typeOrange.$isOpen & .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0',
    },
  },

  selectField: {
    fontSize: '18px',
    color: (props) =>
      props.type === 'orange'
        ? theme.palette.text.primary
        : theme.palette.red.main,
    lineHeight: 'normal',
    minWidth: 'calc(100% - 62px)',
    background: (props) =>
      props.type === 'orange'
        ? theme.palette.yellow.main
        : theme.palette.background.default,
    borderRadius: (props) =>
      props.type === 'orange' ? '0 20px 20px 0' : '20px',
    paddingLeft: '20px',
    paddingRight: '42px',
    paddingTop: '0',
    paddingBottom: '0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '&:focus': {
      background: (props) =>
        props.type === 'orange'
          ? theme.palette.yellow.main
          : theme.palette.background.default,
    },
  },
  outlined: {
    '&.MuiSelect-outlined': {
      paddingRight: '42px',
    },
  },
  icon: {
    color: (props) =>
      props.type === 'orange'
        ? theme.palette.text.primary
        : theme.palette.red.main,
    right: '20px',
    top: 'calc(50% - 6px)',
    fontSize: '12px',
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  paper: {
    marginTop: '0',
    borderRadius: (props) =>
      props.type === 'orange' ? '0 20px 20px 0' : '20px',
    borderStyle: 'solid',
    borderColor: theme.palette.red.main,
    borderWidth: (props) => (props.type === 'orange' ? '0' : '2px'),
    maxWidth: '300px',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: theme.palette.background.default,
    '& li': {
      paddingTop: '12px',
      paddingBottom: '12px',
      alignItems: 'flex-start',
      fontWeight: (props) => (props.type === 'orange' ? '500' : 'normal'),
    },
    '& li:hover, & li.Mui-selected:hover': {
      background: theme.palette.grey.light,
    },
    '& li.Mui-selected': {
      background: theme.palette.background.default,
      fontWeight: (props) => (props.type === 'orange' ? 'bold' : 'normal'),
      color: (props) =>
        props.type === 'orange'
          ? theme.palette.red.main
          : theme.palette.text.primary,
    },
  },
  optionIcon: {
    fontSize: '23px',
    marginRight: '12px',
  },
  optionIconActive: {
    'li:not(.Mui-selected) &, $selectField &': {
      display: 'none',
    },
  },
  optionIconInactive: {
    'li.Mui-selected &, $selectField &': {
      display: 'none',
    },
  },
  optionText: {
    display: 'none',
    '$paper &': {
      whiteSpace: 'normal',
      display: 'block',
    },
    fontSize: '18px',
    lineHeight: 'normal',
  },
  optionTextSelected: {
    display: 'none',
    '$selectField &': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    lineHeight: 'normal',
  },
  popoverRoot: {
    backgroundColor: (props) =>
      props.type === 'orange' ? '#4b4b4b7f' : 'transparent',
  },
}))

function CustomSelect({
  className,
  customRef,
  label,
  options,
  onOptionSelected,
  defaultValue,
  selectWidth,
  selectHeight,
  popoverVerticalOrigin,
  type,
}) {
  const [open, setOpen] = useState(false)
  const classes = useStyles({ selectWidth, selectHeight, type })
  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: type === 'orange' ? 'center' : 'left',
    },
    transformOrigin: {
      vertical: popoverVerticalOrigin,
      horizontal: type === 'orange' ? 'center' : 'left',
    },
    getContentAnchorEl: null,
    marginThreshold: type === 'orange' ? 0 : 16,
    PopoverClasses: {
      root: classes.popoverRoot,
    },
  }

  const inputProps = {
    classes: {
      notchedOutline: classes.notchedOutline,
    },
  }

  function iconComponent(props) {
    return (
      <Icon
        className={clsx(props.className, classes.icon)}
        name={'ToggleClose'}
      />
    )
  }

  function renderItems() {
    return options.map((option, index) => {
      return (
        <MenuItem key={`selectOption-${index}`} value={option.value}>
          {type !== 'orange' && (
            <Icon
              className={clsx(classes.optionIconActive, classes.optionIcon)}
              name={'RadioButtonActive'}
            />
          )}
          {type !== 'orange' && (
            <Icon
              className={clsx(classes.optionIconInactive, classes.optionIcon)}
              name={'RadioButtonInactive'}
            />
          )}
          <Typography className={classes.optionText}>{option.text}</Typography>
          <Typography className={classes.optionTextSelected}>
            {option.selectedText ?? option.text}
          </Typography>
        </MenuItem>
      )
    })
  }

  function handleChange(event) {
    onOptionSelected(event.target.value)
  }

  return (
    <div
      ref={customRef}
      className={clsx(className, {
        [classes.isOpen]: open,
        [classes.typeOrange]: type === 'orange',
      })}
      data-testid="customSelect"
    >
      {label && <Copy className={classes.label}>{label}</Copy>}
      <Select
        className={classes.selectRoot}
        IconComponent={iconComponent}
        classes={{
          root: classes.selectField,
          outlined: classes.outlined,
        }}
        MenuProps={menuProps}
        inputProps={inputProps}
        value={defaultValue}
        variant={'outlined'}
        onChange={handleChange}
        onOpen={(event) => {
          setOpen(true)
        }}
        onClose={(event) => {
          setOpen(false)
        }}
      >
        {renderItems()}
      </Select>
    </div>
  )
}

CustomSelect.propTypes = {
  className: PropTypes.string,
  customRef: PropTypes.object,
  label: PropTypes.string,
  onOptionSelected: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectWidth: PropTypes.string,
  selectHeight: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
      selectedText: PropTypes.string,
    })
  ),
  popoverVerticalOrigin: PropTypes.number,
  type: PropTypes.oneOf(['default', 'orange']),
}

CustomSelect.defaultProps = {
  selectWidth: 200,
  selectHeight: '37px',
  popoverVerticalOrigin: -37,
  type: 'default',
}

export default CustomSelect

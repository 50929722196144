module.exports = {
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 2000,
    desktop: 1535,
  },
}

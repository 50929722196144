import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { navigate } from 'gatsby'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import Button from '@objects/button'

function CreateContentButton({
  className,
  children,
  contentType,
  articleId,
  answerId,
  onClick,
  onSuccessCallback,
  ...props
}) {
  const intl = useIntl()
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const [netiquetteAccepted, setNetiquetteAccepted] = useState(false)

  useEffect(() => {
    const accepted = localStorage.getItem('netiquetteAccepted')
    if (accepted) setNetiquetteAccepted(true)
  }, [])

  function openPopup() {
    if (onClick) onClick()
    if (netiquetteAccepted) {
      openForm()
    } else {
      toggleOverlay(true, 'netiquette', 'default', {
        trackingID: 'netiquette',
        width: 'sm',
        closeButton: 'invert',
        onAcceptance: () => {
          localStorage.setItem('netiquetteAccepted', true)
          openForm()
        },
      })
    }
  }

  function openForm() {
    toggleOverlay(true, contentType, 'default', {
      trackingID: 'dialog',
      articleId: articleId || null,
      answerId: answerId || null,
      onSuccessCallback:
        contentType === 'createArticle'
          ? articleSuccessCallback
          : onSuccessCallback,
    })
  }

  function articleSuccessCallback(data) {
    navigate(
      intl.formatMessage(
        { id: 'forum.path.single' },
        { postId: data.articleId }
      )
    )
  }

  return (
    <Button className={className} onClick={openPopup} {...props}>
      {children}
    </Button>
  )
}

CreateContentButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  contentType: PropTypes.oneOf([
    'createArticle',
    'createAnswer',
    'createComment',
  ]),
  articleId: PropTypes.number,
  answerId: PropTypes.number,
  onClick: PropTypes.func,
  onSuccessCallback: PropTypes.func,
}

CreateContentButton.defaultProps = {
  contentType: 'createArticle',
  onSuccessCallback: (response) => {
    console.info('form response - noop')
  },
}

export default CreateContentButton

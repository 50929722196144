import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Trunc from 'truncate-html'
import parse, { domToReact } from 'html-react-parser'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { GlossaryScopeProvider } from '@providers/glossaryScopeProvider'
import { useTextParser, useGlossaryScope } from '@hooks'
import UnorderedList from '@objects/unorderedlist'
import OrderedList from '@objects/orderedlist'
import Button from '@objects/button'
import ReactMarkdown from 'react-markdown/with-html'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    '&$intro': {
      marginBottom: theme.spacing(15),
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(25),
      },
    },
    '& p': {
      marginTop: 0,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  intro: {
    ...theme.typography.intro,
  },
  article: {
    ...theme.mixins.article,
  },
  post: {
    ...theme.mixins.post,
  },
  faq: {
    ...theme.mixins.faq,
    ...theme.typography.teaser,
  },
}))

function Copy({
  className,
  children,
  html,
  markdown,
  component,
  type,
  truncate,
  truncateextend,
  parseGlossary,
  glossaryExcludes,
  glossaryType,
}) {
  const classes = useStyles()
  const { parseText, glossaryInitialized } = useTextParser()
  const { foundEntries, updateFoundEntries } = useGlossaryScope()
  const classnames = clsx(className, classes.root, {
    [classes[type]]: type,
  })

  const [parsedChildren, setParsedChildren] = useState(children)

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries || []
          )
          updateFoundEntries && updateFoundEntries(parsed.foundEntries)
          return parsed.text
        })
      )
    }
  }, [glossaryInitialized])

  function renderUserCentricsOrNot(parsedChildren) {
    if (
      React.Children.toArray(parsedChildren).includes('{{usercentric-data}}')
    ) {
      return <div className="uc-embed" uc-data="all"></div>
    } else {
      return TruncateOrNot(parsedChildren)
    }
  }

  function TruncateOrNot(text) {
    if (truncate) {
      return Trunc(text, truncate, { byWords: true })
    } else {
      return text
    }
  }

  function markdownLinkRendered({ href, children }) {
    return (
      <Button type="text" link={href}>
        {children}
      </Button>
    )
  }

  function renderCopy() {
    if (html) {
      const htmlWithBr = html
        .replace(/\n\[/g, '[')
        .replace(/\]\n/g, ']')
        .replace(/(?:\r\n|\r|\n)/g, '<br>')
      let containsBlockElements = false
      const renderedHTML = parse(TruncateOrNot(htmlWithBr), {
        replace: (domNode) => {
          if (domNode.type === 'tag') {
            if (domNode.name === 'a') {
              // replace class attribute with className
              const attribs = {
                ...domNode.attribs,
                href: null,
                link: domNode.attribs.href,
                className: domNode.attribs.className ?? domNode.attribs.class,
                class: null,
                target: null,
              }
              delete attribs.class
              delete attribs.target
              if (domNode.attribs.href === '#usercentrics-revoke') {
                return (
                  <Button
                    type="secondary"
                    onClick={() => window.UC_UI.showSecondLayer()}
                  >
                    {domToReact(domNode.children)}
                  </Button>
                )
              }
              return (
                <Button type="text" {...attribs}>
                  {domToReact(domNode.children)}
                </Button>
              )
            } else if (domNode.name === 'ul') {
              containsBlockElements = true
              return (
                <UnorderedList
                  parseGlossary={parseGlossary}
                  glossaryExcludes={glossaryExcludes}
                  glossaryType={glossaryType}
                  {...domNode.attribs}
                >
                  {domToReact(
                    domNode.children.filter((child) => child.name !== 'br')
                  ).map((child) => child?.props?.children)}
                </UnorderedList>
              )
            } else if (domNode.name === 'ol') {
              containsBlockElements = true
              return (
                <OrderedList
                  {...domNode.attribs}
                  parseGlossary={parseGlossary}
                  glossaryExcludes={glossaryExcludes}
                  glossaryType={glossaryType}
                  style={type === 'post' ? 'simple' : 'default'}
                >
                  {domToReact(
                    domNode.children.filter((child) => {
                      return child.name !== 'br'
                    })
                  ).map((child) => {
                    return child?.props?.children && child.props.children
                  })}
                </OrderedList>
              )
            } else if (domNode.name === 'br') {
              if (
                domNode.next?.type !== 'text' ||
                domNode.prev?.type !== 'text'
              ) {
                return <Fragment />
              }
            } else if (
              ['p', 'h1', 'h2', 'h3', 'h4', 'h5'].includes(domNode.name)
            ) {
              containsBlockElements = true
            }
          } else if (domNode.type === 'text' && parseGlossary) {
            const parsed = parseText(
              domNode.data,
              glossaryExcludes,
              glossaryType,
              foundEntries
            )
            updateFoundEntries && updateFoundEntries(parsed.foundEntries)
            return <Fragment>{parsed.text}</Fragment>
          }
        },
      })
      if (component === 'div' && !containsBlockElements) {
        return (
          <p>
            {renderedHTML} {truncateextend}
          </p>
        )
      } else {
        return (
          <>
            {renderedHTML} {truncateextend}
          </>
        )
      }
    } else if (markdown) {
      return (
        <ReactMarkdown
          renderers={{
            link: markdownLinkRendered,
          }}
        >
          {markdown}
        </ReactMarkdown>
      )
    } else {
      return renderUserCentricsOrNot(parsedChildren)
    }
  }

  return (
    <Typography component={component} className={classnames}>
      <GlossaryScopeProvider>{renderCopy()}</GlossaryScopeProvider>
    </Typography>
  )
}

Copy.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  html: PropTypes.string,
  markdown: PropTypes.string,
  component: PropTypes.oneOf(['div', 'span', 'p']),
  type: PropTypes.oneOf(['article', 'intro', 'post', 'faq']),
  truncate: PropTypes.number,
  truncateextend: PropTypes.any,
  parseGlossary: PropTypes.bool,
  glossaryExcludes: PropTypes.arrayOf(PropTypes.string),
  glossaryType: PropTypes.oneOf(['overlay', 'inline']),
}

Copy.defaultProps = {
  component: 'p',
  glossaryType: 'overlay',
}

export default Copy

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Cookies from 'js-cookie'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@objects/button'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 200000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '43px',
    backgroundColor: '#ffaa32',
  },
  bannerWrapperIsClosed: {
    display: 'none',
  },
  isLarge: {
    fontSize: 'x-large',
  },
  copyWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginRight: '20px',
      padding: '12px',
    },
  },
  copy: {
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: '8px',
    },
  },
  link: {
    color: 'black',
    '& > svg': {
      fontSize: '14px',
      marginLeft: '5px',
      verticalAlign: '-1px',
    },
  },
  close: {
    color: 'black',
    position: 'absolute',
    right: '17px',
    '& > svg': {
      fontSize: '18px',
    },
  },
}))

function BannerNewsletter({ isLargeCopy }) {
  const classes = useStyles()
  const intl = useIntl()
  const [bannerIsClosed, setBannerIsClosed] = useState(false)

  const setCookie = () => {
    Cookies.set('closeBanner', 'true')
    setBannerIsClosed(Cookies.get('closeBanner') === 'true')
  }

  const scroll = () => {
    const section = document.querySelector('#newsletter-teaser')
    section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  function handleClick() {
    setCookie()
    scroll()
  }

  function handleClose() {
    setCookie()
  }

  useEffect(() => {
    Cookies.get('closeBanner')
    setBannerIsClosed(Cookies.get('closeBanner') === 'true')
  }, [])

  return (
    <div
      className={clsx(
        classes.bannerWrapper,
        isLargeCopy === true ? classes.isLarge : '',
        classes.bannerWrapper,
        bannerIsClosed === true ? classes.bannerWrapperIsClosed : ''
      )}
      role='region'
      aria-label={intl.formatMessage({ id: 'arialabel.newsletterbanner' })}
    >
      <div className={classes.copyWrapper}>
        <div className={classes.copy} role="paragraph">
          <FormattedMessage id="newsletter.banner" />
        </div>

        <Button
          data-track-content
          className={classes.link}
          onClick={() => handleClick()}
          type="text"
          icon="ArrowDown"
        >
          <FormattedMessage id="register" />
        </Button>
      </div>

      <Button
        data-track-content
        data-testid="register"
        className={classes.close}
        onClick={() => handleClose()}
        type="text"
        icon="Close"
        aria-label={intl.formatMessage({ id: 'button.close' })}
      ></Button>
    </div>
  )
}

BannerNewsletter.propTypes = {
  className: PropTypes.string,
  copy: PropTypes.array,
  markdown: PropTypes.string,
  isLargeCopy: PropTypes.bool,
}

export default BannerNewsletter

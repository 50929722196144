module.exports = function (theme) {
  const a = {
    cursor: 'pointer',
    color: theme.palette.red.main,
    fontWeight: 'bold',
    transition: 'color 0.2s ease-in-out',
    '&:focus': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
    '&:hover': {
      color: theme.palette.red.light,
      textDecoration: 'none',
    },
  }

  return {
    article: {
      '& > ul, & > ol, & > p': {
        marginTop: 0,
        marginBottom: theme.spacing(8),
        [theme.breakpoints.up('lg')]: {
          marginBottom: theme.spacing(10),
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
      '& > h2, & > h3, & > h4': {
        margin: theme.spacing(15, 0, 6, 0),
        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(20, 0, 8, 0),
        },
      },
      '& a': {
        ...a,
      },
    },
    post: {
      '& > ul, & > ol, & > p': {
        marginTop: 0,
        marginBottom: theme.spacing(3),
        '&:last-child': {
          marginBottom: 0,
        },
        wordWrap: 'break-word',
      },
      '& a': {
        ...a,
      },
    },
    faq: {
      '& > ul, & > ol, & > p': {
        marginTop: 0,
        marginBottom: theme.spacing(3),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    overlayBase: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      content: '""',
    },
    linkStyle: {
      ...a,
    },
    containerSpacing: {
      marginBottom: theme.spacing(20),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(25),
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(30),
      },
    },
  }
}

import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import api from '@api'

import { makeStyles } from '@material-ui/core/styles'

import { Formik } from 'formik'
import clsx from 'clsx'

import { useTracking, useToggleComp } from '@hooks'
import useBasket from '@hooks/useBasket'

import Stepper from '@objects/stepper'
import Button from '@objects/button'
import Copy from '@objects/copy'

import { orderSchema } from './validationSchema'
import OrderForm from './orders/order'
import OrderAddressForm from './orders/orderAddress'

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    padding: theme.spacing(10, 5, 5, 5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20),
    },
    position: 'relative',
  },
  contents: {
    marginTop: '60px',
  },

  submittingLayer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    content: '""',
    position: 'absolute',
    zIndex: 2,
  },
  hideLayer: {
    display: 'none',
  },

  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(8),
  },
  actionsMarginTopL: {
    marginTop: '77px',
  },

  orderOverviewInfo: {
    fontSize: '13px',
    lineHeight: '16px',
    marginBottom: '18px',
    width: '100%',
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  findMore: {
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      justifyContent: 'space-between',
      marginBottom: 0,
    },
  },
  back: {
    width: '45%',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      width: 'auto',
      justifyContent: 'space-between',
    },
  },
  next: {
    marginLeft: 'auto',
    width: '45%',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      width: 'auto',
      justifyContent: 'space-between',
    },
  },
  orderBtn: {
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      width: 'auto',
      justifyContent: 'space-between',
      marginTop: 0,
    },
  },
}))

function BasketForm() {
  const intl = useIntl()
  const { toggleOverlay } = useToggleComp()
  const { pushMessage } = useTracking()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const { basket, clearBasket, removeItem, addItem } = useBasket()
  const formInitialValues = {
    firstName: '',
    lastName: '',
    company: '',
    department: '',
    streetAndNumber: '',
    postalCodeAndCity: '',
    phoneNumber: '',
    orderEmail: '',
    orderMessage: '',
    askUserToReceiveEmails: false,
    termsOfPrivacy: false,
    friendlyCaptchaCode: '',
  }
  const [address, setAddress] = useState(formInitialValues)

  const steps = {
    titles: [
      intl.formatMessage({
        id: 'dialogue.popup.form.basket.order.step',
      }),
      intl.formatMessage({
        id: 'dialogue.popup.form.basket.orderAddress.step',
      }),
      intl.formatMessage({
        id: 'dialogue.popup.form.basket.orderOverview.step',
      }),
    ],
  }
  const isLastStep = activeStep === steps.titles.length - 1

  function renderActiveFormStep() {
    switch (activeStep) {
      case 0:
        return (
          <OrderForm
            items={basket}
            handleRemoveItem={handleRemoveItem}
            handleUpdateItemAmount={handleUpdateItemAmount}
          />
        )
      case 1:
        return <OrderAddressForm />
      case 2:
        return (
          <OrderForm
            items={basket}
            address={address}
            handleItemChange={handleItemChange}
            handleAddressChange={() => {
              setActiveStep(1)
            }}
            handleRemoveItem={handleRemoveItem}
            handleUpdateItemAmount={handleUpdateItemAmount}
            handleEmptyBasket={() => {
              toggleOverlay(false)
            }}
            isOverview
          />
        )
      default:
        return null
    }
  }

  function handleRemoveItem(item) {
    removeItem(item.id)
  }

  function handleUpdateItemAmount(item, amount) {
    addItem({ ...item, amount })
  }

  function handleItemChange(index) {
    toggleOverlay(true, 'displayPrintMaterial', 'default', {
      trackingID: 'dialog',
      printMaterial: basket[index],
      width: 'xl',
    })
  }

  function submitForm(values, actions) {
    const apiValues = {
      firstname: values.firstName,
      lastname: values.lastName,
      company: values.company,
      department: values.department,
      streetAndNumberOrPoBox: values.streetAndNumber,
      postcodeAndCity: values.postalCodeAndCity,
      phone: values.phoneNumber,
      email: values.orderEmail,
      message: values.orderMessage,
      FriendlyCaptchaCode: values.friendlyCaptchaCode,
      products: basket.map((item) => {
        return {
          title: item.title,
          variant: item.variants[item.variant].variantName,
          amount: item.amount,
        }
      }),
    }

    api()
      .orderPrintMaterial(apiValues)
      .then((response) => {
        if (response.status === 200) {
          basket.forEach((item) => {
            pushMessage('custom.order', {
              orderBasketTitle: item.title,
              orderBasketAmount: item.amount,
            })
          })
          clearBasket()
          // ToDo: Change for the actual msg if necessary
          response.data.holidayModusEnabled === false
            ? toggleOverlay(true, 'orderConfirmation', 'default', {
                trackingID: 'confirmation',
                width: 'sm',
                closeButton: 'hide',
                onAcceptance: () => toggleOverlay(false),
              })
            : toggleOverlay(true, 'holidayMode', 'default', {
                trackingID: 'holidayMode',
                width: 'sm',
                onAcceptance: () => toggleOverlay(false),
              })
        } else {
          actions.setSubmitting(false)
        }
      })
      .catch((err) => {
        actions.setSubmitting(false)
      })
  }

  function handleSubmit(values, actions) {
    if (isLastStep) {
      submitForm(values, actions)
    } else {
      actions.setTouched({})
      setAddress(values)
      setActiveStep(activeStep + 1)
      actions.setSubmitting(false)
    }
  }

  function getValidationSchema() {
    if (isLastStep) {
      return orderSchema.orderOverview
    }
    return orderSchema.orderAddress
  }

  function handleBack() {
    if (activeStep === 0) {
      toggleOverlay(false)
      return
    }
    setActiveStep(activeStep - 1)
  }

  return (
    <div data-testid="basket" className={classes.mainRoot}>
      <Stepper steps={steps.titles} activeStep={activeStep} />

      <Formik
        key={'orderFormkit'}
        initialValues={formInitialValues}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting, submitForm, ...otherProps }) => {
          return (
            <>
              <div
                className={clsx(classes.submittingLayer, {
                  [classes.hideLayer]: !isSubmitting,
                })}
              ></div>
              <div className={classes.contents}>{renderActiveFormStep()}</div>
              <div
                className={clsx(classes.actions, {
                  [classes.actionsMarginTopL]: activeStep === 1,
                })}
              >
                {isLastStep && (
                  <Copy className={classes.orderOverviewInfo}>
                    <FormattedMessage id="dialogue.popup.form.basket.orderOverview.info" />
                  </Copy>
                )}
                <Button
                  className={clsx({
                    [classes.findMore]: activeStep === 0,
                    [classes.back]: activeStep !== 0,
                  })}
                  color="outline"
                  disabled={isSubmitting || (!!!basket.length && isLastStep)}
                  onClick={handleBack}
                  to={
                    activeStep === 0
                      ? intl.formatMessage({ id: 'media.path.printMaterial' })
                      : null
                  }
                >
                  <FormattedMessage
                    id={
                      activeStep === 0
                        ? 'dialogue.popup.form.basket.button.findMore'
                        : 'dialogue.popup.form.prev'
                    }
                  />
                </Button>
                <Button
                  key={'orderButtonNext'}
                  className={clsx({
                    [classes.orderBtn]: isLastStep,
                    [classes.next]: !isLastStep,
                  })}
                  disabled={isSubmitting || !!!basket.length}
                  onClick={() => {
                    if (basket.length) {
                      if (activeStep === 0) {
                        setActiveStep(activeStep + 1)
                      } else {
                        submitForm()
                      }
                    }
                  }}
                >
                  <FormattedMessage
                    id={
                      isLastStep
                        ? 'dialogue.popup.form.basket.button.lastStep'
                        : 'dialogue.popup.form.next'
                    }
                  />
                </Button>
              </div>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

BasketForm.propTypes = {}

export default BasketForm

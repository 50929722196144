import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import { useTextParser, useGlossaryScope } from '@hooks'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(7),
    margin: 0,
  },
  item: {
    paddingLeft: theme.spacing(1),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}))

function UnorderedList({
  children,
  parseGlossary,
  glossaryExcludes,
  glossaryType,
}) {
  const classes = useStyles()
  const { parseText, glossaryInitialized } = useTextParser()
  const { foundEntries, updateFoundEntries } = useGlossaryScope()
  const [parsedChildren, setParsedChildren] = useState(children)

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries || []
          )
          updateFoundEntries && updateFoundEntries(parsed.foundEntries)
          return parsed.text
        })
      )
    }
  }, [glossaryInitialized])

  useEffect(() => {
    if (children && parseGlossary && glossaryInitialized) {
      setParsedChildren(
        children.map((item) => {
          const parsed = parseText(
            item,
            glossaryExcludes,
            glossaryType,
            foundEntries
          )
          return parsed.text
        })
      )
    }
  }, [foundEntries])

  return (
    <ul className={classes.root}>
      {parsedChildren.map((item, i) => {
        if (item) {
          return (
            <Copy
              component="li"
              className={classes.item}
              key={`${item.created}${i}`}
            >
              {item}
            </Copy>
          )
        } else {
          return <Fragment key={i} />
        }
      })}
    </ul>
  )
}

UnorderedList.propTypes = {
  children: PropTypes.any.isRequired,
  parseGlossary: PropTypes.bool,
  glossaryExcludes: PropTypes.arrayOf(PropTypes.string),
  glossaryType: PropTypes.oneOf(['overlay', 'inline']),
}

UnorderedList.defaultProps = {
  glossaryType: 'overlay',
  glossaryExcludes: [],
}

export default UnorderedList

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  glossaryLink: {
    display: 'inline-block',
    padding: 0,
    border: 0,
    outline: 0,
    background: 'none',
    font: 'inherit',
    cursor: 'pointer',
    fontWeight: 700,

    '&:focus': {
      textDecoration: 'underline',
      '& $icon': {
        color: theme.palette.text.primary,
      },
    },
  },

  tooltipWord: {
    display: 'inline-block',
    padding: 0,
    borderBottom: `1px dotted ${theme.palette.text.primary}`,
    outline: 0,
    background: 'none',
    font: 'inherit',
    cursor: 'pointer',
    fontWeight: 700,
  },
  icon: {
    verticalAlign: 0,
    marginRight: theme.spacing(-0.5),
    marginLeft: theme.spacing(0.5),
    color: theme.palette.red.main,
    fontSize: '.7em',
  },
}))

function GlossaryLink({
  id,
  children,
  className,
  description,
  word,
  title,
  tooltipMessage,
  tooltipOnly,
  synonyms,
  type,
  ...props
}) {
  const classes = useStyles()
  const intl = useIntl()
  const { toggleOverlay } = useContext(ToggleComponentContext)

  function openDescription() {
    toggleOverlay(true, 'glossary', 'default', {
      trackingID: 'glossary',
      width: 'md',
      word: title,
      description: description[0].content[0].value,
      synonyms: synonyms,
      link: `${intl.formatMessage({
        id: 'glossary.path',
      })}?#glossaryitem=${word}`,
    })
  }
  if (!!tooltipOnly && tooltipMessage !== null) {
    return (
      <Tooltip title={tooltipMessage}>
        <span
          className={clsx([
            className,
            classes.tooltipWord,
            { [classes.margin]: id !== 0 },
          ])}
        >
          {children}
        </span>
      </Tooltip>
    )
  }
  return (
    <Tooltip
      title={
        tooltipMessage !== null
          ? tooltipMessage
          : intl.formatMessage({ id: 'glossary.tooltip' })
      }
    >
      {type === 'inline' ? (
        <Link
          data-track-content
          data-tracking-id={`${intl.formatMessage({
            id: 'glossary.path',
          })}?#glossaryitem=${word}`}
          className={clsx([
            className,
            classes.glossaryLink,
            { [classes.margin]: id !== 0 },
          ])}
          to={`${intl.formatMessage({
            id: 'glossary.path',
          })}?#glossaryitem=${word}`}
          {...props}
        >
          <Icon className={classes.icon} name="Next" />
          {children}
        </Link>
      ) : (
        <button
          data-track-content
          data-tracking-id={`${intl.formatMessage({
            id: 'glossary.path',
          })}?#glossaryitem=${word}`}
          className={clsx([
            className,
            classes.glossaryLink,
            { [classes.margin]: id !== 0 },
          ])}
          onClick={openDescription}
          {...props}
        >
          <Icon className={classes.icon} name="Next" />
          {children}
        </button>
      )}
    </Tooltip>
  )
}

GlossaryLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.object),
  word: PropTypes.string,
  tooltipMessage: PropTypes.string,
  tooltipOnly: PropTypes.bool,
  title: PropTypes.string,
  synonyms: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['overlay', 'inline']),
}

GlossaryLink.defaultProps = {
  type: 'overlay',
}

export default GlossaryLink

const de = require('./de.json')
const en = require('./en.json')

const messages = { de, en }
const defaultLang = 'de'

module.exports = {
  messages,
  defaultLang,
}

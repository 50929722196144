import React from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import CheckboxField from '@objects/formFields/checkboxField'
import TextField from '@objects/formFields/textField'
import FriendlyCaptcha from '@objects/formFields/friendlyCaptcha'
import Headline from '@objects/headline'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  headline: {
    maxWidth: '465px',
    margin: theme.spacing(10, 'auto', 5, 'auto'),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
    },
  },
  copy: {
    maxWidth: '336px',
    margin: theme.spacing(0, 'auto', 5, 'auto'),
    textAlign: 'center',
  },
  checkboxes: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
}))

function UserDataForm() {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <>
      <Headline className={classes.headline} level={3}>
        {intl.formatMessage({ id: 'dialogue.popup.form.publish.headline' })}
      </Headline>
      <Copy className={classes.copy}>
        {intl.formatMessage({ id: 'dialogue.popup.form.publish.copy' })}
      </Copy>
      <Grid container spacing={8} justify="center">
        <Grid item xs={12} md={6}>
          <TextField
            id="name-input"
            fullWidth
            variant="outlined"
            name="userName"
            label={intl.formatMessage({
              id: 'dialogue.popup.form.field.name.label',
            })}
            placeholder={intl.formatMessage({
              id: 'dialogue.popup.form.field.name.placeholder',
            })}
            infoText={intl.formatMessage({
              id: 'dialogue.popup.form.field.name.info',
            })}
            autoComplete="name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email-input"
            fullWidth
            variant="outlined"
            type="email"
            name="userEmail"
            label={intl.formatMessage({
              id: 'dialogue.popup.form.field.email.label',
            })}
            placeholder={intl.formatMessage({
              id: 'dialogue.popup.form.field.email.placeholder',
            })}
            infoText={intl.formatMessage({
              id: 'dialogue.popup.form.field.email.info',
            })}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12} md={7} className={classes.checkboxes}>
          <CheckboxField
            name={'informAuthorOfNewContent'}
            labelId="dialogue.popup.form.field.notification.label"
          />

          <CheckboxField
            name="termsOfPrivacy"
            labelId="dialogue.popup.form.field.dataprivacy.label"
            labelLink="dataprivacy.path"
          />
          <FriendlyCaptcha name="friendlyCaptchaCode" background="white" />
        </Grid>
      </Grid>
    </>
  )
}

UserDataForm.propTypes = {}

export default UserDataForm

const axios = require('axios')

module.exports = async (slug, title) => {
  if (!slug) {
    return new Promise(({ reject }) => {
      reject('slug required')
    })
  }

  const article = await axios.get(
    process.env.GATSBY_FORUM_SERVICE_URL +
      `/api/query/magazine-articles?magazineSlug=${slug}&title=${title}`
  )

  return article
}

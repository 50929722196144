module.exports = function (theme) {
  return {
    root: {
      margin: theme.spacing(3, 0, 2, 0),
      borderRadius: theme.spacing(6),
      border: `2px solid ${theme.palette.grey.medium}`,
    },
    editor: {
      padding: theme.spacing(4),
      minHeight: '12em',
    },
    editorContainer: {
      paddingBottom: theme.spacing(4),
    },
    placeHolder: {
      padding: theme.spacing(4),
      color: theme.palette.grey.main,
      margin: 0,
      marginTop: theme.spacing(1),
    },
    hidePlaceholder: {
      display: 'block',
    },
    toolbar: {
      position: 'absolute',
      bottom: '0px',
    },
  }
}

import React from 'react'
const formatResult = (elements) => {
  let templateString = ''
  const formattedElements = []
  if (elements.length > 0) {
    elements.forEach((element, i) => {
      if (typeof element === 'string') {
        templateString += element + ' '
      } else if (typeof element === 'object') {
        if (templateString.trim()) {
          formattedElements.push(<span key={i}>{templateString}</span>)
          templateString = ''
        }
        formattedElements.push(element)
      }
    })
  }

  if (templateString.trim()) {
    formattedElements.push(<span>{templateString}</span>)
  }

  return <>{formattedElements}</>
}

export default formatResult

import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Parallax, A11y } from 'swiper'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { capitalize } from '@material-ui/core'
import _ from 'lodash'
import { useIntl } from 'react-intl'

import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  carouselRoot: {
    '&.swiper-container': {
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      overflow: 'hidden',
      listStyle: 'none',
      padding: 0,
      /* Fix of Webkit flickering */
      //zIndex: 1,
    },
    '& .swiper-wrapper': {
      position: 'relative',
      width: '100%',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      transitionProperty: 'transform',
      boxSizing: 'content-box',
    },
    '& .swiper-container-android .swiper-slide,  .swiper-wrapper': {
      transform: 'translate3d(0px, 0, 0)',
    },
    '& .swiper-slide, .swiper-slide-teaser': {
      flexShrink: 0,
      width: '100%',
      height: '100%',
      position: 'relative',
      transitionProperty: 'transform',
      pointerEvents: 'none',
      '&.active-slide': {
        pointerEvents: 'auto',
      },
    },
    '& .swiper-pagination-bullet': {
      position: 'relative',
      display: 'inline-block',
      cursor: 'pointer',
      height: '20px',
      width: '20px',
      border: '2px solid black',
      borderRadius: '50%',
      margin: theme.spacing(0, 4, 0, 1),
      opacity: '0.2',

      '&:focus, &:hover': {
        opacity: '.9',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '2px',
        bottom: '2px',
        left: '2px',
        right: '2px',
        transform: 'scale(0.01)',
        background: 'transparent',
        transition: 'transform 0.3s ease, background 0.3s ease',
        borderRadius: 999,
      },

      '&.swiper-pagination-bullet-active': {
        opacity: 1,
        '&::after': {
          background: theme.palette.red.main,
          transform: 'scale(1)',
        },
        '&:focus::after': {
          background: theme.palette.background.focus,
        },
        '&:hover::after': {
          background: theme.palette.red.light,
        },
      },
    },
    '& $paginationBetweenArrows $paginationBullets > .swiper-pagination-bullet':
      {
        width: '10px',
        height: '10px',
        borderWidth: '1px',
        margin: '0 3px',

        '&:first-child': {
          margin: '0 3px 0 22px',
        },

        '&:last-child': {
          margin: '0 28px 0 3px',
        },
      },
    '& .swiper-notification': {
      position: 'absolute',
      left: 0,
      top: 0,
      pointerEvents: 'none',
      opacity: 0,
      zIndex: -1000,
    },
  },
  nav: {
    cursor: 'pointer',
    zIndex: 1010,
    padding: theme.spacing(2),
    borderRadius: '50%',
    '&:focus': {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
  },
  navFraction: {
    cursor: 'pointer',
    zIndex: 1010,
    position: 'relative',
    '&:focus': {
      color: theme.palette.text.invert,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: theme.spacing(-2),
        bottom: theme.spacing(-2),
        background: theme.palette.background.focus,
        borderRadius: '50%',
      },
    },
  },
  navPrev: {
    '&:focus:before': {
      left: theme.spacing(-3),
      right: theme.spacing(-1),

      '$paginationBetweenArrows &': {
        left: '-7px',
        right: '-3px',
        top: '-4px',
        bottom: '-2px',
      },
    },
  },
  navNext: {
    '&:focus:before': {
      left: theme.spacing(-3.5),
      right: theme.spacing(-0.5),

      '$paginationBetweenArrows &': {
        left: '-9px',
        right: '-1px',
        top: '-4px',
        bottom: '-2px',
      },
    },
  },
  disabled: {
    color: theme.palette.grey.main,
  },
  navIcon: {
    position: 'relative',
    fontSize: theme.spacing(6),
    '&.lgNavIcon': {
      fontSize: theme.spacing(11),
    },
    '$paginationBetweenArrows &': {
      fontSize: '14px',
    },
  },
  buttons: {
    position: 'absolute',
    bottom: theme.spacing(5),
    width: '75vw',
    left: (props) => (props.centeredArrows ? `0` : theme.spacing(2)),
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      bottom: 'auto',
      top: `calc(50% - ${theme.spacing(9)}px)`,
    },
  },
  paginationBullets: {
    textAlign: 'center',
    transition: '300ms opacity',
    transform: 'translate3d(0, 0, 0)',
    zIndex: 10,
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(8),
    lineHeight: 0,
    '&.swiper-pagination-hidden': {
      opacity: 0,
    },
    '$paginationBetweenArrows &': {
      margin: 0,
    },
  },
  paginationFraction: {
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(7),
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      margin: theme.spacing(7, 35, 2, 38),
    },
  },
  paginationFractionTeaser: {
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(7),
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      margin: theme.spacing(7, 3, 2, 38),
      maxWidth: '1072px',
      marginLeft: 'auto',
      marginRight: 'auto',
      //marginRight: theme.spacing(18),
    },
  },
  paginationBetweenArrows: {
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '2px',
  },
  fraction: {
    margin: theme.spacing(0, 8, 0, 5),
  },
  typeDefault: {},
  typeSlideFade: {
    margin: '0 -16px',
    paddingTop: '20px',

    [theme.breakpoints.up('lg')]: {
      width: '98vw',
      margin: 'auto',
      '@supports (margin: initial)': {
        margin: 'initial',
      },
      marginLeft: `calc(-50vw + ( ${theme.container['lg']} * 0.5))!important`,
    },
    '& .swiper-slide': {
      transition: 'background 0.3s ease, transform 0.3s ease',
    },
    '& $buttons': {
      margin: 'auto',
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.up('lg')]: {
        width: '50vw',
        marginLeft: (props) => (props.centeredArrows ? `auto` : '25vw'),
      },
    },
  },
  fadeBegin: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '25vw',
    background:
      'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
    zIndex: 100,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  fadeEnd: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '25vw',
    background:
      'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
    zIndex: 100,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  navBelow: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(10),
    },
  },
}))

function Carousel({
  className,
  children,
  settings,
  pagination,
  type,
  navigationOpts,
  onSlideClick,
  navBelow,
  carouselId,
  carouselKey,
}) {
  const classes = useStyles({ centeredArrows: navigationOpts?.centeredArrows })
  const intl = useIntl()

  SwiperCore.use([Pagination, Navigation, Parallax, A11y])

  const props = useMemo(
    () => ({
      navigation: {
        nextEl: `.swiper-next-${carouselId}`,
        prevEl: `.swiper-prev-${carouselId}`,
        disabledClass: `${classes.disabled}`,
      },

      pagination: {
        el: `.swiper-pagination-${carouselId}`,
        type: pagination === 'fraction' ? pagination : 'bullets',
        renderFraction:
          pagination === 'fraction'
            ? function (currentClass, totalClass) {
                return (
                  '<span class="' +
                  currentClass +
                  '"></span>' +
                  ' von ' +
                  '<span class="' +
                  totalClass +
                  '"></span>'
                )
              }
            : null,
        clickable: pagination !== 'fraction',
      },
    }),
    []
  )

  function renderButtonsAndPagination() {
    if (pagination === 'fraction' || pagination === 'betweenArrows') {
      return (
        <div
          className={clsx({
            [classes.paginationFractionTeaser]: pagination === 'fraction',
            [classes.paginationBetweenArrows]: pagination === 'betweenArrows',
          })}
        >
          <div
            className={clsx(
              classes.navFraction,
              classes.navPrev,
              `swiper-prev-${carouselId}`
            )}
          >
            <Icon className={classes.navIcon} name={'Prev'} />
          </div>
          <div
            className={clsx(`swiper-pagination-${carouselId}`, {
              [classes.fraction]: pagination === 'fraction',
              [classes.paginationBullets]: pagination === 'betweenArrows',
            })}
          />
          <div
            className={clsx(
              classes.navFraction,
              classes.navNext,
              `swiper-next-${carouselId}`
            )}
          >
            <Icon className={classes.navIcon} name={'Next'} />
          </div>
        </div>
      )
    } else if (!navigationOpts?.disabled && !navigationOpts?.useExternal) {
      return (
        <Fragment>
          <div className={classes.buttons} aria-hidden="true">
            <div className={clsx(classes.nav, `swiper-prev-${carouselId}`)}>
              <Icon
                className={clsx(classes.navIcon, 'lgNavIcon')}
                name={'PrevBig'}
              />
            </div>
            <div className={clsx(classes.nav, `swiper-next-${carouselId}`)}>
              <Icon
                className={clsx(classes.navIcon, 'lgNavIcon')}
                name={'NextBig'}
              />
            </div>
          </div>
          <div
            aria-hidden="true"
            className={clsx(
              classes.paginationBullets,
              `swiper-pagination-${carouselId}`
            )}
          ></div>
        </Fragment>
      )
    }
  }

  function renderSlides() {
    return React.Children.map(children, (child, index) => {
      return (
        <SwiperSlide
          key={`carousel-slide-${carouselId}-${index}`}
          virtualIndex={index}
          onClick={
            onSlideClick
              ? () => {
                  onSlideClick(index)
                }
              : null
          }
        >
          {child}
        </SwiperSlide>
      )
    })
  }

  return (
    <>
      {!!navBelow && (
        <div className={classes.navBelow} aria-hidden="true">
          {renderButtonsAndPagination()}
        </div>
      )}

      <Swiper
        key={carouselKey}
        className={clsx(className, classes.carouselRoot, carouselId, {
          [classes[`type${capitalize(type)}`]]: type,
        })}
        data-testid={carouselId || 'carousel'}
        slidesPerView={1}
        spaceBetween={20}
        slideActiveClass={'active-slide'}
        a11y={{
          enabled: true,
          itemRoleDescriptionMessage: intl.formatMessage({
            id: 'carousel.label.itemRoleDescriptionMessage',
          }),
          paginationBulletMessage: intl.formatMessage({
            id: 'carousel.label.paginationBulletMessage',
          }),
          nextSlideMessage: intl.formatMessage({
            id: 'carousel.label.nextSlideMessage',
          }),
          prevSlideMessage: intl.formatMessage({
            id: 'carousel.label.prevSlideMessage',
          }),
          firstSlideMessage: intl.formatMessage({
            id: 'carousel.label.firstSlideMessage',
          }),
          lastSlideMessage: intl.formatMessage({
            id: 'carousel.label.lastSlideMessage',
          }),
          notificationClass: 'swiper-notification',
        }}
        {...props}
        {...settings}
      >
        {renderSlides()}

        {!navBelow && renderButtonsAndPagination()}

        {'slideFade' === type && (
          <Fragment key={_.uniqueId()}>
            <div
              slot="container-start"
              className={classes.fadeBegin}
              aria-hidden="true"
            />
            <div
              slot="container-end"
              className={classes.fadeEnd}
              aria-hidden="true"
            />
          </Fragment>
        )}
      </Swiper>
    </>
  )
}

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  settings: PropTypes.object,
  navigation: PropTypes.object,
  navigationOpts: PropTypes.object,
  centeredSlides: PropTypes.bool,
  centeredArrows: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'slideFade']),
  breakpoints: PropTypes.object,
  pagination: PropTypes.oneOf(['fraction', 'bullet', 'betweenArrows']),
  onSlideClick: PropTypes.func,
}

Carousel.defaultProps = {
  centeredSlides: false,
  type: 'default',
}

export default Carousel
